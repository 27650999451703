import React from "react";
import { FaTruck, FaCreditCard, FaRedo, FaComments } from "react-icons/fa";

const services = [
  {
    icon: <FaTruck className="text-primary" size={24} />,
    title: "Giao hàng miễn phí",
    description: "Cho đơn hàng từ 700,000₫",
  },
  {
    icon: <FaCreditCard className="text-primary" size={24} />,
    title: "Thanh toán tiện lợi",
    description: "Miễn phí thanh toán qua thẻ, QR Code",
  },
  {
    icon: <FaRedo className="text-primary" size={24} />,
    title: "Bảo hành đan lưới",
    description: "Bảo hành 72h sau khi đan",
  },
  {
    icon: <FaComments className="text-primary" size={24} />,
    title: "99% khách hài lòng",
    description: "Phản hồi tích cực",
  },
];

const ServiceCard = ({ icon, title, description }: any) => (
  <div className="flex items-center bg-gray-100 p-6 rounded-lg shadow-sm space-x-4">
    <div>{icon}</div>
    <div className="border-l border-gray-300 pl-4">
      <h3 className="font-semibold text-lg">{title}</h3>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
  </div>
);

const Services = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 my-8">
      {services.map((service, index) => (
        <ServiceCard
          key={index}
          icon={service.icon}
          title={service.title}
          description={service.description}
        />
      ))}
    </div>
  );
};

export default Services;
