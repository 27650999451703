import { useState } from "react";
import useViewport from "../../hooks/useViewport";
import useCartStore from "../../store/cartStore";
import logo from "../../assets/logo-v2.png";
import Container from "../Common/Container";
import { IoLocationOutline, IoSearchOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { FiPhone, FiShoppingCart } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import headerBackground from "../../assets/header-background.png";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import NavigationDrawer from "../Home/NavigationDrawer";
import SearchOverlay from "./SearchOverlay";
const Header = () => {
  const totalQuantity = useCartStore((state) => state.totalQuantity);
  const { isMobile, isTablet, isDesktop } = useViewport();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <header className="bg-[#ed2027] text-white ">
      <div
        className="bg-cover bg-center bg-repeat py-3"
        style={{ backgroundImage: `url(${headerBackground})` }}
      >
        <Container>
          <div className="flex justify-between items-center pr-3 md:pr-0">
            <div className="flex gap-4 items-center md:hidden">
              <button onClick={() => setIsDrawerOpen(true)}>
                <RxHamburgerMenu size={27} />
              </button>
              <button onClick={() => setIsSearchOpen(true)}>
                <IoSearchOutline size={27} />
              </button>
            </div>

            <Link to="/" className="flex gap-1 text-xl items-center">
              <img src={logo} alt="logo" className="w-40 md:w-auto" />
            </Link>

            <div className="flex gap-7">
              {isDesktop && (
                <>
                  <button className="flex gap-2 items-center text-base">
                    <IoLocationOutline size={24} />
                    <p>Hệ thống của hàng</p>
                    <IoIosArrowDown size={24} />
                  </button>
                  <div className="flex gap-2 items-center text-base">
                    <FiPhone size={24} />
                    <p>0888 08 08 22</p>
                  </div>
                </>
              )}

              <Link
                to="/gio-hang"
                className="relative hover:brightness-75 transition duration-300"
              >
                <FiShoppingCart size={25} />
                <p className="bg-black py-[2px] px-[1px] rounded-full flex items-center justify-center absolute w-full -bottom-3 -right-4">
                  {totalQuantity}
                </p>
              </Link>
            </div>
          </div>
        </Container>
      </div>

      {isDrawerOpen && (
        <NavigationDrawer onClose={() => setIsDrawerOpen(false)} />
      )}

      {isSearchOpen && <SearchOverlay onClose={() => setIsSearchOpen(false)} />}
    </header>
  );
};

{
  /* <div className="flex gap-2 items-center">
                <FaRegUser size={24} />
                <p>Đăng ký/đăng nhập</p>
              </div> */
}

export default Header;
// import { FiShoppingCart } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import logo from "../../assets/logo.png";
// import useCartStore from "../../store/cartStore";
// import Container from "../Common/Container";
// import Navigation from "./Navigation";
// import QuickCart from "./QuickCart";
// import SearchBox from "./SearchBox";
// import useViewport from "../../hooks/useViewport";
// import { useState } from "react";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { IoSearchOutline } from "react-icons/io5";
// import CartDrawer from "../Common/CartDrawer";
// import NavigationDrawer from "../Home/NavigationDrawer";
// import SearchOverlay from "./SearchOverlay";
// const Header = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const { isMobile, isTablet, isDesktop } = useViewport();
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isSearchOpen, setIsSearchOpen] = useState(false);

//   return (
//     <header className="py-1 md:py-0 md:pt-3 shadow-md relative">
//       <div className="">
//         <Container>
//           <div className="flex items-center justify-between ">
//             {(isMobile || isTablet) && (
//               <div className="flex gap-4 items-center">
//                 <button onClick={() => setIsDrawerOpen(true)}>
//                   <RxHamburgerMenu size={27} />
//                 </button>
//                 <button onClick={() => setIsSearchOpen(true)}>
//                   <IoSearchOutline size={27} />
//                 </button>
//               </div>
//             )}

//             <Link to="/" className="flex gap-1 items-center">
//               <img src={logo} alt="logo" className="w-14" loading="eager" />
//               <h1 className="font-bold text-2xl -rotate-2">
//                 <span className="text-primary"> Vua </span>Vợt
//               </h1>
//             </Link>
//             {isDesktop && <SearchBox />}

//             <div className="flex gap-5 text-sm">
//               <Link
//                 to="/gio-hang"
//                 className="flex gap-2 items-center hover-primary-color"
//               >
//                 {isDesktop && <span>Giỏ hàng</span>}
//                 <div className="relative">
//                   {isDesktop && <FiShoppingCart size={20} />}
//                   {!isDesktop && (
//                     <FiShoppingCart size={27} className="text-primary" />
//                   )}
//                   <span className="text-xs bg-primary text-white px-1 py-[2px] absolute -top-3 -right-3 rounded-full">
//                     {totalQuantity}
//                   </span>
//                 </div>
//               </Link>
//             </div>
//           </div>
//           {isDesktop && <Navigation />}
//         </Container>
//       </div>

//       <QuickCart />

//       {isDrawerOpen && (
//         <NavigationDrawer onClose={() => setIsDrawerOpen(false)} />
//       )}

//       {isSearchOpen && <SearchOverlay onClose={() => setIsSearchOpen(false)} />}
//     </header>
//   );
// };

// export default Header;

// import { FiShoppingCart } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import logo from "../../assets/logo.png";
// import useCartStore from "../../store/cartStore";
// import Container from "../Common/Container";
// import Navigation from "./Navigation";
// import QuickCart from "./QuickCart";
// import SearchBox from "./SearchBox";
// import useViewport from "../../hooks/useViewport";
// import { useState } from "react";
// const Header = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);

//   return (
//     <header className="pt-3 shadow-md relative">
//       <div className="">
//         <Container>
//           <div className="flex items-center justify-between ">
//             <Link to="/" className="flex gap-1 items-center">
//               <img src={logo} alt="logo" className="w-14" loading="eager" />
//               <h1 className="font-bold text-2xl -rotate-2">
//                 <span className="text-primary"> Vua </span>Vợt
//               </h1>
//             </Link>
//             <SearchBox />

//             <div className="flex gap-5 text-sm">
//               <Link
//                 to="/gio-hang"
//                 className="flex gap-2 items-center hover-primary-color"
//               >
//                 <span>Giỏ hàng</span>
//                 <div className="relative">
//                   <FiShoppingCart size={20} />
//                   <span className="text-xs bg-primary text-white px-1 py-[2px] absolute -top-3 -right-3 rounded-full">
//                     {totalQuantity}
//                   </span>
//                 </div>
//               </Link>
//             </div>
//           </div>
//           <Navigation />
//         </Container>
//       </div>

//       <QuickCart />
//     </header>
//   );
// };

// export default Header;

// import { FiShoppingCart, FiSearch, FiMenu } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import { useState } from "react";
// import logo from "../../assets/logo.png";
// import useCartStore from "../../store/cartStore";
// import Container from "../Common/Container";
// import Navigation from "./Navigation";
// import QuickCart from "./QuickCart";
// import SearchBox from "./SearchBox";
// import useViewport from "../../hooks/useViewport";

// const Header = () => {
//   const { isMobile, isTablet, isDesktop } = useViewport();
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const [showDrawer, setShowDrawer] = useState(false);
//   const [showSearch, setShowSearch] = useState(false);

//   return (
//     <header className="pt-3 shadow-md relative">
//       <Container>
//         <div className="flex items-center justify-between">
//           {/* Left Section */}
//           <div className="flex items-center gap-2">
//             {isMobile && (
//               <>
//                 <FiMenu
//                   size={24}
//                   onClick={() => setShowDrawer(true)}
//                   className="cursor-pointer"
//                 />
//                 <FiSearch
//                   size={24}
//                   onClick={() => setShowSearch(!showSearch)}
//                   className="cursor-pointer"
//                 />
//               </>
//             )}
//             <Link
//               to="/"
//               className={`flex items-center gap-1 ${
//                 isDesktop ? "order-1" : "order-2"
//               }`}
//             >
//               <img src={logo} alt="logo" className="w-12" loading="eager" />
//               {isDesktop && (
//                 <h1 className="font-bold text-2xl -rotate-2">
//                   <span className="text-primary">Vua</span>Vợt
//                 </h1>
//               )}
//             </Link>
//           </div>

//           {/* Center Section */}
//           {isDesktop && <SearchBox />}
//           {isMobile && showSearch && <SearchBox />}

//           {/* Right Section */}
//           <Link
//             to="/gio-hang"
//             className="relative flex items-center gap-1 hover-primary-color order-3"
//           >
//             <FiShoppingCart size={24} />
//             {isDesktop && <span>Giỏ hàng</span>}
//             {totalQuantity > 0 && (
//               <span className="text-xs bg-primary text-white px-1 py-[2px] absolute -top-2 -right-2 rounded-full">
//                 {totalQuantity}
//               </span>
//             )}
//           </Link>
//         </div>

//         {/* Drawer for Mobile Navigation */}
//         {isMobile && showDrawer && (
//           <div
//             className="fixed inset-0 bg-black bg-opacity-50 z-20"
//             onClick={() => setShowDrawer(false)}
//           >
//             <div
//               className="bg-white w-64 h-full p-5 shadow-lg"
//               onClick={(e) => e.stopPropagation()}
//             >
//               <Navigation
//                 isMobile={isMobile}
//                 closeDrawer={() => setShowDrawer(false)}
//               />
//             </div>
//           </div>
//         )}

//         {/* Desktop Navigation */}
//         {isDesktop && <Navigation />}
//       </Container>

//       <QuickCart />
//     </header>
//   );
// };

// export default Header;

// import { useEffect, useState } from "react";
// import { FiShoppingCart, FiMenu, FiSearch } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import logo from "../../assets/logo.png";
// import useCartStore from "../../store/cartStore";
// import CartDrawer from "../Common/CartDrawer";
// import Container from "../Common/Container";
// import SearchBox from "./SearchBox";
// import Navigation from "./Navigation";
// import NavigationDrawer from "../Home/NavigationDrawer";

// const Header = () => {
//   const [isOpenCart, setIsOpenCart] = useState(false);
//   const [isOpenDrawer, setIsOpenDrawer] = useState(false);
//   const [isSearchOpen, setIsSearchOpen] = useState(false);
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const [animate, setAnimate] = useState(false);

//   useEffect(() => {
//     setAnimate(true);
//     const timeout = setTimeout(() => setAnimate(false), 500);
//     return () => clearTimeout(timeout);
//   }, [totalQuantity]);

//   return (
//     <header className="pt-3 shadow-md relative">
//       <Container>
//         <div className="flex items-center justify-between ">
//           {/* Hamburger Icon (Mobile Only) */}
//           <FiMenu
//             size={24}
//             className="md:hidden cursor-pointer"
//             onClick={() => setIsOpenDrawer(true)}
//           />

//           {/* Logo (Centered on Mobile, Left-Aligned on Desktop) */}
//           <Link
//             to="/"
//             className="flex gap-1 items-center mx-auto md:mx-0 md:ml-0"
//           >
//             <img src={logo} alt="logo" className="w-14" loading="eager" />
//             <h1 className="font-bold text-2xl -rotate-2">
//               <span className="text-primary"> Vua </span>Vợt
//             </h1>
//           </Link>

//           {/* Search Icon (Mobile Only) */}
//           <FiSearch
//             size={24}
//             className="md:hidden cursor-pointer"
//             onClick={() => setIsSearchOpen((prev) => !prev)}
//           />

//           {/* chỉ hiện searchbox ở desktop */}
//           <SearchBox />

//           {/* Cart Icon */}
//           <div
//             className="relative cursor-pointer"
//             onClick={() => setIsOpenCart(true)}
//           >
//             <FiShoppingCart size={24} />
//             <span className="absolute -top-2 -right-2 bg-primary text-white text-xs rounded-full px-1">
//               {totalQuantity}
//             </span>
//           </div>
//         </div>

//         {/* Chỉ hiện nav này ở desktop */}
//         <Navigation />

//         {/* Mobile Search Box */}
//         {isSearchOpen && <SearchBox />}

//         {/* Mobile Drawer */}
//         {isOpenDrawer && (
//           <NavigationDrawer onClose={() => setIsOpenDrawer(false)} />
//         )}

//         {/* Cart Drawer */}
//         {isOpenCart && <CartDrawer onClose={() => setIsOpenCart(false)} />}
//       </Container>
//     </header>
//   );
// };

// export default Header;

// import { useEffect, useState } from "react";
// import { FiShoppingCart, FiMenu, FiSearch } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import logo from "../../assets/logo.png";
// import useCartStore from "../../store/cartStore";
// import CartDrawer from "../Common/CartDrawer";
// import Container from "../Common/Container";
// import SearchBox from "./SearchBox";
// import Navigation from "./Navigation";
// import NavigationDrawer from "../Home/NavigationDrawer";

// const Header = () => {
//   const [isOpenCart, setIsOpenCart] = useState(false);
//   const [isOpenDrawer, setIsOpenDrawer] = useState(false);
//   const [isSearchOpen, setIsSearchOpen] = useState(false);
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const [animate, setAnimate] = useState(false);

//   useEffect(() => {
//     setAnimate(true);
//     const timeout = setTimeout(() => setAnimate(false), 500);
//     return () => clearTimeout(timeout);
//   }, [totalQuantity]);

//   return (
//     <header className="pt-3 shadow-md relative">
//       <Container>
//         <div className="flex items-center justify-between">
//           {/* Hamburger Icon */}
//           <FiMenu
//             size={24}
//             className="md:hidden cursor-pointer"
//             onClick={() => setIsOpenDrawer(true)}
//           />

//           {/* Logo */}
//           <Link to="/" className="flex gap-1 items-center mx-auto">
//             <img src={logo} alt="logo" className="w-14" loading="eager" />
//             <h1 className="font-bold text-2xl -rotate-2">
//               <span className="text-primary"> Vua </span>Vợt
//             </h1>
//           </Link>

//           {/* Search Icon */}
//           <FiSearch
//             size={24}
//             className="md:hidden cursor-pointer"
//             onClick={() => setIsSearchOpen((prev) => !prev)}
//           />

//           {/* Cart Icon */}
//           <div
//             className="relative cursor-pointer"
//             onClick={() => setIsOpenCart(true)}
//           >
//             <FiShoppingCart size={24} />
//             <span className="absolute -top-2 -right-2 bg-primary text-white text-xs rounded-full px-1">
//               {totalQuantity}
//             </span>
//           </div>
//         </div>

//         {/* Desktop Navigation */}
//         <div className="hidden md:flex justify-between">
//           <SearchBox />
//           <Navigation />
//         </div>

//         {/* Mobile Search Box */}
//         {isSearchOpen && <SearchBox />}

//         {/* Mobile Drawer */}
//         {isOpenDrawer && (
//           <NavigationDrawer onClose={() => setIsOpenDrawer(false)} />
//         )}

//         {/* Cart Drawer */}
//         {isOpenCart && <CartDrawer onClose={() => setIsOpenCart(false)} />}
//       </Container>
//     </header>
//   );
// };

// export default Header;

// import { FiShoppingCart } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import logo from "../../assets/logo.png";
// import useCartStore from "../../store/cartStore";
// import Container from "../Common/Container";
// import Navigation from "./Navigation";
// import QuickCart from "./QuickCart";
// import SearchBox from "./SearchBox";
// const Header = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);

//   return (
//     <header className="pt-3 shadow-md relative">
//       <div className="">
//         <Container>
//           <div className="flex items-center justify-between ">
//             <Link to="/" className="flex gap-1 items-center">
//               <img src={logo} alt="logo" className="w-14" loading="eager" />
//               <h1 className="font-bold text-2xl -rotate-2">
//                 <span className="text-primary"> Vua </span>Vợt
//               </h1>
//             </Link>
//             <SearchBox />

//             <div className="flex gap-5 text-sm">
//               <Link
//                 to="/gio-hang"
//                 className="flex gap-2 items-center hover-primary-color"
//               >
//                 <span>Giỏ hàng</span>
//                 <div className="relative">
//                   <FiShoppingCart size={20} />
//                   <span className="text-xs bg-primary text-white px-1 py-[2px] absolute -top-3 -right-3 rounded-full">
//                     {totalQuantity}
//                   </span>
//                 </div>
//               </Link>
//             </div>
//           </div>
//           <Navigation />
//         </Container>
//       </div>

//       <QuickCart />
//     </header>
//   );
// };

// export default Header;

{
  /* <button className="flex gap-2 items-center hover-primary-color">
                <span>Tài khoản</span>
                <FiUser size={20} />
              </button> */
}
{
  /* <button className="flex gap-2 items-center hover-primary-color">
                <span>Yêu thích</span>
                <FiHeart size={20} />
              </button> */
}
