import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import useViewport from "../../hooks/useViewport";
import { fetchMainProducts } from "../../services/home";
import { MainProduct, Product } from "../../shared/types";
import ProductSlider from "../Common/ProductSlider";

const ShoeSliderWithBrands: React.FC = () => {
  const { isMobile, isTablet } = useViewport();
  const [selectedBrand, setSelectedBrand] = useState("All");
  const navigate = useNavigate();

  const {
    isPending: isLoadingMainProduct,
    error: errorMainProduct,
    data: dataMainProduct,
  } = useQuery({
    queryKey: ["mainProducts"],
    queryFn: fetchMainProducts,
  });

  if (isLoadingMainProduct) return <div>Loading...</div>;
  if (errorMainProduct) return <div>Error fetching main products</div>;

  const shoeCategory = dataMainProduct?.data.find(
    (mainProduct: MainProduct) => mainProduct.categoryName === "Giày Cầu Lông"
  );

  const uniqueBrands = Array.from(
    new Set(shoeCategory?.products.map((product: Product) => product.brand))
  ).filter((brand) => brand);

  const filteredProducts =
    selectedBrand === "All"
      ? shoeCategory?.products
      : shoeCategory?.products.filter(
          (product: Product) => product.brand === selectedBrand
        );

  return (
    <div className="py-4 md:py-6 lg:py-8">
      <div className="px-4 md:px-5">
        <div className="flex flex-col md:flex-row md:justify-between items-center mb-3 md:mb-5">
          {/* Title */}
          <h2 className="text-lg md:text-2xl lg:text-3xl font-extrabold mb-4 md:mb-0">
            GIÀY CẦU LÔNG
          </h2>

          {/* Brand Filter Buttons */}
          <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
            <button
              onClick={() => setSelectedBrand("All")}
              className={`px-3 py-1 md:px-4 md:py-2 rounded-md ${
                selectedBrand === "All"
                  ? "bg-primary text-white"
                  : "border border-gray-300 text-gray-600"
              } text-sm md:text-base`}
            >
              Tất cả
            </button>
            {uniqueBrands.map((brand) => (
              <button
                key={brand}
                onClick={() => setSelectedBrand(brand)}
                className={`px-3 py-1 md:px-4 md:py-2 rounded-md ${
                  selectedBrand === brand
                    ? "bg-primary text-white"
                    : "border border-gray-300 text-gray-600"
                } text-sm md:text-base`}
              >
                {brand}
              </button>
            ))}
            {/* Right Arrow Button */}
            <button
              onClick={() =>
                navigate("/san-pham/giay-cau-long?orderByDescending=popular")
              }
              className="px-3 py-1 md:px-4 md:py-2 rounded-md border border-gray-300 text-gray-600 flex items-center text-sm md:text-base"
            >
              <AiOutlineArrowRight className="text-lg" />
            </button>
          </div>
        </div>

        <ProductSlider products={filteredProducts} />
      </div>
    </div>
  );
};

export default ShoeSliderWithBrands;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import ProductCard from "../Common/ProductCard";
// import useViewport from "../../hooks/useViewport";
// import { useQuery } from "@tanstack/react-query";
// import { fetchMainProducts } from "../../services/home";
// import { AiOutlineArrowRight } from "react-icons/ai";

// interface Product {
//   productId: string;
//   productName: string;
//   brand: string;
//   productCode: string;
//   erpProductCode: string;
//   price?: number;
//   imageUrl?: string;
//   isPopular: boolean;
//   manufacturerPrice?: number;
//   categoryName: string;
// }

// interface MainProduct {
//   categoryName: string;
//   products: Product[];
// }

// const ShoeSliderWithBrands: React.FC = () => {
//   const { isMobile, isTablet, isDesktop } = useViewport();
//   const [selectedBrand, setSelectedBrand] = useState("All");
//   const navigate = useNavigate();

//   const {
//     isPending: isLoadingMainProduct,
//     error: errorMainProduct,
//     data: dataMainProduct,
//   } = useQuery({
//     queryKey: ["mainProducts"],
//     queryFn: fetchMainProducts,
//   });

//   if (isLoadingMainProduct) {
//     return <div>Loading...</div>;
//   }

//   if (errorMainProduct) {
//     return <div>Error fetching main products</div>;
//   }

//   // Find the "Giày Cầu Lông" category from the fetched data
//   const shoeCategory = dataMainProduct?.data.find(
//     (mainProduct: MainProduct) => mainProduct.categoryName === "Giày Cầu Lông"
//   );

//   // Extract unique brands from the products in the "Giày Cầu Lông" category
//   const uniqueBrands = Array.from(
//     new Set(shoeCategory?.products.map((product: Product) => product.brand))
//   ).filter((brand) => brand);

//   // Filter products by the selected brand or show all if "All" is selected
//   const filteredProducts =
//     selectedBrand === "All"
//       ? shoeCategory?.products
//       : shoeCategory?.products.filter(
//           (product: Product) => product.brand === selectedBrand
//         );

//   // Adjust Swiper configuration based on viewport size
//   const slidesPerView = isMobile ? 1.5 : isTablet ? 2.5 : 4;
//   const spaceBetween = isMobile ? 16 : isTablet ? 20 : 30;

//   return (
//     <div className="py-8">
//       <div className="px-5">
//         <div className="flex justify-between items-center">
//           {/* Title */}
//           <h2 className="text-2xl font-extrabold mb-4">GIÀY CẦU LÔNG</h2>

//           {/* Brand Filter Buttons */}
//           <div className="flex flex-wrap space-x-2 mb-6">
//             {/* All Option */}
//             <button
//               onClick={() => setSelectedBrand("All")}
//               className={`px-4 py-2 rounded ${
//                 selectedBrand === "All"
//                   ? "bg-primary text-white"
//                   : "border border-gray-300 text-gray-600"
//               }`}
//             >
//               Tất cả
//             </button>

//             {/* Unique Brands */}
//             {uniqueBrands.map((brand) => (
//               <button
//                 key={brand}
//                 onClick={() => setSelectedBrand(brand)}
//                 className={`px-4 py-2 rounded ${
//                   selectedBrand === brand
//                     ? "bg-primary text-white"
//                     : "border border-gray-300 text-gray-600"
//                 }`}
//               >
//                 {brand}
//               </button>
//             ))}

//             {/* Right Arrow Button */}
//             <button
//               onClick={() => navigate("/san-pham/giay-cau-long")}
//               className="px-4 py-2 rounded border border-gray-300 text-gray-600 flex items-center"
//             >
//               <AiOutlineArrowRight className="text-lg" />
//             </button>
//           </div>
//         </div>

//         {/* Product Slider */}
//         <Swiper
//           modules={[Navigation]}
//           spaceBetween={spaceBetween}
//           slidesPerView={slidesPerView}
//           navigation
//           loop
//           className="px-7"
//         >
//           {filteredProducts?.map((product: Product) => (
//             <SwiperSlide key={product.productId} className="py-2 select-none">
//               <ProductCard
//                 id={product.productId}
//                 category={product.categoryName}
//                 name={product.productName}
//                 brand={product.brand}
//                 originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
//                 salePrice={`${product.price?.toLocaleString()}₫`}
//                 colors={["#FFA500", "#FF0000", "#000000"]} // Assuming fixed colors, adjust as necessary
//                 badge={product.isPopular ? "HOT" : undefined}
//                 imageUrl={product.imageUrl || "/path/to/default/image.png"} // Provide a default image if none is available
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default ShoeSliderWithBrands;
