import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { fetchAllProducts } from "../../services/home";
import { Filter, Product } from "../../shared/types";
import { formatVietnamDong, getCategorySlugFromName } from "../../shared/utils";

const SearchOverlay = ({ onClose }: any) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filters, setFilters] = useState<Partial<Filter>>({
    SearchQuery: "",
  });

  useEffect(() => {
    const debouncedSetFilters = debounce(() => {
      if (query.trim() && !submitted) {
        setFilters({
          SearchQuery: query,
          pageIndex: 1,
        });
        setShowSuggestions(true);
      }
    }, 300);

    debouncedSetFilters();

    return () => debouncedSetFilters.cancel();
  }, [query, submitted]);

  const { data: dataAllProduct } = useQuery({
    queryKey: ["searchProducts", filters],
    queryFn: () => fetchAllProducts("all", filters),
    enabled: !!filters.SearchQuery,
  });

  const handleInputChange = (e: any) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    setSubmitted(false);
    if (!searchQuery.trim()) {
      setShowSuggestions(false);
    }
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    if (query.trim()) {
      setShowSuggestions(false);
      setSubmitted(true);
      navigate(`/tim-kiem?q=${encodeURIComponent(query)}`);
      onClose();
    }
  };

  const handleProductClick = (product: Product) => {
    navigate(
      `/san-pham/${getCategorySlugFromName(product.categoryName)}/${
        product.productId
      }`
    );
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50">
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 animate__animated animate__fadeIn animate__faster"
        onClick={onClose}
      ></div>

      {/* Search Box */}
      <form
        onSubmit={handleSearchSubmit}
        className={`fixed top-[15%] left-[5%] right-[5%] ${
          showSuggestions && dataAllProduct?.data.length
            ? "rounded-lg"
            : "rounded-full"
        } overflow-hidden bg-white  shadow-md animate__animated animate__fadeInDown animate__faster "`}
      >
        <div className="relative pr-10 border-b border-blue-200">
          <input
            type="text"
            className="w-full px-8 py-4 outline-none text-gray-700"
            placeholder="Bạn cần tìm gì...?"
            onChange={handleInputChange}
            autoFocus
            value={query}
            onFocus={() => query.trim() && setShowSuggestions(true)}
          />
          <IoSearch
            size={24}
            className="mr-3 text-primary absolute top-1/2 -translate-y-1/2 right-3"
          />
        </div>

        {/* Suggestions */}
        {showSuggestions &&
          dataAllProduct &&
          dataAllProduct.data.length > 0 && (
            <div className="bg-white rounded-md p-3 text-gray-700">
              {dataAllProduct.data.slice(0, 3).map((product) => (
                <div
                  key={product.productId}
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleProductClick(product)}
                >
                  <LazyLoadImage
                    src={
                      product.imageUrl ||
                      "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                    }
                    alt={product.productName}
                    className="w-[75px] h-[75px] rounded-md mr-3"
                  />
                  <div>
                    <p className="text-sm font-medium">{product.productName}</p>
                    <p className="text-[14px] text-gray-600 mt-1 text-primary">
                      {formatVietnamDong(product.price)}
                    </p>
                  </div>
                </div>
              ))}
              {/* {(dataAllProduct.paging?.totalCount || 0) > 4 && (
                <div className="my-2 flex justify-end">
                  <button
                    onClick={() => {
                      navigate(`/tim-kiem?q=${encodeURIComponent(query)}`);
                      onClose();
                    }}
                    className="hover-brigher-color"
                  >
                    Xem toàn bộ {dataAllProduct.paging?.totalCount} kết quả
                  </button>
                </div>
              )} */}
            </div>
          )}
      </form>
    </div>
  );
};

export default SearchOverlay;
