import { Link } from "react-router-dom";
import backdrop from "../../assets/backdrop.png";
import imageInIcon from "../../assets/imageInIcon.png";
import shirt1 from "../../assets/shirt1.png";
import shirt2 from "../../assets/shirt2.png";
import shirt3 from "../../assets/shirt3.png";

const Features = () => {
  return (
    <section
      className="relative bg-cover bg-center text-white py-12 px-4 sm:px-8"
      style={{ backgroundImage: `url(${backdrop})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-40"></div>

      <div className="relative max-w-7xl mx-auto flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
        {/* Left Side: Features List */}
        <div className="space-y-6 text-center md:text-left md:ml-[10%]">
          <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
            <img src={imageInIcon} alt="Feature Icon" className="w-12 h-12" />
            <p className="text-base sm:text-lg font-semibold">
              Thoáng mát, thoải mái
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
            <img src={imageInIcon} alt="Feature Icon" className="w-12 h-12" />
            <p className="text-base sm:text-lg font-semibold">
              Thấm hút mồ hôi
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
            <img src={imageInIcon} alt="Feature Icon" className="w-12 h-12" />
            <p className="text-base sm:text-lg font-semibold">
              Vải co giãn 4 chiều
            </p>
          </div>
        </div>

        {/* Center: Shirts and Title */}
        <div className="flex flex-col items-center space-y-4 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 px-4">
            ÁO LINING FAN ZHENDONG <br className="hidden sm:block" /> OLYMPIC
            PARIS 2024
          </h2>

          <div className="flex items-end space-x-2 sm:space-x-4">
            <img
              src={shirt1}
              alt="Shirt 1"
              className="w-24 sm:w-36 h-auto rounded-lg"
            />
            <img
              src={shirt2}
              alt="Shirt 2"
              className="w-28 sm:w-40 h-auto rounded-lg mb-2 sm:mb-4"
            />
            <img
              src={shirt3}
              alt="Shirt 3"
              className="w-24 sm:w-36 h-auto rounded-lg"
            />
          </div>

          <Link
            to="/san-pham/vot-cau-long"
            className="bg-primary text-white px-5 py-2 rounded-lg font-semibold hover:bg-primary-dark mt-4 transition duration-300 text-sm sm:text-base"
          >
            KHÁM PHÁ NGAY
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Features;

// import { FaTruck } from "react-icons/fa";
// import Container from "../Common/Container";
// import { RiExchangeFill } from "react-icons/ri";
// import { AiFillLike } from "react-icons/ai";
// import { FaRegCreditCard } from "react-icons/fa";
// const Features = () => {
//   return (
//     <section>
//       <Container className=" flex justify-between gap-16 items-start">
//         <div className="flex flex-col items-center flex-1">
//           <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
//             <FaTruck className="text-primary" size={35} />
//           </div>
//           <h2 className="text-lg font-medium mt-4">
//             Giao hàng nhanh, miễn phí
//           </h2>
//           <p className="text-gray-400 text-[13px] text-center">
//             Đơn hàng hơn 900k hoặc đăng ký tài khoản được giao hàng miễn phí
//           </p>
//         </div>
//         <div className="flex flex-col items-center flex-1">
//           <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
//             <RiExchangeFill className="text-primary" size={35} />
//           </div>
//           <h2 className="text-lg font-medium mt-4">Trả hàng, Bảo hành</h2>
//           <p className="text-gray-400 text-[13px] text-center">
//             Không thích? Trả lại hoặc đổi hàng của bạn miễn phí trong vòng 30
//             ngày.
//           </p>
//         </div>
//         <div className="flex flex-col items-center flex-1">
//           <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
//             <AiFillLike className="text-primary" size={35} />
//           </div>
//           <h2 className="text-lg font-medium mt-4">Thành viên</h2>
//           <p className="text-gray-400 text-[13px] text-center">
//             Ưu đã theo từng cấp bậc hạng thành viên. Sinh nhật quà tặng thành
//             viên
//           </p>
//         </div>
//         <div className="flex flex-col items-center flex-1">
//           <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
//             <FaRegCreditCard className="text-primary" size={35} />
//           </div>
//           <h2 className="text-lg font-medium mt-4">Chính hãng</h2>
//           <p className="text-gray-400 text-[13px] text-center">
//             Sản phẩm chính hãng. Được nhập khẩu 100% từ hãng.
//           </p>
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default Features;
