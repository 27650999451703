import axiosInstance from "../shared/axiosInstance";
import {
  Category,
  APIResponse,
  Brand,
  MainProduct,
  Product,
  Filter,
} from "../shared/types";

export const fetchCategories = async (): Promise<APIResponse<Category>> => {
  try {
    const response = await axiosInstance.get<APIResponse<Category>>(
      "/api/v1/categories/get-all-categories"
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch categories");
    }
  } catch (error) {
    // Log error details for debugging purposes and throw the error to be caught by the caller
    console.error("Error fetching categories:", error);
    throw new Error("An error occurred while fetching categories");
  }
};

export const fetchBrands = async (): Promise<APIResponse<Brand>> => {
  try {
    const response = await axiosInstance.get<APIResponse<Brand>>(
      "/api/v1/brands"
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch brands");
    }
  } catch (error) {
    console.error("Error fetching brands:", error);
    throw new Error("An error occurred while fetching brands");
  }
};

export const fetchFlashSale = async (): Promise<APIResponse<Product>> => {
  try {
    const response = await axiosInstance.get<APIResponse<Product>>(
      "/api/v1/products/flash-sale"
    );
    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch flash sale");
    }
  } catch (error) {
    console.error("Error fetching flash sale:", error);
    throw new Error("An error occurred while fetching flash sale");
  }
};

export const fetchMainProducts = async (): Promise<
  APIResponse<MainProduct>
> => {
  try {
    const response = await axiosInstance.get<APIResponse<MainProduct>>(
      "/api/v1/products/grouped-by-category"
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch main products");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw new Error("An error occurred while fetching products");
  }
};

export const fetchAllProducts = async (
  categoryCode: string | number | null,
  filters: Partial<Filter>
): Promise<APIResponse<Product>> => {
  try {
    if (!categoryCode) throw new Error("Category Code is null");

    const response = await axiosInstance.get<APIResponse<Product>>(
      `/api/v1/products/${categoryCode}`,
      {
        params: filters,
      }
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch main products");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw new Error("An error occurred while fetching products");
  }
};
