import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import emptyCart from "../assets/emptycart.png";
import Breadcrumb from "../components/Common/Breadcrumb";
import Container from "../components/Common/Container";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
import { CartItem } from "../shared/types";
import {
  formatVietnamDong,
  translateCharacteristicName,
} from "../shared/utils";
import useCartStore from "../store/cartStore";
import { IoIosArrowRoundBack } from "react-icons/io";
import useViewport from "../hooks/useViewport";
import { RxCross2 } from "react-icons/rx";

const CartPage = () => {
  const totalQuantity = useCartStore((state) => state.totalQuantity);
  const addItemToCart = useCartStore((state) => state.addItemToCart);
  const removeItemFromCart = useCartStore((state) => state.removeItemFromCart);
  const removeEntireItem = useCartStore((state) => state.removeEntireItem);
  const products = useCartStore((state) => state.items);
  const totalAmount = useCartStore((state) => state.totalAmount);

  const handleItemToCart = (product: CartItem) => {
    const addedProduct: CartItem = {
      ...product,
      quantity: 1,
      totalPrice: product.price * 1,
    };
    addItemToCart(addedProduct);
  };

  const navigate = useNavigate();

  const [parent] = useAutoAnimate();

  const { isMobile, isTablet, isDesktop } = useViewport();

  const breadcrumbItems = [
    { label: "Trang chủ", link: "/" },
    { label: "Giỏ hàng" },
  ];

  return (
    <>
      <div className="min-h-screen">
        <Header />
        <Helmet>
          <title>Giỏ hàng của bạn | Vuavot</title>
          <meta
            name="description"
            content="Xem giỏ hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín với các sản phẩm chất lượng từ Yonex, Victor, Lining và nhiều thương hiệu khác. Chuẩn bị sẵn sàng cho những trải nghiệm cầu lông tuyệt vời với sản phẩm của bạn!"
          />
        </Helmet>

        <Container className="mb-10">
          <HeroWithOverlay />
          <Breadcrumb items={breadcrumbItems} />

          <h1 className="text-2xl md:text-3xl font-semibold mb-5">GIỎ HÀNG</h1>

          {products.length === 0 && (
            <div>
              <div className="flex justify-center mt-10">
                <LazyLoadImage src={emptyCart} width={300} />
              </div>
              <p className="text-lg md:text-xl font-semibold mb-5 text-center">
                Giỏ hàng của bạn đang trống
              </p>
            </div>
          )}

          {products.length > 0 && (
            <div className="flex flex-col lg:flex-row gap-6 items-start">
              <div
                ref={parent}
                className="w-full lg:w-2/3 bg-white md:p-6 rounded-lg shadow"
              >
                {/* Giao diện desktop */}
                {isDesktop && (
                  <div className="grid grid-cols-5 text-center font-semibold border-b-2 pb-4 mb-1">
                    <div className="col-span-2">Sản phẩm</div>
                    <div>Giá</div>
                    <div>Số lượng</div>
                    <div>Tổng tiền</div>
                  </div>
                )}

                {/* {products.map((product) => (
                  <div
                    key={product.id}
                    className={`${
                      isMobile
                        ? "flex flex-col items-start text-left p-4 bg-white mb-6 rounded-lg shadow"
                        : "grid grid-cols-5 items-center text-center mb-6"
                    }`}
                  >
                    {isDesktop && (
                      <>
                        <div className="col-span-2 flex items-center">
                          <LazyLoadImage
                            src={
                              product.img ||
                              "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                            }
                            className="w-16 h-16 md:w-20 md:h-20 object-cover mr-4"
                            alt={product.name}
                          />
                          <div className="text-left">
                            <p className="text-sm md:text-[17px] font-medium">
                              {product.name}
                            </p>
                            <p className="text-xs md:text-sm text-gray-500">
                              {product.brand}
                            </p>
                            <div className="flex gap-3">
                              {Object.entries(product.characteristic).map(
                                ([key, value]) => (
                                  <div
                                    key={key}
                                    className="flex gap-1 text-sm text-gray-500"
                                  >
                                    <p>{translateCharacteristicName(key)}:</p>
                                    <p>{value}</p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="font-medium">
                          {formatVietnamDong(product.price)}
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            onClick={() => removeItemFromCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-l"
                          >
                            -
                          </button>
                          <span className="px-4">{product.quantity}</span>
                          <button
                            onClick={() => handleItemToCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-r"
                          >
                            +
                          </button>
                        </div>
                        <div className="font-medium">
                          {formatVietnamDong(product.totalPrice)}
                        </div>
                      </>
                    )}

                    {isMobile && (
                      <>
                        <button
                          className="self-end text-black text-lg mb-2"
                          onClick={() => removeEntireItem(product)}
                        >
                          <RxCross2 size={16} />
                        </button>
                        <LazyLoadImage
                          src={
                            product.img ||
                            "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                          }
                          className="w-24 h-24 object-cover mb-4"
                          alt={product.name}
                        />
                        <p className="text-sm font-medium mb-2">
                          <span className="font-semibold">SẢN PHẨM:</span>{" "}
                          {product.name}
                        </p>
                        <p className="text-sm font-medium mb-2">
                          <span className="font-semibold">GIÁ:</span>{" "}
                          {formatVietnamDong(product.price)}
                        </p>
                        <div className="flex items-center mb-2">
                          <span className="font-semibold mr-2">SỐ LƯỢNG:</span>
                          <button
                            onClick={() => removeItemFromCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-l"
                          >
                            -
                          </button>
                          <span className="px-4">{product.quantity}</span>
                          <button
                            onClick={() => handleItemToCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-r"
                          >
                            +
                          </button>
                        </div>
                        <p className="text-sm font-medium">
                          <span className="font-semibold">TỔNG CỘNG:</span>{" "}
                          {formatVietnamDong(product.totalPrice)}
                        </p>
                      </>
                    )}
                  </div>
                ))} */}
                {products.map((product) => (
                  <div
                    key={product.id}
                    className={`${
                      isMobile
                        ? "flex flex-col items-start text-left p-4 bg-white mb-6 rounded-lg shadow"
                        : "relative grid grid-cols-5 items-center text-center mb-6 bg-white p-4 rounded-lg shadow"
                    }`}
                  >
                    {/* Close Button for Desktop (top right corner) */}
                    {isDesktop && (
                      <button
                        className="absolute top-1/2 -translate-y-1/2 right-2 text-black text-lg"
                        onClick={() => removeEntireItem(product)}
                      >
                        <RxCross2 size={16} />
                      </button>
                    )}

                    {isDesktop && (
                      <>
                        <div className="col-span-2 flex items-center">
                          <LazyLoadImage
                            src={
                              product.img ||
                              "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                            }
                            className="w-16 h-16 md:w-20 md:h-20 object-cover mr-4"
                            alt={product.name}
                          />
                          <div className="text-left">
                            <p className="text-sm md:text-[17px] font-medium">
                              {product.name}
                            </p>
                            <p className="text-xs md:text-sm text-gray-500">
                              {product.brand}
                            </p>
                            <div className="flex gap-3">
                              {Object.entries(product.characteristic).map(
                                ([key, value]) => (
                                  <div
                                    key={key}
                                    className="flex gap-1 text-sm text-gray-500"
                                  >
                                    <p>{translateCharacteristicName(key)}:</p>
                                    <p>{value}</p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="font-medium">
                          {formatVietnamDong(product.price)}
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            onClick={() => removeItemFromCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-l"
                          >
                            -
                          </button>
                          <span className="px-4">{product.quantity}</span>
                          <button
                            onClick={() => handleItemToCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-r"
                          >
                            +
                          </button>
                        </div>
                        <div className="font-medium">
                          {formatVietnamDong(product.totalPrice)}
                        </div>
                      </>
                    )}

                    {isMobile && (
                      <>
                        {/* Close Button for Mobile */}
                        <button
                          className="self-end text-black text-lg mb-2"
                          onClick={() => removeEntireItem(product)}
                        >
                          <RxCross2 size={16} />
                        </button>
                        <LazyLoadImage
                          src={
                            product.img ||
                            "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                          }
                          className="w-24 h-24 object-cover mb-4"
                          alt={product.name}
                        />
                        <p className="text-sm font-medium mb-2">
                          <span className="font-semibold">SẢN PHẨM:</span>{" "}
                          {product.name}
                        </p>
                        <p className="text-sm font-medium mb-2">
                          <span className="font-semibold">GIÁ:</span>{" "}
                          {formatVietnamDong(product.price)}
                        </p>
                        <div className="flex items-center mb-2">
                          <span className="font-semibold mr-2">SỐ LƯỢNG:</span>
                          <button
                            onClick={() => removeItemFromCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-l"
                          >
                            -
                          </button>
                          <span className="px-4">{product.quantity}</span>
                          <button
                            onClick={() => handleItemToCart(product)}
                            className="px-2 py-1 bg-gray-200 rounded-r"
                          >
                            +
                          </button>
                        </div>
                        <p className="text-sm font-medium">
                          <span className="font-semibold">TỔNG CỘNG:</span>{" "}
                          {formatVietnamDong(product.totalPrice)}
                        </p>
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div className="w-full lg:w-1/3 bg-gray-100 rounded-lg shadow-lg p-4 md:p-6">
                <h2 className="text-lg md:text-2xl font-semibold mb-5">
                  THÔNG TIN ĐƠN HÀNG
                </h2>
                <div className="flex justify-between mb-3">
                  <span className="text-base md:text-lg">Số sản phẩm</span>
                  <span className="text-lg md:text-xl font-semibold">
                    {totalQuantity}
                  </span>
                </div>
                <div className="flex justify-between mb-8">
                  <span className="text-base md:text-lg">Tổng tiền</span>
                  <span className="text-lg md:text-xl font-semibold text-red-500">
                    {formatVietnamDong(totalAmount)}
                  </span>
                </div>

                <textarea
                  placeholder="Ghi chú đơn hàng"
                  className="w-full h-24 p-2 border rounded mb-4"
                ></textarea>

                <div className="mb-8">
                  <input
                    type="text"
                    placeholder="Nhập mã khuyến mãi (nếu có)"
                    className="w-full p-2 border rounded"
                  />
                </div>

                <button
                  onClick={() => navigate("/thanh-toan")}
                  className="w-full py-3 md:py-4 bg-black/85 text-white font-semibold uppercase rounded mb-4 hover:bg-black transition duration-300"
                >
                  Thanh toán ngay
                </button>
                <Link
                  to="/"
                  className="flex justify-center text-gray-600 gap-1 items-center hover:text-primary transition duration-300"
                >
                  <IoIosArrowRoundBack size={20} />
                  <span>Quay lại mua sắm</span>
                </Link>
              </div>
            </div>
          )}
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default CartPage;

// import { useAutoAnimate } from "@formkit/auto-animate/react";
// import { Helmet } from "react-helmet-async";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link, useNavigate } from "react-router-dom";
// import emptyCart from "../assets/emptycart.png";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
// import { CartItem } from "../shared/types";
// import {
//   formatVietnamDong,
//   translateCharacteristicName,
// } from "../shared/utils";
// import useCartStore from "../store/cartStore";
// import { IoIosArrowRoundBack } from "react-icons/io";

// const CartPage = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const addItemToCart = useCartStore((state) => state.addItemToCart);
//   const removeItemFromCart = useCartStore((state) => state.removeItemFromCart);
//   const products = useCartStore((state) => state.items);
//   const totalAmount = useCartStore((state) => state.totalAmount);

//   const handleItemToCart = (product: CartItem) => {
//     const addedProduct: CartItem = {
//       ...product,
//       quantity: 1,
//       totalPrice: product.price * 1,
//     };
//     addItemToCart(addedProduct);
//   };

//   const navigate = useNavigate();

//   const [parent] = useAutoAnimate();

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: "Giỏ hàng" },
//   ];

//   return (
//     <>
//       <div className="min-h-screen">
//         <Header />
//         <Helmet>
//           <title>Giỏ hàng của bạn | Vuavot</title>
//           <meta
//             name="description"
//             content="Xem giỏ hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín với các sản phẩm chất lượng từ Yonex, Victor, Lining và nhiều thương hiệu khác. Chuẩn bị sẵn sàng cho những trải nghiệm cầu lông tuyệt vời với sản phẩm của bạn!"
//           />
//         </Helmet>

//         <Container>
//           <HeroWithOverlay />
//           <Breadcrumb items={breadcrumbItems} />

//           <h1 className="text-2xl md:text-3xl font-semibold mb-5">GIỎ HÀNG</h1>

//           {products.length === 0 && (
//             <div>
//               <div className="flex justify-center mt-10">
//                 <LazyLoadImage src={emptyCart} width={300} />
//               </div>
//               <p className="text-lg md:text-xl font-semibold mb-5 text-center">
//                 Giỏ hàng của bạn đang trống
//               </p>
//             </div>
//           )}

//           {products.length > 0 && (
//             <div className="flex flex-col lg:flex-row gap-6 items-start">
//               <div
//                 ref={parent}
//                 className="w-full lg:w-2/3 bg-white md:p-6 rounded-lg shadow"
//               >
//                 <div className="hidden md:grid grid-cols-5 text-center font-semibold border-b-2 pb-4 mb-1">
//                   <div className="col-span-2">Sản phẩm</div>
//                   <div>Giá</div>
//                   <div>Số lượng</div>
//                   <div>Tổng tiền</div>
//                 </div>

//                 {/* {products.map((product) => (
//                   <div
//                     key={product.id}
//                     className="grid grid-cols-2 md:grid-cols-5 items-center text-center mb-6"
//                   >
//                     <div className="col-span-2 md:col-span-2 flex items-center">
//                       <LazyLoadImage
//                         src={
//                           product.img ||
//                           "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//                         }
//                         className="w-16 h-16 md:w-20 md:h-20 object-cover mr-4"
//                         alt={product.name}
//                       />
//                       <div className="text-left">
//                         <p className="text-sm md:text-[17px] font-medium">
//                           {product.name}
//                         </p>
//                         <p className="text-xs md:text-sm text-gray-500">
//                           {product.brand}
//                         </p>
//                         <div className="hidden md:flex gap-3">
//                           {Object.entries(product.characteristic).map(
//                             ([key, value]) => (
//                               <div
//                                 key={key}
//                                 className="flex gap-1 text-sm text-gray-500"
//                               >
//                                 <p>{translateCharacteristicName(key)}:</p>
//                                 <p>{value}</p>
//                               </div>
//                             )
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="font-medium hidden md:block">
//                       {formatVietnamDong(product.price)}
//                     </div>
//                     <div className="flex items-center justify-center">
//                       <button
//                         onClick={() => removeItemFromCart(product)}
//                         className="px-2 py-1 bg-gray-200 rounded-l"
//                       >
//                         -
//                       </button>
//                       <span className="px-2 md:px-4">{product.quantity}</span>
//                       <button
//                         onClick={() => handleItemToCart(product)}
//                         className="px-2 py-1 bg-gray-200 rounded-r"
//                       >
//                         +
//                       </button>
//                     </div>
//                     <div className="font-medium hidden md:block">
//                       {formatVietnamDong(product.totalPrice)}
//                     </div>
//                   </div>
//                 ))} */}
//                 {products.map((product) => (
//                   <div
//                     key={product.id}
//                     className="flex flex-col md:grid md:grid-cols-5 items-center text-center md:text-left mb-6 bg-white rounded-lg md:p-4 shadow"
//                   >
//                     <div className="flex md:col-span-2 items-center mb-4 md:mb-0">
//                       <LazyLoadImage
//                         src={
//                           product.img ||
//                           "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//                         }
//                         className="w-16 h-16 md:w-20 md:h-20 object-cover mr-4"
//                         alt={product.name}
//                       />
//                       <div className="text-left">
//                         <p className="text-sm md:text-[17px] font-medium">
//                           {product.name}
//                         </p>
//                         <p className="text-xs md:text-sm text-gray-500">
//                           {product.brand}
//                         </p>
//                         <div className="flex flex-wrap gap-2">
//                           {Object.entries(product.characteristic).map(
//                             ([key, value]) => (
//                               <div
//                                 key={key}
//                                 className="flex items-center gap-1 text-xs md:text-sm text-gray-500"
//                               >
//                                 <p>{translateCharacteristicName(key)}:</p>
//                                 <p>{value}</p>
//                               </div>
//                             )
//                           )}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="text-sm md:text-base font-medium mb-2 md:mb-0">
//                       {formatVietnamDong(product.price)}
//                     </div>

//                     <div className="flex items-center justify-center mb-2 md:mb-0">
//                       <button
//                         onClick={() => removeItemFromCart(product)}
//                         className="px-2 py-1 bg-gray-200 rounded-l"
//                       >
//                         -
//                       </button>
//                       <span className="px-2 md:px-4">{product.quantity}</span>
//                       <button
//                         onClick={() => handleItemToCart(product)}
//                         className="px-2 py-1 bg-gray-200 rounded-r"
//                       >
//                         +
//                       </button>
//                     </div>

//                     <div className="text-sm md:text-base font-medium text-center">
//                       {formatVietnamDong(product.totalPrice)}
//                     </div>
//                   </div>
//                 ))}
//               </div>

//               <div className="w-full lg:w-1/3 bg-gray-100 rounded-lg shadow-lg p-4 md:p-6">
//                 <h2 className="text-lg md:text-2xl font-semibold mb-5">
//                   THÔNG TIN ĐƠN HÀNG
//                 </h2>
//                 <div className="flex justify-between mb-3">
//                   <span className="text-base md:text-lg">Số sản phẩm</span>
//                   <span className="text-lg md:text-xl font-semibold">
//                     {totalQuantity}
//                   </span>
//                 </div>
//                 <div className="flex justify-between mb-8">
//                   <span className="text-base md:text-lg">Tổng tiền</span>
//                   <span className="text-lg md:text-xl font-semibold text-red-500">
//                     {formatVietnamDong(totalAmount)}
//                   </span>
//                 </div>

//                 <textarea
//                   placeholder="Ghi chú đơn hàng"
//                   className="w-full h-24 p-2 border rounded mb-4"
//                 ></textarea>

//                 <div className="mb-8">
//                   <input
//                     type="text"
//                     placeholder="Nhập mã khuyến mãi (nếu có)"
//                     className="w-full p-2 border rounded"
//                   />
//                 </div>

//                 <button
//                   onClick={() => navigate("/thanh-toan")}
//                   className="w-full py-3 md:py-4 bg-black/85 text-white font-semibold uppercase rounded mb-4 hover:bg-black transition duration-300"
//                 >
//                   Thanh toán ngay
//                 </button>
//                 <Link
//                   to="/"
//                   className="flex justify-center text-gray-600 gap-1 items-center hover:text-primary transition duration-300"
//                 >
//                   <IoIosArrowRoundBack size={20} />
//                   <span>Quay lại mua sắm</span>
//                 </Link>
//               </div>
//             </div>
//           )}
//         </Container>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default CartPage;

// import { useAutoAnimate } from "@formkit/auto-animate/react";
// import { Helmet } from "react-helmet-async";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link, useNavigate } from "react-router-dom";
// import emptyCart from "../assets/emptycart.png";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
// import { CartItem } from "../shared/types";
// import {
//   formatVietnamDong,
//   translateCharacteristicName,
// } from "../shared/utils";
// import useCartStore from "../store/cartStore";
// import { IoIosArrowRoundBack } from "react-icons/io";
// const CartPage = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const addItemToCart = useCartStore((state) => state.addItemToCart);
//   const removeItemFromCart = useCartStore((state) => state.removeItemFromCart);
//   const products = useCartStore((state) => state.items);
//   const totalAmount = useCartStore((state) => state.totalAmount);
//   const handleItemToCart = (product: CartItem) => {
//     const addedProduct: CartItem = {
//       ...product,
//       quantity: 1,
//       totalPrice: product.price * 1,
//     };

//     addItemToCart(addedProduct);
//   };

//   const navigate = useNavigate();

//   const [parent] = useAutoAnimate();

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: "Giỏ hàng" },
//   ];

//   return (
//     <>
//       <div className="min-h-screen">
//         <Header />
//         <Helmet>
//           {/* Title and description for SEO */}
//           <title>Giỏ hàng của bạn | Vuavot</title>
//           <meta
//             name="description"
//             content="Xem giỏ hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín với các sản phẩm chất lượng từ Yonex, Victor, Lining và nhiều thương hiệu khác. Chuẩn bị sẵn sàng cho những trải nghiệm cầu lông tuyệt vời với sản phẩm của bạn!"
//           />

//           {/* Open Graph / Facebook */}
//           <meta property="og:title" content="Giỏ hàng của bạn | Vuavot" />
//           <meta
//             property="og:description"
//             content="Xem và quản lý giỏ hàng của bạn tại Vua Vợt. Cửa hàng chuyên cung cấp sản phẩm cầu lông chất lượng từ các thương hiệu hàng đầu. Đừng bỏ lỡ những ưu đãi hấp dẫn!"
//           />
//           <meta property="og:image" content="%PUBLIC_URL%/emptycart.png" />
//           <meta property="og:url" content="https://vuavot.com/gio-hang" />
//           <meta property="og:type" content="website" />

//           {/* Zalo */}
//           <meta property="zalo:title" content="Giỏ hàng của bạn | Vuavot" />
//           <meta
//             property="zalo:description"
//             content="Giỏ hàng của bạn tại Vua Vợt - Sản phẩm cầu lông chính hãng từ Yonex, Victor, Lining, và nhiều thương hiệu khác đang chờ bạn trải nghiệm!"
//           />
//           <meta property="zalo:image" content="%PUBLIC_URL%/emptycart.png" />
//           <meta property="zalo:url" content="https://vuavot.com/gio-hang" />
//         </Helmet>

//         <Container>
//           <HeroWithOverlay />
//           <Breadcrumb items={breadcrumbItems} />
//           <div className="container mx-auto pb-10">
//             <h1 className="text-3xl font-semibold mb-5">GIỎ HÀNG</h1>

//             {products.length === 0 && (
//               <div>
//                 <div className="flex justify-center mt-10">
//                   <LazyLoadImage src={emptyCart} width={300} />
//                 </div>
//                 <p className="text-xl font-semibold mb-5 text-center">
//                   Giỏ hàng của bạn đang trống
//                 </p>
//               </div>
//             )}

//             {products.length > 0 && (
//               <div className="flex flex-col lg:flex-row gap-6 items-start">
//                 <div ref={parent} className="w-full lg:w-2/3 bg-white p-6 ">
//                   <div className="grid grid-cols-5 text-center font-semibold border-b-2 pb-4 mb-1">
//                     <div className="col-span-2 ">Sản phẩm</div>
//                     <div>Giá</div>
//                     <div>Số lượng</div>
//                     <div>Tổng tiền</div>
//                   </div>

//                   {products.map((product) => (
//                     <div
//                       key={product.id}
//                       className="grid grid-cols-5 items-center text-center mb-6"
//                     >
//                       <div className="col-span-2 flex items-center">
//                         <LazyLoadImage
//                           src={
//                             product.img ||
//                             "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//                           }
//                           className="w-20 h-20 object-cover mr-4"
//                           alt={product.name}
//                         />
//                         <div className="text-left">
//                           <p className="text-[17px] font-medium">
//                             {" "}
//                             {product.name}
//                           </p>
//                           <p className="text-sm text-gray-500">
//                             {product.brand}
//                           </p>

//                           {/* Display other product characteristics if needed */}
//                           <p className="text-sm text-gray-500">
//                             {/* {product.characteristic?.size || ""}  */}
//                           </p>
//                           <div className="flex gap-3">
//                             {Object.entries(product.characteristic).map(
//                               ([key, value]) => (
//                                 <div
//                                   key={key}
//                                   className="flex gap-1 text-sm text-gray-500"
//                                 >
//                                   <p className="">
//                                     {translateCharacteristicName(key)}:
//                                   </p>
//                                   <p className="">{value}</p>
//                                 </div>
//                               )
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                       <div className="font-medium">
//                         {formatVietnamDong(product.price)}
//                       </div>
//                       <div className="flex items-center justify-center">
//                         <button
//                           onClick={() => removeItemFromCart(product)}
//                           className="px-2 py-1 bg-gray-200 rounded-l"
//                         >
//                           -
//                         </button>
//                         <span className="px-4">{product.quantity}</span>
//                         <button
//                           onClick={() => handleItemToCart(product)}
//                           className="px-2 py-1 bg-gray-200 rounded-r"
//                         >
//                           +
//                         </button>
//                       </div>
//                       <div className="font-medium">
//                         {formatVietnamDong(product.totalPrice)}
//                       </div>
//                     </div>
//                   ))}
//                 </div>

//                 <div className="w-full lg:w-1/3 bg-gray-100 rounded-lg shadow-lg p-6">
//                   <h2 className="text-2xl font-semibold mb-5">
//                     THÔNG TIN ĐƠN HÀNG
//                   </h2>
//                   <div className="flex justify-between mb-3">
//                     <span className="text-lg">Số sản phẩm</span>
//                     <span className="text-xl font-semibold ">
//                       {totalQuantity}
//                     </span>
//                   </div>
//                   <div className="flex justify-between mb-8">
//                     <span className="text-lg">Tổng tiền</span>
//                     <span className="text-xl font-semibold text-red-500">
//                       {formatVietnamDong(totalAmount)}
//                     </span>
//                   </div>

//                   <textarea
//                     placeholder="Ghi chú đơn hàng"
//                     className="w-full h-24 p-2 border rounded mb-4"
//                   ></textarea>

//                   <div className="mb-8">
//                     <input
//                       type="text"
//                       placeholder="Nhập mã khuyến mãi (nếu có)"
//                       className="w-full p-2 border rounded"
//                     />
//                   </div>

//                   <button
//                     onClick={() => navigate("/thanh-toan")}
//                     className="w-full py-4 bg-black/85 text-white font-semibold uppercase rounded mb-4 hover:bg-black transition duration-300"
//                   >
//                     Thanh toán ngay
//                   </button>
//                   <Link
//                     to="/"
//                     className="justify-center text-gray-600 flex gap-1 items-center hover:text-primary transition duration-300"
//                   >
//                     <IoIosArrowRoundBack size={20} />
//                     <span>Quay lại mua sắm</span>
//                   </Link>
//                 </div>
//               </div>
//             )}
//           </div>
//         </Container>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default CartPage;

// import { useAutoAnimate } from "@formkit/auto-animate/react";
// import { Helmet } from "react-helmet-async";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link } from "react-router-dom";
// import emptyCart from "../assets/emptycart.png";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
// import { CartItem } from "../shared/types";
// import {
//   formatVietnamDong,
//   translateCharacteristicName,
// } from "../shared/utils";
// import useCartStore from "../store/cartStore";
// const CartPage = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const addItemToCart = useCartStore((state) => state.addItemToCart);
//   const removeItemFromCart = useCartStore((state) => state.removeItemFromCart);
//   const products = useCartStore((state) => state.items);
//   const totalAmount = useCartStore((state) => state.totalAmount);
//   const handleItemToCart = (product: CartItem) => {
//     const addedProduct: CartItem = {
//       ...product,
//       quantity: 1,
//       totalPrice: product.price * 1,
//     };

//     addItemToCart(addedProduct);
//   };

//   const [parent] = useAutoAnimate();

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: "Giỏ hàng" },
//   ];

//   return (
//     <>
//       <div className="min-h-screen">
//         <Header />
//         <Helmet>
//           {/* Title and description for SEO */}
//           <title>Giỏ hàng của bạn | Vuavot</title>
//           <meta
//             name="description"
//             content="Xem giỏ hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín với các sản phẩm chất lượng từ Yonex, Victor, Lining và nhiều thương hiệu khác. Chuẩn bị sẵn sàng cho những trải nghiệm cầu lông tuyệt vời với sản phẩm của bạn!"
//           />

//           {/* Open Graph / Facebook */}
//           <meta property="og:title" content="Giỏ hàng của bạn | Vuavot" />
//           <meta
//             property="og:description"
//             content="Xem và quản lý giỏ hàng của bạn tại Vua Vợt. Cửa hàng chuyên cung cấp sản phẩm cầu lông chất lượng từ các thương hiệu hàng đầu. Đừng bỏ lỡ những ưu đãi hấp dẫn!"
//           />
//           <meta property="og:image" content="%PUBLIC_URL%/emptycart.png" />
//           <meta property="og:url" content="https://vuavot.com/gio-hang" />
//           <meta property="og:type" content="website" />

//           {/* Zalo */}
//           <meta property="zalo:title" content="Giỏ hàng của bạn | Vuavot" />
//           <meta
//             property="zalo:description"
//             content="Giỏ hàng của bạn tại Vua Vợt - Sản phẩm cầu lông chính hãng từ Yonex, Victor, Lining, và nhiều thương hiệu khác đang chờ bạn trải nghiệm!"
//           />
//           <meta property="zalo:image" content="%PUBLIC_URL%/emptycart.png" />
//           <meta property="zalo:url" content="https://vuavot.com/gio-hang" />
//         </Helmet>

//         <Container>
//           <HeroWithOverlay />
//           <Breadcrumb items={breadcrumbItems} />
//           <div className="bg-lightWhite  mt-10 py-8 px-6 ">
//             <div className="flex justify-between items-center border-primary border-b-2 pb-4">
//               <p className="text-2xl font-semibold uppercase">
//                 Giỏ hàng của bạn
//               </p>
//               <p className="text-xl font-medium">{totalQuantity} sản phẩm</p>
//             </div>

//             {products.length === 0 && (
//               <div>
//                 <div className="flex justify-center mt-10">
//                   <LazyLoadImage src={emptyCart} width={300} />
//                 </div>
//                 <p className="text-xl font-semibold mb-5 text-center">
//                   Giỏ hàng của bạn đang trống
//                 </p>
//                 <p className="mt-3 tracking-wider max-w-[600px] mx-auto text-center">
//                   Hãy lướt qua bộ sưu tập đa dạng của chúng tôi, chọn cho mình
//                   những sản phẩm ưng ý nhất và đừng quên nhận ngay những khuyến
//                   mãi khi mua sắm!
//                 </p>
//               </div>
//             )}
//             {products.length !== 0 && (
//               <div ref={parent}>
//                 <div className="grid grid-cols-6 text-center text-lg mt-10 uppercase  py-2  font-medium">
//                   <div className="col-span-3">Chi tiết sản phẩm</div>
//                   <div className="">Số lượng</div>
//                   <div>Giá</div>
//                   <div className="">Tổng tiền</div>
//                 </div>
//                 {products.map((product) => (
//                   <div
//                     key={product.id}
//                     className="grid grid-cols-6 text-center mt-4 bg-white px-4 py-1 rounded-lg"
//                   >
//                     <div className="col-span-3 flex items-center gap-6">
//                       <div className="aspect-square w-24 bg-blue-100">
//                         <img
//                           src={
//                             product.img ||
//                             "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//                           }
//                           alt={product.name}
//                           className="w-full h-full object-cover"
//                         />
//                       </div>
//                       <div className="text-left">
//                         <p className="text-lg font-medium mb-2">
//                           {product.name}
//                         </p>
//                         <p className="text-sm text-gray-500">{product.brand}</p>
//                         {/* Display other product characteristics if needed */}
//                         <p className="text-sm text-gray-500">
//                           {/* {product.characteristic?.size || ""}  */}
//                         </p>
//                         <div className="flex gap-3">
//                           {Object.entries(product.characteristic).map(
//                             ([key, value]) => (
//                               <div
//                                 key={key}
//                                 className="flex gap-1 text-sm text-gray-500"
//                               >
//                                 <p className="">
//                                   {translateCharacteristicName(key)}:
//                                 </p>
//                                 <p className="">{value}</p>
//                               </div>
//                             )
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="flex items-center gap-6  mx-auto">
//                       <button
//                         onClick={() => removeItemFromCart(product)}
//                         className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg transition duration-300"
//                       >
//                         -
//                       </button>

//                       <span className="text-lg font-bold">
//                         {product.quantity}
//                       </span>

//                       <button
//                         onClick={() => handleItemToCart(product)}
//                         className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg transition duration-300"
//                       >
//                         +
//                       </button>
//                     </div>
//                     <div className="text-lg font-medium content-center">
//                       {formatVietnamDong(product.price)}
//                     </div>
//                     <div className="text-lg font-medium content-center">
//                       {formatVietnamDong(product.totalPrice)}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//           <div className="flex gap-5 justify-end items-center text-lg mt-8">
//             <div className="">Tổng tiền:</div>
//             <span className="text-xl font-medium">
//               {formatVietnamDong(totalAmount)}
//             </span>
//           </div>
//           <div className="flex justify-end mt-3">
//             <Link
//               to="/thanh-toan"
//               className="px-20 py-3 bg-primary hover-brigher-color rounded-lg text-white font-semibold uppercase"
//             >
//               Mua ngay
//             </Link>
//           </div>
//         </Container>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default CartPage;
