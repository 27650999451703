import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";

const Images = () => {
  return (
    <section className="py-10 flex flex-col gap-4 md:flex-row md:gap-6 justify-center">
      <div className="w-full md:w-1/2 lg:w-1/3">
        <img src={img1} alt="img1" className="w-full h-auto object-cover" />
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3">
        <img src={img2} alt="img2" className="w-full h-auto object-cover" />
      </div>
      <div className="hidden md:block w-full md:w-1/2 lg:w-1/3">
        <img src={img3} alt="img3" className="w-full h-auto object-cover" />
      </div>
    </section>
  );
};

export default Images;
