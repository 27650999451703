import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useViewport from "../../hooks/useViewport";
import { SALE_BADGE_CONDITION } from "../../shared/constant";
import { Product } from "../../shared/types";
import ProductCard from "./ProductCard";
import Skeleton from "./Skeleton";

interface ProductSliderProps {
  products?: Product[];
}

const ProductSlider: React.FC<ProductSliderProps> = ({ products }) => {
  const { isMobile, isTablet, isDesktop } = useViewport();

  const slidesPerView = isMobile ? 1.5 : isTablet ? 2.5 : 4;
  const spaceBetween = isMobile ? 16 : isTablet ? 20 : 30;
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      navigation
      loop
      className="px-2 md:px-5 lg:px-7"
    >
      {products ? (
        products.map((product: Product) => (
          <SwiperSlide key={product.productId} className="py-2 select-none">
            <ProductCard
              id={product.productId}
              category={product.categoryName}
              name={` ${product.productName} `}
              brand={product.brand}
              originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
              salePrice={`${product.price?.toLocaleString()}₫`}
              colors={["#FFA500", "#FF0000", "#000000"]}
              badges={[
                ...(product.isPopular ? ["HOT"] : []),
                ...((product.discountPercentage || 0) > SALE_BADGE_CONDITION
                  ? ["SALE"]
                  : []),
              ]}
              discountPercentage={product.discountPercentage}
              imageUrl={product.imageUrl || "/path/to/default/image.png"}
            />
          </SwiperSlide>
        ))
      ) : (
        <ProductCardSkeleton />
      )}
    </Swiper>
  );
};

export default ProductSlider;

const ProductCardSkeleton: React.FC = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-sm flex flex-col items-center">
      {/* Image Skeleton */}
      <Skeleton className="w-full h-0 pb-[100%] mb-3 rounded-lg" />{" "}
      {/* 1:1 aspect ratio */}
      {/* Text Skeletons */}
      <Skeleton className="h-4 w-3/4 mb-2" /> {/* Product Title */}
      <Skeleton className="h-4 w-1/2 mb-2" /> {/* Brand */}
      <Skeleton className="h-5 w-1/3 mb-2" /> {/* Price */}
      <Skeleton className="h-4 w-1/4" /> {/* Discount Percentage */}
    </div>
  );
};
// import { Navigation } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Product } from "../../shared/types";
// import { sortedProductsHasImageComeFirst } from "../../shared/utils";
// import ProductItem from "./ProductItem";
// import "swiper/css";
// import "swiper/css/navigation";
// import useViewport from "../../hooks/useViewport";

// interface ProductSliderProps {
//   products?: Product[];
// }

// const ProductSlider: React.FC<ProductSliderProps> = ({ products }) => {
//   const { isMobile, isTablet, isDesktop } = useViewport();

//   const slidesPerView = isMobile ? 1.5 : isTablet ? 2.5 : 4;
//   const spaceBetween = isMobile ? 16 : isTablet ? 20 : 30;
//   return (
//     <Swiper
//       modules={[Navigation]}
//       spaceBetween={spaceBetween} // Mặc định khoảng cách giữa các slides
//       slidesPerView={slidesPerView} // Hiển thị 1.5 slide trên mobile mặc định
//       navigation
//       loop
//     >
//       {sortedProductsHasImageComeFirst(products)?.map((p) => (
//         <SwiperSlide key={p.productId} className="py-2">
//           <ProductItem product={p} />
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

// export default ProductSlider;

// import { Navigation } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Product } from "../../shared/types";
// import { sortedProductsHasImageComeFirst } from "../../shared/utils";
// import ProductItem from "./ProductItem";

// interface ProductSliderProps {
//   products?: Product[];
// }

// const ProductSlider: React.FC<ProductSliderProps> = ({ products }) => {
//   return (
//     <Swiper
//       modules={[Navigation]}
//       spaceBetween={30}
//       slidesPerView={4}
//       navigation
//       loop
//     >
//       {sortedProductsHasImageComeFirst(products)?.map((p) => (
//         <SwiperSlide key={p.productId} className="py-2">
//           <ProductItem product={p} />
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

// export default ProductSlider;
