import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { getCategorySlugFromName } from "../../shared/utils";

interface ProductCardProps {
  id: string;
  category: string;
  name: string;
  brand: string;
  originalPrice: string;
  salePrice: string;
  colors: string[];
  // badge?: "SALE" | "HOT" | string;
  badges?: string[];
  imageUrl: string;
  discountPercentage?: number;
}

// Function to generate a random rating (5, 4.5, or 4 stars)
const getRandomRating = () => {
  const ratings = [5, 4.5, 4];
  return ratings[Math.floor(Math.random() * ratings.length)];
};

const ProductCard: React.FC<ProductCardProps> = ({
  id,
  category,
  name,
  brand,
  originalPrice,
  salePrice,
  colors,
  // badge,
  imageUrl,
  badges,
  discountPercentage,
}) => {
  // Memoize the random rating to ensure it doesn't change on every render
  const rating = useMemo(getRandomRating, []);
  return (
    <Link
      to={`/san-pham/${getCategorySlugFromName(category)}/${id}`}
      className="bg-[#FAFAFA] p-3 md:p-4 rounded-lg shadow-md relative flex flex-col select-none hover:scale-105 hover:brightness-110 transition duration-300 min-h-[250px] md:min-h-[300px]"
    >
      {/* Badge */}
      {/* {badge && (
        <div
          className={`absolute top-3 left-3 px-2 py-1 text-xs rounded-full text-white ${
            badge === "SALE" ? "bg-primary" : "bg-green-500"
          }`}
        >
          {badge}
        </div>
      )} */}
      {(badges?.length || 0) > 0 && (
        <ul className={`absolute top-3 left-3 flex gap-1 flex-wrap`}>
          {badges?.map((badge) => (
            <li
              key={badge}
              className={`px-2 py-1 text-xs rounded-full text-white ${
                badge === "SALE" ? "bg-primary" : "bg-green-500"
              }`}
            >
              {badge === "HOT"
                ? badge
                : `-${Math.round(discountPercentage || 0)}%`}
            </li>
          ))}
        </ul>
      )}

      {/* Product Image */}
      <img
        src={imageUrl || "https://via.placeholder.com/200"}
        alt={name}
        className="w-full h-48 md:h-72 object-contain mb-2 md:mb-4 "
      />

      {/* Rating */}
      <div className="flex items-center text-gray-400 text-xs md:text-sm mb-1 md:mb-2">
        {[...Array(5)].map((_, index) => {
          if (index + 1 <= rating) {
            return <FaStar key={index} className="text-yellow-400" />;
          } else if (index + 0.5 === rating) {
            return <FaStarHalfAlt key={index} className="text-yellow-400" />;
          } else {
            return <FaRegStar key={index} />;
          }
        })}
      </div>

      {/* Product Name */}
      <h3 className="text-xs md:text-base font-semibold mb-1">{name}</h3>

      {/* Brand */}
      <p className="text-xs md:text-sm italic text-gray-500 mb-1 md:mb-2">
        {brand}
      </p>

      {/* Color Swatches */}
      <div className="flex items-center mb-1 md:mb-2">
        {colors.map((color, index) => (
          <span
            key={index}
            className="w-3 h-3 md:w-4 md:h-4 rounded-full mr-1"
            style={{ backgroundColor: color }}
          ></span>
        ))}
      </div>

      {/* Pricing */}
      <div className="flex items-center space-x-2 mt-auto">
        <span className="text-primary font-bold text-sm md:text-base">
          {salePrice}
        </span>
        {originalPrice && originalPrice !== salePrice && (
          <span className="text-gray-400 line-through text-xs md:text-sm">
            {originalPrice}
          </span>
        )}
      </div>
    </Link>
  );
};

export default ProductCard;

// import React, { useMemo } from "react";
// import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { getCategorySlugFromName } from "../../shared/utils";

// interface ProductCardProps {
//   name: string;
//   brand: string;
//   originalPrice: string;
//   salePrice: string;
//   colors: string[];
//   badge?: "SALE" | "NEW" | string;
//   imageUrl: string;
//   id: string;
//   category: string;
// }

// // Function to generate a random rating (5, 4.5, or 4 stars)
// const getRandomRating = () => {
//   const ratings = [5, 4.5, 4];
//   return ratings[Math.floor(Math.random() * ratings.length)];
// };

// const ProductCard: React.FC<ProductCardProps> = ({
//   id,
//   category,
//   name,
//   brand,
//   originalPrice,
//   salePrice,
//   colors,
//   badge,
//   imageUrl,
// }) => {
//   // Memoize the random rating to ensure it doesn't change on every render
//   const rating = useMemo(getRandomRating, []);
//   return (
//     <Link
//       to={`/san-pham/${getCategorySlugFromName(category)}/${id}`}
//       className="bg-[#FAFAFA] p-4 rounded-lg shadow-md relative min-h-[300px] flex flex-col select-none hover:scale-105 transition duration-300"
//     >
//       {/* Badge */}
//       {badge && (
//         <div
//           className={`absolute top-2 left-2 px-2 py-1 text-xs rounded-full text-white ${
//             badge === "SALE" ? "bg-primary" : "bg-green-500"
//           }`}
//         >
//           {badge}
//         </div>
//       )}

//       {/* Product Image */}
//       <img
//         src={
//           imageUrl ||
//           "https://lh3.googleusercontent.com/proxy/nudYzQ3984mywzsR2KAW04IvPhs_1Xlg4HhmmEIj8FgSzCVTZMt3d7z0edyeetyKGscA9dsIF8lpS3OCMRik1g0AyqzvcJjj_t00idVhVDIx0lTZwWBa9e8"
//         }
//         alt={name}
//         className="w-full h-72 object-cover mb-4"
//       />

//       {/* Rating */}
//       <div className="flex items-center text-gray-400 text-sm mb-2">
//         {[...Array(5)].map((_, index) => {
//           if (index + 1 <= rating) {
//             return <FaStar key={index} className="text-yellow-400" />;
//           } else if (index + 0.5 === rating) {
//             return <FaStarHalfAlt key={index} className="text-yellow-400" />;
//           } else {
//             return <FaRegStar key={index} />;
//           }
//         })}
//       </div>

//       {/* Product Name */}
//       <h3 className="text-sm font-semibold mb-1">{name}</h3>

//       {/* Brand */}
//       <p className="text-sm italic text-gray-500 mb-2">{brand}</p>

//       {/* Color Swatches */}
//       <div className="flex items-center mb-2">
//         {colors.map((color, index) => (
//           <span
//             key={index}
//             className="w-4 h-4 rounded-full mr-1"
//             style={{ backgroundColor: color }}
//           ></span>
//         ))}
//       </div>

//       {/* Pricing */}
//       <div className="flex items-center space-x-2 mt-auto">
//         <span className="text-primary font-bold">{salePrice}</span>
//         {originalPrice && (
//           <span className="text-gray-400 line-through">{originalPrice}</span>
//         )}
//       </div>
//     </Link>
//   );
// };

// export default ProductCard;
