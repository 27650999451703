import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { fetchAllProducts } from "../services/home";
import ProductCollection from "../components/Collection/ProductCollection";
import Header from "../components/Layout/Header";
import Container from "../components/Common/Container";
import Footer from "../components/Layout/Footer";
import Pagination from "../components/Common/Pagination";
import { Helmet } from "react-helmet-async";
import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
import Breadcrumb from "../components/Common/Breadcrumb";

const SearchResultPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q") || "";
  const page = parseInt(searchParams.get("page") || "1", 10);

  const [filters, setFilters] = useState({
    SearchQuery: query,
    pageIndex: page,
    pageSize: 12,
  });

  const { data, error, isLoading, isFetching, isPlaceholderData } = useQuery({
    queryKey: ["searchProducts", filters],
    queryFn: () => fetchAllProducts("all", filters),
    enabled: !!filters.SearchQuery,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      SearchQuery: query,
      pageIndex: page,
    }));
  }, [query, page]);

  const handlePageChange = (newPageIndex: number) => {
    setSearchParams({ q: query, page: newPageIndex.toString() });
  };

  // const renderPagination = () => {
  //   if (!data?.paging) return null;

  //   const totalPages = data.paging.totalPage;
  //   const currentPage = filters.pageIndex;

  //   const paginationButtons = [];

  //   // Nút mũi tên cho trang trước
  //   if (currentPage > 1) {
  //     paginationButtons.push(
  //       <button
  //         key="prev"
  //         onClick={() => handlePageChange(currentPage - 1)}
  //         className="px-3 py-2 rounded-md bg-gray-200 hover:bg-gray-300"
  //       >
  //         &larr;
  //       </button>
  //     );
  //   }

  //   // Hiển thị các nút trang
  //   const pageNumbers = [];
  //   if (totalPages <= 5) {
  //     // Nếu có 5 trang hoặc ít hơn, hiển thị tất cả các trang
  //     for (let i = 1; i <= totalPages; i++) {
  //       pageNumbers.push(i);
  //     }
  //   } else {
  //     // Logic hiển thị 5 trang liền kề và các nút điều hướng
  //     if (currentPage <= 3) {
  //       // Trường hợp đang ở các trang đầu tiên (1, 2, 3)
  //       pageNumbers.push(1, 2, 3, 4, 5);
  //       pageNumbers.push(-1); // Để đại diện cho dấu ba chấm
  //       pageNumbers.push(totalPages);
  //     } else if (currentPage >= totalPages - 2) {
  //       // Trường hợp đang ở các trang cuối (totalPages - 2, totalPages - 1, totalPages)
  //       pageNumbers.push(1);
  //       pageNumbers.push(-1); // Để đại diện cho dấu ba chấm
  //       for (let i = totalPages - 4; i <= totalPages; i++) {
  //         pageNumbers.push(i);
  //       }
  //     } else {
  //       // Trường hợp ở các trang giữa
  //       pageNumbers.push(1);
  //       pageNumbers.push(-1); // Để đại diện cho dấu ba chấm
  //       pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
  //       pageNumbers.push(-2); // Để đại diện cho dấu ba chấm sau
  //       pageNumbers.push(totalPages);
  //     }
  //   }

  //   pageNumbers.forEach((pageNum) => {
  //     if (pageNum === -1 || pageNum === -2) {
  //       paginationButtons.push(
  //         <span key={pageNum} className="px-3 py-2">
  //           ...
  //         </span>
  //       );
  //     } else {
  //       paginationButtons.push(
  //         <button
  //           key={pageNum}
  //           onClick={() => handlePageChange(pageNum)}
  //           className={`px-3 py-2 rounded-md ${
  //             pageNum === currentPage
  //               ? "bg-blue-500 text-white"
  //               : "bg-gray-200 hover:bg-gray-300"
  //           }`}
  //         >
  //           {pageNum}
  //         </button>
  //       );
  //     }
  //   });

  //   // Nút mũi tên cho trang sau
  //   if (currentPage < totalPages) {
  //     paginationButtons.push(
  //       <button
  //         key="next"
  //         onClick={() => handlePageChange(currentPage + 1)}
  //         className="px-3 py-2 rounded-md bg-gray-200 hover:bg-gray-300"
  //       >
  //         &rarr;
  //       </button>
  //     );
  //   }

  //   return (
  //     <div className="mt-4 flex justify-center space-x-2">
  //       {paginationButtons}
  //     </div>
  //   );
  // };

  if (error) return <p>Đã có lỗi xảy ra khi tải kết quả tìm kiếm.</p>;

  const breadcrumbItems = [
    { label: "Trang chủ", link: "/" },
    { label: "Kết quả tìm kiếm" },
  ];

  return (
    <div>
      <Helmet>
        {/* Title and description for SEO */}
        <title>{`Kết quả tìm kiếm ${query} | Vuavot`}</title>
        <meta
          name="description"
          content={`Xem các kết quả tìm kiếm cho "${query}" tại Vua Vợt - nơi cung cấp sản phẩm cầu lông chất lượng từ các thương hiệu hàng đầu như Yonex, Victor, và Lining.`}
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content={`Kết quả tìm kiếm cho "${query}" | Vuavot`}
        />
        <meta
          property="og:description"
          content={`Tìm kiếm sản phẩm cầu lông tại Vua Vợt với kết quả cho từ khóa "${query}". Khám phá các sản phẩm từ Yonex, Victor, Lining, và nhiều thương hiệu nổi tiếng khác.`}
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta
          property="og:url"
          content={`https://vuavot.com/tim-kiem?q=${encodeURIComponent(query)}`}
        />
        <meta property="og:type" content="website" />

        {/* Zalo */}
        <meta
          property="zalo:title"
          content={`Kết quả tìm kiếm cho "${query}" | Vuavot`}
        />
        <meta
          property="zalo:description"
          content={`Tìm kiếm sản phẩm cầu lông tại Vua Vợt với kết quả cho từ khóa "${query}". Khám phá các sản phẩm từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng khác.`}
        />
        <meta property="zalo:image" content="%PUBLIC_URL%/logo.png" />
        <meta
          property="zalo:url"
          content={`https://vuavot.com/tim-kiem?q=${encodeURIComponent(query)}`}
        />
      </Helmet>
      <Header />

      <Container className="min-h-screen">
        <HeroWithOverlay />
        <Breadcrumb items={breadcrumbItems} />
        <h1 className="text-2xl font-bold mb-4 mt-10">
          {data?.paging?.totalCount} kết quả tìm kiếm cho: "{query}"
        </h1>

        {isLoading || isFetching ? (
          <p>Đang tải...</p>
        ) : (
          <>
            {data?.data.length === 0 ? (
              <p>Không có sản phẩm nào khớp với tìm kiếm của bạn.</p>
            ) : (
              <ProductCollection products={data?.data} />
            )}

            {data?.paging && data.paging.totalPage > 1 && (
              <Pagination
                currentPage={filters.pageIndex}
                totalPages={data.paging.totalPage}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default SearchResultPage;
