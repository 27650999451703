import { SALE_BADGE_CONDITION } from "../../shared/constant";
import { Product } from "../../shared/types";
import ProductCard from "../Common/ProductCard";
import Skeleton from "../Common/Skeleton";

interface ProductCollectionProps {
  products?: Product[];
}

const ProductCollection: React.FC<ProductCollectionProps> = ({ products }) => {
  return (
    <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 bg-[#f7f7f7] py-6 lg:px-6 px-1">
      {products
        ? products.map((product) => (
            <li key={product.productId}>
              <ProductCard
                id={product.productId}
                category={product.categoryName}
                name={`${product.productName} `}
                brand={product.brand}
                originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
                salePrice={`${product.price?.toLocaleString()}₫`}
                colors={["#FFA500", "#FF0000", "#000000"]}
                badges={[
                  ...(product.isPopular ? ["HOT"] : []),
                  ...((product.discountPercentage || 0) > SALE_BADGE_CONDITION
                    ? ["SALE"]
                    : []),
                ]}
                discountPercentage={product.discountPercentage}
                imageUrl={product.imageUrl || "/path/to/default/image.png"}
              />
            </li>
          ))
        : [...new Array(20)].map((_, index) => (
            <li key={index}>
              <ProductSkeleton />
            </li>
          ))}
    </ul>
  );
};

export default ProductCollection;

// import { SALE_BADGE_CONDITION } from "../../shared/constant";
// import { Product } from "../../shared/types";
// import ProductCard from "../Common/ProductCard";
// import Skeleton from "../Common/Skeleton";

// interface ProductCollectionProps {
//   products?: Product[];
// }

// const ProductCollection: React.FC<ProductCollectionProps> = ({ products }) => {
//   return (
//     <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 bg-[#f7f7f7] py-6 lg:px-6 px-1">
//       {products &&
//         products.map((product) => (
//           <li key={product.productId}>
//             <ProductCard
//               id={product.productId}
//               category={product.categoryName}
//               // name={product.productName}
//               name={`${product.categoryName} ${product.productName} Chính hãng`}
//               brand={product.brand}
//               originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
//               salePrice={`${product.price?.toLocaleString()}₫`}
//               colors={["#FFA500", "#FF0000", "#000000"]}
//               // badge={
//               //   product.isPopular
//               //     ? "HOT"
//               //     : (product.discountPercentage || 0) > SALE_BADGE_CONDITION
//               //     ? "SALE"
//               //     : undefined
//               // }
//               badges={[
//                 ...(product.isPopular ? ["HOT"] : []),
//                 ...((product.discountPercentage || 0) > SALE_BADGE_CONDITION
//                   ? ["SALE"]
//                   : []),
//               ]}
//               discountPercentage={product.discountPercentage}
//               imageUrl={product.imageUrl || "/path/to/default/image.png"}
//             />
//           </li>
//         ))}
//       {!products &&
//         [...new Array(20)].map((_, index) => (
//           <li key={index}>
//             <Skeleton className="h-0 pb-[120%]" />
//           </li>
//         ))}
//     </ul>
//   );
// };

// export default ProductCollection;

const ProductSkeleton: React.FC = () => {
  return (
    <div className="p-2 bg-white rounded-lg shadow-sm">
      {/* Image Skeleton */}
      <Skeleton className="w-full h-0 pb-[120%] mb-2" />

      {/* Text Skeletons */}
      <Skeleton className="h-4 w-3/4 mb-2" />
      <Skeleton className="h-4 w-1/2 mb-2" />
      <Skeleton className="h-4 w-1/3" />
    </div>
  );
};
