import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import useViewport from "../../hooks/useViewport";
import { fetchMainProducts } from "../../services/home";
import { MainProduct, Product } from "../../shared/types";
import ProductSlider from "../Common/ProductSlider";

// Helper function to shuffle an array
const shuffleArray = (array: Product[]) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

const ClothingSlider: React.FC = () => {
  const { isMobile, isTablet } = useViewport();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  const {
    isPending: isLoadingMainProduct,
    error: errorMainProduct,
    data: dataMainProduct,
  } = useQuery({
    queryKey: ["mainProducts"],
    queryFn: fetchMainProducts,
  });

  if (isLoadingMainProduct) return <div>Loading...</div>;
  if (errorMainProduct) return <div>Error fetching main products</div>;

  // Define the categories to include
  const clothingCategories = [
    "Áo Cầu Lông",
    "Quần Cầu Lông",
    "Váy Cầu Lông",
    "Túi Cầu Lông",
    "Balo Cầu Lông",
  ];

  // Filter products based on selected category
  const filteredProducts =
    selectedCategory === "All"
      ? shuffleArray(
          dataMainProduct?.data
            .filter((mainProduct: MainProduct) =>
              clothingCategories.includes(mainProduct.categoryName)
            )
            .flatMap((mainProduct: MainProduct) => mainProduct.products) || []
        )
      : dataMainProduct?.data.find(
          (mainProduct: MainProduct) =>
            mainProduct.categoryName === selectedCategory
        )?.products;

  return (
    <div className="py-4 md:py-6 lg:py-8">
      <div className="px-4 md:px-5">
        <div className="flex flex-col md:flex-row md:justify-between items-center mb-3 md:mb-5">
          {/* Title */}
          <h2 className="text-lg md:text-2xl lg:text-3xl font-extrabold mb-4 md:mb-0">
            QUẦN ÁO CẦU LÔNG
          </h2>

          {/* Category Filter Buttons */}
          <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
            <button
              onClick={() => setSelectedCategory("All")}
              className={`px-3 py-1 md:px-4 md:py-2 rounded-md ${
                selectedCategory === "All"
                  ? "bg-primary text-white"
                  : "border border-gray-300 text-gray-600"
              } text-sm md:text-base`}
            >
              Tất cả
            </button>

            {clothingCategories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-3 py-1 md:px-4 md:py-2 rounded-md ${
                  selectedCategory === category
                    ? "bg-primary text-white"
                    : "border border-gray-300 text-gray-600"
                } text-sm md:text-base`}
              >
                {category}
              </button>
            ))}

            {/* Right Arrow Button */}
            <button
              onClick={() =>
                navigate("/san-pham/ao-cau-long?orderByDescending=popular")
              }
              className="px-3 py-1 md:px-4 md:py-2 rounded-md border border-gray-300 text-gray-600 flex items-center text-sm md:text-base"
            >
              <AiOutlineArrowRight className="text-lg" />
            </button>
          </div>
        </div>

        {/* Product Slider */}
        <ProductSlider products={filteredProducts} />
        {/* <Swiper
          modules={[Navigation]}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          navigation
          loop
          className="px-2 md:px-5 lg:px-7"
        >
          {filteredProducts?.map((product: Product) => (
            <SwiperSlide key={product.productId} className="py-2 select-none">
              <ProductCard
                id={product.productId}
                category={product.categoryName}
                name={product.productName}
                brand={product.brand}
                originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
                salePrice={`${product.price?.toLocaleString()}₫`}
                colors={["#FFA500", "#FF0000", "#000000"]}
                // badge={
                //   product.isPopular
                //     ? "HOT"
                //     : (product.discountPercentage || 0) > SALE_BADGE_CONDITION
                //     ? "SALE"
                //     : undefined
                // }
                badges={[
                  ...(product.isPopular ? ["HOT"] : []),
                  ...((product.discountPercentage || 0) > SALE_BADGE_CONDITION
                    ? ["SALE"]
                    : []),
                ]}
                imageUrl={product.imageUrl || "/path/to/default/image.png"}
              />
            </SwiperSlide>
          ))}
        </Swiper> */}
      </div>
    </div>
  );
};

export default ClothingSlider;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import ProductCard from "../Common/ProductCard";
// import useViewport from "../../hooks/useViewport";
// import { useQuery } from "@tanstack/react-query";
// import { fetchMainProducts } from "../../services/home";
// import { AiOutlineArrowRight } from "react-icons/ai";
// import { MainProduct, Product } from "../../shared/types";

// // Helper function to shuffle an array
// const shuffleArray = (array: Product[]) => {
//   return array
//     .map((item) => ({ item, sort: Math.random() }))
//     .sort((a, b) => a.sort - b.sort)
//     .map(({ item }) => item);
// };

// const ClothingSlider: React.FC = () => {
//   const { isMobile, isTablet, isDesktop } = useViewport();
//   const [selectedCategory, setSelectedCategory] = useState("All");
//   const navigate = useNavigate();

//   const {
//     isPending: isLoadingMainProduct,
//     error: errorMainProduct,
//     data: dataMainProduct,
//   } = useQuery({
//     queryKey: ["mainProducts"],
//     queryFn: fetchMainProducts,
//   });

//   if (isLoadingMainProduct) {
//     return <div>Loading...</div>;
//   }

//   if (errorMainProduct) {
//     return <div>Error fetching main products</div>;
//   }

//   // Define the categories we want to include
//   const clothingCategories = [
//     "Áo Cầu Lông",
//     "Quần Cầu Lông",
//     "Váy Cầu Lông",
//     "Túi Cầu Lông",
//     "Balo Cầu Lông",
//   ];

//   // Combine products from all specified categories if "All" is selected and shuffle them
//   const filteredProducts =
//     selectedCategory === "All"
//       ? shuffleArray(
//           dataMainProduct?.data
//             .filter((mainProduct: MainProduct) =>
//               clothingCategories.includes(mainProduct.categoryName)
//             )
//             .flatMap((mainProduct: MainProduct) => mainProduct.products) || []
//         )
//       : dataMainProduct?.data.find(
//           (mainProduct: MainProduct) =>
//             mainProduct.categoryName === selectedCategory
//         )?.products;

//   const slidesPerView = isMobile ? 1.5 : isTablet ? 2.5 : 4;
//   const spaceBetween = isMobile ? 16 : isTablet ? 20 : 30;

//   return (
//     <div className="py-8">
//       <div className="px-5">
//         <div className="flex justify-between items-center">
//           {/* Title */}
//           <h2 className="text-2xl font-extrabold mb-4">QUẦN ÁO CẦU LÔNG</h2>

//           {/* Category Filter Buttons */}
//           <div className="flex flex-wrap space-x-2 mb-6">
//             {/* All Option */}
//             <button
//               onClick={() => setSelectedCategory("All")}
//               className={`px-4 py-2 rounded ${
//                 selectedCategory === "All"
//                   ? "bg-primary text-white"
//                   : "border border-gray-300 text-gray-600"
//               }`}
//             >
//               Tất cả
//             </button>

//             {/* Specific Clothing Categories */}
//             {clothingCategories.map((category) => (
//               <button
//                 key={category}
//                 onClick={() => setSelectedCategory(category)}
//                 className={`px-4 py-2 rounded ${
//                   selectedCategory === category
//                     ? "bg-primary text-white"
//                     : "border border-gray-300 text-gray-600"
//                 }`}
//               >
//                 {category}
//               </button>
//             ))}

//             {/* Right Arrow Button */}
//             <button
//               onClick={() => navigate("/san-pham/ao-cau-long")}
//               className="px-4 py-2 rounded border border-gray-300 text-gray-600 flex items-center"
//             >
//               <AiOutlineArrowRight className="text-lg" />
//             </button>
//           </div>
//         </div>

//         {/* Product Slider */}
//         <Swiper
//           modules={[Navigation]}
//           spaceBetween={spaceBetween}
//           slidesPerView={slidesPerView}
//           navigation
//           loop
//           className="px-7"
//         >
//           {filteredProducts?.map((product: Product) => (
//             <SwiperSlide key={product.productId} className="py-2 select-none">
//               <ProductCard
//                 id={product.productId}
//                 category={product.categoryName}
//                 name={product.productName}
//                 brand={product.brand}
//                 originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
//                 salePrice={`${product.price?.toLocaleString()}₫`}
//                 colors={["#FFA500", "#FF0000", "#000000"]}
//                 badge={product.isPopular ? "HOT" : undefined}
//                 imageUrl={product.imageUrl || "/path/to/default/image.png"}
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default ClothingSlider;
