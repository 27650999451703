import { FC } from "react";
import { Helmet } from "react-helmet-async";
import Container from "../components/Common/Container";
import AccessorySlider from "../components/Home/AccessorySlider";
import ClothingSlider from "../components/Home/ClothingSlider";
import DiscountBanner from "../components/Home/DiscountBanner";
import Features from "../components/Home/Features";
import FlashSale from "../components/Home/FlashSale";
import ImageBar from "../components/Home/ImageBar";
import Images from "../components/Home/Images";
import NewsEventSlider from "../components/Home/NewsEventSlider";
import ProductSliderWithBrands from "../components/Home/ProductSilderWithBrands";
import Services from "../components/Home/Services";
import ShoeSliderWithBrands from "../components/Home/ShoeSliderWithBrands";
import ShopByCategory from "../components/Home/ShopByCategory";
import SubscriptionBanner from "../components/Home/SubscriptionBanner";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Hero from "../components/Layout/Hero";
import homepage from "../assets/homepage.png";

const HomePage: FC = () => {
  return (
    <>
      <Helmet>
        {/* Title and description for SEO */}
        <title>
          VUAVOT.COM - Cửa hàng cầu lông chuyên nghiệp tại Nha Trang
        </title>
        <meta
          name="description"
          content="VUAVOT.COM - Chuyên cung cấp các thương hiệu Yonex, Victor, Lining, Felet cùng giày cầu lông, quần áo và phụ kiện tại 83 Thống Nhất, Vạn Thạnh, Nha Trang, Khánh Hòa."
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content="VUAVOT.COM - Cửa hàng cầu lông chuyên nghiệp tại Nha Trang"
        />
        <meta
          property="og:description"
          content="Khám phá các sản phẩm cầu lông chính hãng từ Yonex, Victor, Lining, Felet và nhiều phụ kiện tại VUAVOT.COM - Nơi đam mê của bạn tỏa sáng! Địa chỉ: 83 Thống Nhất, Vạn Thạnh, Nha Trang."
        />
        <meta property="og:image" content={homepage} />
        <meta property="og:url" content="https://vuavot.com" />
        <meta property="og:type" content="website" />

        {/* Zalo */}
        <meta
          property="zalo:title"
          content="VUAVOT.COM - Cửa hàng cầu lông chuyên nghiệp tại Nha Trang"
        />
        <meta
          property="zalo:description"
          content="Vua Vợt - Chuyên các sản phẩm cầu lông chính hãng từ các thương hiệu hàng đầu như Yonex, Victor, Lining, Felet. Ghé thăm chúng tôi tại 83 Thống Nhất, Vạn Thạnh, Nha Trang, Khánh Hòa."
        />
        <meta property="zalo:image" content={homepage} />
        <meta property="zalo:url" content="https://vuavot.com" />
      </Helmet>

      <Header />
      <Container>
        <Hero />
        <Services />
        <ShopByCategory />
        <FlashSale />
        <ProductSliderWithBrands />
        <Features />
        <ClothingSlider />
        <DiscountBanner />
        <ShoeSliderWithBrands />
        <Images />
        <AccessorySlider />
        <ImageBar />
        <NewsEventSlider />
      </Container>
      <SubscriptionBanner />

      <div
        className="zalo-chat-widget"
        data-oaid="794468836944095128"
        data-welcome-message="Rất vui khi được hỗ trợ bạn, Bạn vui lòng cho mình sđt để tiện tư vấn ạ!"
        data-autopopup="0"
        data-width=""
        data-height=""
      ></div>

      <script src="https://sp.zalo.me/plugins/sdk.js"></script>

      <Footer />
    </>
  );
};

export default HomePage;

// import { FC } from "react";
// import { Helmet } from "react-helmet-async";
// import Container from "../components/Common/Container";
// import AccessorySlider from "../components/Home/AccessorySlider";
// import ClothingSlider from "../components/Home/ClothingSlider";
// import DiscountBanner from "../components/Home/DiscountBanner";
// import Features from "../components/Home/Features";
// import FlashSale from "../components/Home/FlashSale";
// import ImageBar from "../components/Home/ImageBar";
// import Images from "../components/Home/Images";
// import NewsEventSlider from "../components/Home/NewsEventSlider";
// import ProductSliderWithBrands from "../components/Home/ProductSilderWithBrands";
// import Services from "../components/Home/Services";
// import ShoeSliderWithBrands from "../components/Home/ShoeSliderWithBrands";
// import ShopByCategory from "../components/Home/ShopByCategory";
// import SubscriptionBanner from "../components/Home/SubscriptionBanner";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import Hero from "../components/Layout/Hero";

// const HomePage: FC = () => {
//   return (
//     <>
//       <Helmet>
//         <title>Vua vợt - Nơi đam mê tỏa sáng</title>
//       </Helmet>
//       <Header />
//       <Container>
//         <Hero />
//         <Services />
//         <ShopByCategory />
//         <FlashSale />
//         <ProductSliderWithBrands />
//         <Features />
//         <ClothingSlider />
//         <DiscountBanner />
//         <ShoeSliderWithBrands />
//         <Images />
//         <AccessorySlider />
//         <ImageBar />
//         <NewsEventSlider />
//       </Container>
//       <SubscriptionBanner />
//       <Footer />
//     </>
//   );
// };

// export default HomePage;
