import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface TitleProps {
  title?: string;
  seeMoreUrl?: string;
  className?: string;
}

const Title: React.FC<TitleProps> = ({ title, seeMoreUrl, className }) => {
  return (
    <div className={`flex justify-between items-end mb-3 md:mb-5 ${className}`}>
      <div>
        <p className="text-primary font-semibold uppercase tracking-widest text-sm md:text-base">
          Vuavot
        </p>
        <h3 className="font-semibold text-2xl md:text-[30px] leading-tight md:leading-none">
          {title}
        </h3>
      </div>
      {seeMoreUrl && (
        <button className="flex items-center gap-1 text-sm md:text-base hover-primary-color">
          <Link
            to={seeMoreUrl || "/"}
            className="uppercase tracking-widest font-medium"
          >
            Xem thêm
          </Link>
          <FaArrowRightLong />
        </button>
      )}
    </div>
  );
};

export default Title;

// import { FaArrowRightLong } from "react-icons/fa6";
// import { Link } from "react-router-dom";

// interface TitleProps {
//   title?: string;
//   seeMoreUrl?: string;
// }

// const Title: React.FC<TitleProps> = ({ title, seeMoreUrl }) => {
//   return (
//     <div className="flex justify-between items-end mb-5">
//       <div>
//         <p className="text-primary font-semibold uppercase tracking-widest">
//           Vuavot
//         </p>
//         <h3 className="font-semibold text-[30px]  leading-none">{title}</h3>
//       </div>
//       <button className="flex items-center gap-1 hover-primary-color">
//         <Link
//           to={seeMoreUrl || "/"}
//           className="uppercase tracking-widest font-medium"
//         >
//           Xem thêm
//         </Link>
//         <FaArrowRightLong />
//       </button>
//     </div>
//   );
// };

// export default Title;
