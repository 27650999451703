import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import banner1 from "../../assets/banner-1.png";
import banner2 from "../../assets/banner-2.png";
import banner3 from "../../assets/banner-3.png";
import { Link } from "react-router-dom";

const images = [
  {
    src: banner3,
    text: "Cầm vợt trước\nTrả phí sau\n",
    subtext: "thủ tục xét duyệt nhanh",
    button: "Khám phá ngay",
    textPosition: "right",
  },
  {
    src: banner2,
    text: "Ưu đãi voucher\n",
    subtext: "quần áo cầu lông",
    button: "Khám phá ngay",
    textPosition: "left",
  },
  {
    src: banner1,
    text: "Huyền thoại Lin Đan\n",
    subtext: "dùng loại vợt gì",
    button: "Khám phá ngay",
    textPosition: "bottom",
  },
];

const Slider: React.FC = () => {
  return (
    <Swiper
      modules={[EffectFade, Autoplay]}
      effect="fade"
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      loop={true}
      className="w-full mt-4"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <div className="relative uppercase">
            <img
              src={image.src}
              alt={`slider pic ${index + 1}`}
              className={`object-cover object-center w-full h-[50vh] lg:h-auto `}
            />

            <div
              className={`absolute ${
                image.textPosition === "right"
                  ? "top-[38%] md:top-1/2 md:right-32 right-16"
                  : image.textPosition === "left"
                  ? "top-[45%] left-28  "
                  : "bottom-10 md:bottom-16 md:right-[23%] right-[10%]"
              } text-white flex flex-col items-start`}
            >
              <div>
                <p className="whitespace-pre-line font-extrabold  text-left text-2xl md:text-4xl">
                  {image.text}
                </p>
                <p className="font-semibold md:mt-2 text-base md:text-2xl">
                  {image.subtext}
                </p>
              </div>
              <Link
                to="/san-pham/vot-cau-long"
                className="px-4 py-2 bg-primary rounded hover:bg-primary-dark mt-5 transition duration-300"
              >
                {image.button}
              </Link>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
