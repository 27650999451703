import { CATEGORIES } from "./constant";
import { Category, Product } from "./types";

// Utility function to format number into VND currency
export const formatVietnamDong = (amount?: number | string) => {
  return (
    amount?.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    }) || "-"
  );
};

export const formatTextFirstLetterUppercase = (text?: string) => {
  if (!text || typeof text !== "string") return "";

  // Convert the first letter to uppercase and the rest to lowercase
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const sortedProductsHasImageComeFirst = (products?: Product[]) =>
  products?.slice().sort((a, b) => {
    // If 'a' has an imageUrl but 'b' does not, move 'a' before 'b'
    if (a.imageUrl && !b.imageUrl) return -1;
    // If 'b' has an imageUrl but 'a' does not, move 'b' before 'a'
    if (!a.imageUrl && b.imageUrl) return 1;
    // If both have or neither has an imageUrl, keep their order unchanged
    return 0;
  });

export const convertToSlug = (text?: string) => {
  return text
    ?.toLowerCase() // Convert to lowercase
    .normalize("NFD") // Normalize to decompose diacritics (accents)
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics (accents)
    .replace(/[^a-z0-9\s-]/g, "") // Remove any special characters
    .trim() // Remove leading/trailing spaces
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen

  // Example usage
  /* 
  console.log(convertToSlug('Vợt cầu lông')); // Output: vot-cau-long
  console.log(convertToSlug('túi Cầu LôNg')); // Output: tui-cau-long
  console.log(convertToSlug('Heineken')); // Output: heineken
  console.log(convertToSlug('NVL TP Bia')); // Output: nvl-tp-bia
  */
};

// 1. Convert CategorySlug to CategoryCode
export const getCategoryCodeFromSlug = (slug?: string) => {
  const category = CATEGORIES.find(
    (category) => category.CategorySlug === slug
  );
  return category ? category.CategoryCode : null;
};

// 2. Convert CategoryName to CategorySlug
export const getCategorySlugFromName = (name?: string) => {
  const category = CATEGORIES.find(
    (category) => category.CategoryName === name
  );
  return category ? category.CategorySlug : null;
};

// 3. Convert CategorySlug to CategoryName
export const getCategoryNameFromSlug = (slug?: string) => {
  const category = CATEGORIES.find(
    (category) => category.CategorySlug === slug
  );
  return category ? category.CategoryName : null;
};

export const calculatePercentageSaved = (
  originalPrice: number,
  discountedPrice: number
) => Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);

export const getTextFromHTML = (htmlString: string) => {
  return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
};

export function translateCharacteristicName(characteristicName: string) {
  if (characteristicName.toLowerCase().includes("color")) {
    return "Màu sắc";
  } else if (characteristicName.toLowerCase().includes("size")) {
    return "Kích cỡ";
  } else {
    return characteristicName; // Return the original name if no match is found
  }
}

export const removeDichVuCategory = (category: Category) =>
  category.categoryName !== "Dịch vụ";

export const saveRecentlyViewedProduct = (productId: string) => {
  let recentlyViewed =
    JSON.parse(localStorage.getItem("RecentlyViewedProducts") || "[]") || [];

  // Remove the product ID if it already exists in the list
  recentlyViewed = recentlyViewed.filter((id: string) => id !== productId);

  // Add the product ID to the end of the list
  recentlyViewed.push(productId);

  // Keep only the last 10 items
  if (recentlyViewed.length > 10) {
    recentlyViewed.shift();
  }

  // Save the updated list back to localStorage
  localStorage.setItem(
    "RecentlyViewedProducts",
    JSON.stringify(recentlyViewed)
  );
};
