import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { fetchBrands, fetchCategories } from "../../services/home";
import { useQuery } from "@tanstack/react-query";
import {
  getCategorySlugFromName,
  removeDichVuCategory,
} from "../../shared/utils";

interface NavigationDrawerProps {
  onClose: any;
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ onClose }) => {
  const [parent] = useAutoAnimate();
  const navigate = useNavigate();
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);

  // Fetch categories and brands
  const { data: dataCategory } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
    staleTime: 1000 * 60 * 5,
  });
  const { data: dataBrand } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
    staleTime: 1000 * 60 * 5,
  });

  // Toggle child category visibility
  const toggleCategory = (categoryId: string) => {
    setExpandedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  // Function to handle navigation with brand filter
  const handleChildCategoryClick = (
    parentCategoryName: string,
    childCategoryName: string
  ) => {
    if (parentCategoryName === "Phụ Kiện Cầu Lông") {
      navigate(`/tim-kiem?q=${encodeURIComponent(childCategoryName)}`);
      onClose();
      return;
    }

    const brandName = childCategoryName.split(" ").pop();
    const brand = dataBrand?.data.find(
      (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
    );
    const parentCategorySlug = getCategorySlugFromName(parentCategoryName);

    if (brand) {
      navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
      onClose();
    } else {
      console.error("Brand not found:", brandName);
    }
  };

  return (
    <div>
      <div
        className="animate__animated animate__fadeIn animate__faster fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={onClose}
      ></div>
      <div className="custom-scrollbar animate__animated animate__fadeInLeft animate__faster fixed top-0 left-0 w-[85%] sm:w-[50%] h-full bg-white shadow-lg z-50 overflow-x-hidden">
        <div className="flex justify-between items-center p-4 bg-primary border-b border-gray-100">
          <h2 className="text-lg font-semibold uppercase">Danh mục sản phẩm</h2>
          <button
            onClick={onClose}
            className="text-xl font-semibold text-gray-300 hover:text-gray-800"
          >
            <IoMdClose size={30} className="hover-primary-color" />
          </button>
        </div>

        <nav ref={parent} className="p-4 text-sm text-gray-700">
          <ul>
            {dataCategory?.data
              .filter(removeDichVuCategory)
              .map((parentCategory) => (
                <li key={parentCategory.categoryId} className="mb-4">
                  <div className="flex justify-between items-center">
                    <button
                      onClick={() =>
                        navigate(
                          `/san-pham/${getCategorySlugFromName(
                            parentCategory.categoryName
                          )}`
                        )
                      }
                      className=" font-medium uppercase"
                    >
                      {parentCategory.categoryName}
                    </button>
                    <button
                      onClick={() => toggleCategory(parentCategory.categoryId)}
                      className="text-gray-500 hover:text-primary-dark"
                    >
                      {expandedCategory === parentCategory.categoryId ? (
                        <FaMinus />
                      ) : (
                        <FaPlus />
                      )}
                    </button>
                  </div>

                  {/* Child Categories */}
                  {expandedCategory === parentCategory.categoryId && (
                    <ul className="pl-4 mt-2 space-y-3 text-gray-700">
                      {parentCategory.childCategories.map((childCategory) => (
                        <li
                          key={childCategory.categoryId}
                          onClick={() =>
                            handleChildCategoryClick(
                              parentCategory.categoryName,
                              childCategory.categoryName
                            )
                          }
                          className="cursor-pointer hover:text-primary"
                        >
                          {childCategory.categoryName}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavigationDrawer;

// import { useState } from "react";
// import { useAutoAnimate } from "@formkit/auto-animate/react";
// import { FaPlus, FaMinus } from "react-icons/fa";
// import { IoMdClose } from "react-icons/io";
// import { Link, useNavigate } from "react-router-dom";
// import { fetchBrands, fetchCategories } from "../../services/home";
// import { useQuery } from "@tanstack/react-query";
// import { getCategorySlugFromName } from "../../shared/utils";

// interface NavigationDrawerProps {
//   onClose: any;
// }

// const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ onClose }) => {
//   const [parent] = useAutoAnimate();
//   const navigate = useNavigate();
//   const [isCategoryOpen, setIsCategoryOpen] = useState(false);

//   // Fetch categories and brands
//   const { data: dataCategory, error: errorCategory } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//     staleTime: 1000 * 60 * 5,
//   });
//   const { data: dataBrand, error: errorBrand } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//     staleTime: 1000 * 60 * 5,
//   });

//   // Function to handle navigation with brand filter
//   const handleChildCategoryClick = (
//     parentCategoryName: string,
//     childCategoryName: string
//   ) => {
//     console.log(parentCategoryName);
//     if (parentCategoryName === "Phụ Kiện Cầu Lông") {
//       navigate(`/tim-kiem?q=${encodeURIComponent(childCategoryName)}`);
//       onClose();
//       return;
//     }

//     const brandName = childCategoryName.split(" ").pop();
//     const brand = dataBrand?.data.find(
//       (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
//     );
//     const parentCategorySlug = getCategorySlugFromName(parentCategoryName);

//     if (brand) {
//       navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
//       onClose(); // Close the dropdown after clicking a category
//     } else {
//       console.error("Brand not found:", brandName);
//     }
//   };

//   return (
//     <div>
//       <div
//         className="animate__animated animate__fadeIn animate__faster fixed inset-0 bg-black bg-opacity-50 z-40"
//         onClick={onClose}
//       ></div>
//       <div className="custom-scrollbar animate__animated animate__fadeInLeft animate__faster fixed top-0 left-0 w-[80%] sm:w-[50%] h-full bg-white shadow-lg z-50 overflow-x-hidden">
//         <div className="flex justify-between items-center p-4 bg-primary border-b border-gray-100">
//           <h2 className="text-lg font-semibold uppercase">Danh mục sản phẩm</h2>
//           <button
//             onClick={onClose}
//             className="text-xl font-semibold text-gray-300 hover:text-gray-800"
//           >
//             <IoMdClose size={30} className="hover-primary-color" />
//           </button>
//         </div>

//         <nav ref={parent} className="p-4 text-sm text-black">
//           <li>
//             <button
//               className="flex items-center justify-between w-full uppercase hover-primary-color"
//               onClick={() => setIsCategoryOpen((prev) => !prev)}
//             >
//               Sản phẩm
//               {isCategoryOpen ? <FaMinus /> : <FaPlus />}
//             </button>
//             {isCategoryOpen && (
//               <ul className="pl-4 mt-2 space-y-2 text-gray-700">
//                 {dataCategory?.data.map((parentCategory) => (
//                   <li key={parentCategory.categoryId}>
//                     <button
//                       onClick={() =>
//                         navigate(
//                           `/san-pham/${getCategorySlugFromName(
//                             parentCategory.categoryName
//                           )}`
//                         )
//                       }
//                       className="block text-primary font-bold uppercase hover:text-primary-dark"
//                     >
//                       {parentCategory.categoryName}
//                     </button>
//                     <ul className="pl-4 mt-1 space-y-1 text-sm">
//                       {parentCategory.childCategories.map((childCategory) => (
//                         <li
//                           key={childCategory.categoryId}
//                           onClick={() =>
//                             handleChildCategoryClick(
//                               parentCategory.categoryName,
//                               childCategory.categoryName
//                             )
//                           }
//                           className="cursor-pointer hover:text-primary"
//                         >
//                           {childCategory.categoryName}
//                         </li>
//                       ))}
//                     </ul>
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </li>
//         </nav>
//       </div>
//     </div>
//   );
// };

// export default NavigationDrawer;

// import { useAutoAnimate } from "@formkit/auto-animate/react";
// import React from "react";
// import { IoMdClose } from "react-icons/io";
// import { Link, useNavigate } from "react-router-dom";
// interface NavigationDrawerProps {
//   onClose: any;
// }

// const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ onClose }) => {
//   const [parent] = useAutoAnimate();
//   const navigate = useNavigate();

//   return (
//     <div>
//       <div
//         className={`animate__animated animate__fadeIn animate__faster fixed inset-0 bg-black bg-opacity-50 z-40`}
//         onClick={onClose}
//       ></div>

//       <div
//         className={`custom-scrollbar animate__animated animate__fadeInLeft animate__faster fixed top-0 left-0 w-[80%] h-full bg-white shadow-lg z-50 overflow-x-hidden`}
//       >
//         <div className="flex justify-between items-center p-4 bg-blue-100 border-b border-gray-100">
//           <h2 className="text-lg font-semibold uppercase">Danh mục sản phẩm</h2>
//           <button
//             onClick={onClose}
//             className="text-xl font-semibold text-gray-600 hover:text-gray-800"
//           >
//             <IoMdClose size={30} className="hover-primary-color" />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NavigationDrawer;
