import React from "react";

interface SkeletonProps {
  width?: string;
  height?: string;
  className?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({
  width = "100%",
  height = "20px",
  className,
}) => {
  return (
    <div
      className={`bg-gray-200 animate-pulse ${className}`}
      style={{ width, height }}
    ></div>
  );
};

export default Skeleton;

// import { FC, HTMLProps } from "react";

// interface SkeletonProps {
//   className?: string;
//   children?: React.ReactNode;
// }

// const Skeleton: FC<HTMLProps<HTMLDivElement> & SkeletonProps> = ({
//   className,
//   children,
//   ...others
// }) => {
//   return (
//     <div
//       className={`${className} animate-pulse bg-gray-200 rounded-md `}
//       {...others}
//     >
//       {children}
//     </div>
//   );
// };

// export default Skeleton;
