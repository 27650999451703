import React from "react";
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const paginationButtons = [];

  // Previous page button
  if (currentPage > 1) {
    paginationButtons.push(
      <button
        key="prev"
        onClick={() => onPageChange(currentPage - 1)}
        className="px-2 py-1 sm:px-3 sm:py-2 rounded-md bg-gray-200 hover:bg-gray-300"
      >
        &larr;
      </button>
    );
  }

  const pageNumbers = [];
  if (totalPages <= 5) {
    // Show all pages if total pages is 5 or less
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage <= 3) {
      // Show first five pages, add ellipsis if there’s a gap to the last page
      pageNumbers.push(1, 2, 3, 4, 5);
      if (totalPages > 6) {
        pageNumbers.push(-1); // Ellipsis
      }
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - 2) {
      // Show last five pages, add ellipsis if there’s a gap from the first page
      pageNumbers.push(1);
      if (totalPages > 6) {
        pageNumbers.push(-1); // Ellipsis
      }
      pageNumbers.push(
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      );
    } else {
      // Show current, previous, and next pages, with ellipses as necessary
      pageNumbers.push(1);
      if (currentPage > 4) {
        pageNumbers.push(-1); // Ellipsis before current range
      }
      pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
      if (currentPage < totalPages - 3) {
        pageNumbers.push(-2); // Ellipsis after current range
      }
      pageNumbers.push(totalPages);
    }
  }

  pageNumbers.forEach((pageNum, index) => {
    if (pageNum === -1 || pageNum === -2) {
      paginationButtons.push(
        <span key={`ellipsis-${index}`} className="px-2 py-1 sm:px-3 sm:py-2">
          ...
        </span>
      );
    } else {
      paginationButtons.push(
        <button
          key={pageNum}
          onClick={() => onPageChange(pageNum)}
          className={`px-2 py-1 sm:px-3 sm:py-2 rounded-md ${
            pageNum === currentPage
              ? "bg-primary text-white"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
        >
          {pageNum}
        </button>
      );
    }
  });

  // Next page button
  if (currentPage < totalPages) {
    paginationButtons.push(
      <button
        key="next"
        onClick={() => onPageChange(currentPage + 1)}
        className="px-2 py-1 sm:px-3 sm:py-2 rounded-md bg-gray-200 hover:bg-gray-300"
      >
        &rarr;
      </button>
    );
  }

  return (
    <div className="my-5 flex justify-center space-x-1 sm:space-x-2">
      {paginationButtons}
    </div>
  );
};

export default Pagination;

// import React from "react";
// interface PaginationProps {
//   currentPage: number;
//   totalPages: number;
//   onPageChange: (page: number) => void;
// }

// const Pagination: React.FC<PaginationProps> = ({
//   currentPage,
//   totalPages,
//   onPageChange,
// }) => {
//   const paginationButtons = [];

//   // Previous page button
//   if (currentPage > 1) {
//     paginationButtons.push(
//       <button
//         key="prev"
//         onClick={() => onPageChange(currentPage - 1)}
//         className="px-2 py-1 sm:px-3 sm:py-2 rounded-md bg-gray-200 hover:bg-gray-300"
//       >
//         &larr;
//       </button>
//     );
//   }

//   const pageNumbers = [];
//   if (totalPages <= 5) {
//     // Show all pages if total pages is 5 or less
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(i);
//     }
//   } else {
//     if (currentPage <= 3) {
//       // When current page is near the start
//       pageNumbers.push(1, 2, 3, 4, 5);
//       if (totalPages > 5) {
//         pageNumbers.push(totalPages);
//       }
//     } else if (currentPage >= totalPages - 2) {
//       // When current page is near the end
//       pageNumbers.push(1);
//       if (totalPages > 5) {
//         pageNumbers.push(
//           totalPages - 4,
//           totalPages - 3,
//           totalPages - 2,
//           totalPages - 1,
//           totalPages
//         );
//       }
//     } else {
//       // When current page is in the middle
//       pageNumbers.push(1);
//       if (currentPage > 4) {
//         pageNumbers.push(-1); // Represents ellipsis if gap is present
//       }
//       pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
//       if (currentPage < totalPages - 3) {
//         pageNumbers.push(-2); // Represents ellipsis at the end if gap is present
//       }
//       pageNumbers.push(totalPages);
//     }
//   }

//   pageNumbers.forEach((pageNum, index) => {
//     if (pageNum === -1 || pageNum === -2) {
//       paginationButtons.push(
//         <span key={index} className="px-2 py-1 sm:px-3 sm:py-2">
//           ...
//         </span>
//       );
//     } else {
//       paginationButtons.push(
//         <button
//           key={pageNum}
//           onClick={() => onPageChange(pageNum)}
//           className={`px-2 py-1 sm:px-3 sm:py-2 rounded-md ${
//             pageNum === currentPage
//               ? "bg-primary text-white"
//               : "bg-gray-200 hover:bg-gray-300"
//           }`}
//         >
//           {pageNum}
//         </button>
//       );
//     }
//   });

//   // Next page button
//   if (currentPage < totalPages) {
//     paginationButtons.push(
//       <button
//         key="next"
//         onClick={() => onPageChange(currentPage + 1)}
//         className="px-2 py-1 sm:px-3 sm:py-2 rounded-md bg-gray-200 hover:bg-gray-300"
//       >
//         &rarr;
//       </button>
//     );
//   }

//   return (
//     <div className="my-5 flex justify-center space-x-1 sm:space-x-2">
//       {paginationButtons}
//     </div>
//   );
// };

// export default Pagination;

// import React from "react";
// interface PaginationProps {
//   currentPage: number;
//   totalPages: number;
//   onPageChange: (page: number) => void;
// }

// const Pagination: React.FC<PaginationProps> = ({
//   currentPage,
//   totalPages,
//   onPageChange,
// }) => {
//   const paginationButtons = [];

//   if (currentPage > 1) {
//     paginationButtons.push(
//       <button
//         key="prev"
//         onClick={() => onPageChange(currentPage - 1)}
//         className="px-2 py-1 sm:px-3 sm:py-2 rounded-md bg-gray-200 hover:bg-gray-300"
//       >
//         &larr;
//       </button>
//     );
//   }

//   const pageNumbers = [];
//   if (totalPages <= 5) {
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(i);
//     }
//   } else {
//     if (currentPage <= 3) {
//       pageNumbers.push(1, 2, 3, 4, 5);
//       pageNumbers.push(-1); // Represents ellipsis
//       pageNumbers.push(totalPages);
//     } else if (currentPage >= totalPages - 2) {
//       pageNumbers.push(1);
//       pageNumbers.push(-1); // Represents ellipsis
//       for (let i = totalPages - 4; i <= totalPages; i++) {
//         pageNumbers.push(i);
//       }
//     } else {
//       pageNumbers.push(1);
//       pageNumbers.push(-1); // Represents ellipsis
//       pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
//       pageNumbers.push(-2); // Represents ellipsis at the end
//       pageNumbers.push(totalPages);
//     }
//   }

//   pageNumbers.forEach((pageNum) => {
//     if (pageNum === -1 || pageNum === -2) {
//       paginationButtons.push(
//         <span key={pageNum} className="px-2 py-1 sm:px-3 sm:py-2">
//           ...
//         </span>
//       );
//     } else {
//       paginationButtons.push(
//         <button
//           key={pageNum}
//           onClick={() => onPageChange(pageNum)}
//           className={`px-2 py-1 sm:px-3 sm:py-2 rounded-md ${
//             pageNum === currentPage
//               ? "bg-primary text-white"
//               : "bg-gray-200 hover:bg-gray-300"
//           }`}
//         >
//           {pageNum}
//         </button>
//       );
//     }
//   });

//   if (currentPage < totalPages) {
//     paginationButtons.push(
//       <button
//         key="next"
//         onClick={() => onPageChange(currentPage + 1)}
//         className="px-2 py-1 sm:px-3 sm:py-2 rounded-md bg-gray-200 hover:bg-gray-300"
//       >
//         &rarr;
//       </button>
//     );
//   }

//   return (
//     <div className="my-5 flex justify-center space-x-1 sm:space-x-2">
//       {paginationButtons}
//     </div>
//   );
// };

// export default Pagination;

// // components/Common/Pagination.tsx
// import React from "react";

// interface PaginationProps {
//   currentPage: number;
//   totalPages: number;
//   onPageChange: (page: number) => void;
// }

// const Pagination: React.FC<PaginationProps> = ({
//   currentPage,
//   totalPages,
//   onPageChange,
// }) => {
//   const paginationButtons = [];

//   // Previous page arrow
//   if (currentPage > 1) {
//     paginationButtons.push(
//       <button
//         key="prev"
//         onClick={() => onPageChange(currentPage - 1)}
//         className="px-3 py-2 rounded-md bg-gray-200 hover:bg-gray-300"
//       >
//         &larr;
//       </button>
//     );
//   }

//   // Determine the page numbers to display
//   const pageNumbers = [];
//   if (totalPages <= 5) {
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(i);
//     }
//   } else {
//     if (currentPage <= 3) {
//       pageNumbers.push(1, 2, 3, 4, 5);
//       pageNumbers.push(-1); // Represents ellipsis
//       pageNumbers.push(totalPages);
//     } else if (currentPage >= totalPages - 2) {
//       pageNumbers.push(1);
//       pageNumbers.push(-1); // Represents ellipsis
//       for (let i = totalPages - 4; i <= totalPages; i++) {
//         pageNumbers.push(i);
//       }
//     } else {
//       pageNumbers.push(1);
//       pageNumbers.push(-1); // Represents ellipsis
//       pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
//       pageNumbers.push(-2); // Represents ellipsis at the end
//       pageNumbers.push(totalPages);
//     }
//   }

//   // Render page buttons
//   pageNumbers.forEach((pageNum) => {
//     if (pageNum === -1 || pageNum === -2) {
//       paginationButtons.push(
//         <span key={pageNum} className="px-3 py-2">
//           ...
//         </span>
//       );
//     } else {
//       paginationButtons.push(
//         <button
//           key={pageNum}
//           onClick={() => onPageChange(pageNum)}
//           className={`px-3 py-2 rounded-md ${
//             pageNum === currentPage
//               ? "bg-primary text-white"
//               : "bg-gray-200 hover:bg-gray-300"
//           }`}
//         >
//           {pageNum}
//         </button>
//       );
//     }
//   });

//   // Next page arrow
//   if (currentPage < totalPages) {
//     paginationButtons.push(
//       <button
//         key="next"
//         onClick={() => onPageChange(currentPage + 1)}
//         className="px-3 py-2 rounded-md bg-gray-200 hover:bg-gray-300"
//       >
//         &rarr;
//       </button>
//     );
//   }

//   return (
//     <div className="my-10 flex justify-center space-x-2">
//       {paginationButtons}
//     </div>
//   );
// };

// export default Pagination;
