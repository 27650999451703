import React, { useEffect, useState } from "react";
import axios from "axios";

interface Province {
  id: string;
  name: string;
}

interface District {
  id: string;
  name: string;
}

interface Ward {
  id: string;
  name: string;
}

interface AddressSelectorProps {
  onAddressChange: (address: {
    province: string;
    district: string;
    ward: string;
    detail: string;
  }) => void;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
  onAddressChange,
}) => {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [wards, setWards] = useState<Ward[]>([]);

  const [selectedProvince, setSelectedProvince] = useState<string>("");
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [selectedWard, setSelectedWard] = useState<string>("");
  const [detailAddress, setDetailAddress] = useState<string>("");

  useEffect(() => {
    // Fetch the list of provinces
    axios
      .get("https://open.oapi.vn/location/provinces?page=0&size=64")
      .then((response) => setProvinces(response.data.data))
      .catch((error) => console.error("Error fetching provinces:", error));
  }, []);

  useEffect(() => {
    // Fetch the list of districts when a province is selected
    if (selectedProvince) {
      axios
        .get(
          `https://open.oapi.vn/location/districts/${selectedProvince}?page=0`
        )
        .then((response) => setDistricts(response.data.data))
        .catch((error) => console.error("Error fetching districts:", error));
    } else {
      setDistricts([]);
      setWards([]);
    }
  }, [selectedProvince]);

  useEffect(() => {
    // Fetch the list of wards when a district is selected
    if (selectedDistrict) {
      axios
        .get(`https://open.oapi.vn/location/wards/${selectedDistrict}?page=0`)
        .then((response) => setWards(response.data.data))
        .catch((error) => console.error("Error fetching wards:", error));
    } else {
      setWards([]);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    // Trigger the onAddressChange callback when any address field changes
    onAddressChange({
      province: selectedProvince,
      district: selectedDistrict,
      ward: selectedWard,
      detail: detailAddress,
    });
  }, [
    selectedProvince,
    selectedDistrict,
    selectedWard,
    detailAddress,
    onAddressChange,
  ]);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mb-10">
      {/* Province Select */}
      <select
        value={selectedProvince}
        onChange={(e) => {
          setSelectedProvince(e.target.value);
          setSelectedDistrict("");
          setSelectedWard("");
        }}
        className="border border-gray-300 px-3 py-2 rounded-md"
      >
        <option value="">Chọn tỉnh/thành phố</option>
        {provinces.map((province) => (
          <option key={province.id} value={province.id}>
            {province.name}
          </option>
        ))}
      </select>

      {/* District Select */}
      <select
        value={selectedDistrict}
        onChange={(e) => {
          setSelectedDistrict(e.target.value);
          setSelectedWard("");
        }}
        className="border border-gray-300 px-3 py-2 rounded-md"
        disabled={!selectedProvince}
      >
        <option value="">Chọn quận/huyện</option>
        {districts.map((district) => (
          <option key={district.id} value={district.id}>
            {district.name}
          </option>
        ))}
      </select>

      {/* Ward Select */}
      <select
        value={selectedWard}
        onChange={(e) => setSelectedWard(e.target.value)}
        className="border border-gray-300 px-3 py-2 rounded-md"
        disabled={!selectedDistrict}
      >
        <option value="">Chọn xã/phường</option>
        {wards.map((ward) => (
          <option key={ward.id} value={ward.id}>
            {ward.name}
          </option>
        ))}
      </select>

      {/* Detailed Address Input */}
      <input
        type="text"
        value={detailAddress}
        onChange={(e) => setDetailAddress(e.target.value)}
        placeholder="Tên đường, số nhà"
        className="border border-gray-300 px-3 py-2 rounded-md md:col-span-2 lg:col-span-3"
      />
    </div>
  );
};

export default AddressSelector;

// import React, { useEffect, useState } from "react";
// import axios from "axios";

// interface Province {
//   id: string;
//   name: string;
// }

// interface District {
//   id: string;
//   name: string;
// }

// interface Ward {
//   id: string;
//   name: string;
// }

// interface AddressSelectorProps {
//   onAddressChange: (address: {
//     province: string;
//     district: string;
//     ward: string;
//     detail: string;
//   }) => void;
// }

// const AddressSelector: React.FC<AddressSelectorProps> = ({
//   onAddressChange,
// }) => {
//   const [provinces, setProvinces] = useState<Province[]>([]);
//   const [districts, setDistricts] = useState<District[]>([]);
//   const [wards, setWards] = useState<Ward[]>([]);

//   const [selectedProvince, setSelectedProvince] = useState<string>("");
//   const [selectedDistrict, setSelectedDistrict] = useState<string>("");
//   const [selectedWard, setSelectedWard] = useState<string>("");
//   const [detailAddress, setDetailAddress] = useState<string>("");

//   useEffect(() => {
//     // Fetch the list of provinces
//     axios
//       .get("https://open.oapi.vn/location/provinces?page=0&size=64")
//       .then((response) => setProvinces(response.data.data))
//       .catch((error) => console.error("Error fetching provinces:", error));
//   }, []);

//   useEffect(() => {
//     // Fetch the list of districts when a province is selected
//     if (selectedProvince) {
//       axios
//         .get(
//           `https://open.oapi.vn/location/districts/${selectedProvince}?page=0`
//         )
//         .then((response) => setDistricts(response.data.data))
//         .catch((error) => console.error("Error fetching districts:", error));
//     } else {
//       setDistricts([]);
//       setWards([]);
//     }
//   }, [selectedProvince]);

//   useEffect(() => {
//     // Fetch the list of wards when a district is selected
//     if (selectedDistrict) {
//       axios
//         .get(`https://open.oapi.vn/location/wards/${selectedDistrict}?page=0`)
//         .then((response) => setWards(response.data.data))
//         .catch((error) => console.error("Error fetching wards:", error));
//     } else {
//       setWards([]);
//     }
//   }, [selectedDistrict]);

//   useEffect(() => {
//     // Trigger the onAddressChange callback when any address field changes
//     onAddressChange({
//       province: selectedProvince,
//       district: selectedDistrict,
//       ward: selectedWard,
//       detail: detailAddress,
//     });
//   }, [
//     selectedProvince,
//     selectedDistrict,
//     selectedWard,
//     detailAddress,
//     onAddressChange,
//   ]);

//   return (
//     <>
//       {/* Province Select */}
//       <select
//         value={selectedProvince}
//         onChange={(e) => {
//           setSelectedProvince(e.target.value);
//           setSelectedDistrict("");
//           setSelectedWard("");
//         }}
//         className="border border-gray-300 px-3 py-2 rounded-md"
//       >
//         <option value="">Chọn tỉnh/thành phố</option>
//         {provinces.map((province) => (
//           <option
//             key={province.id}
//             value={province.id}
//             className="bg-transparent py-2 hover:bg-primary"
//           >
//             {province.name}
//           </option>
//         ))}
//       </select>

//       {/* District Select */}
//       <select
//         value={selectedDistrict}
//         onChange={(e) => {
//           setSelectedDistrict(e.target.value);
//           setSelectedWard("");
//         }}
//         className="border border-gray-300 px-3 py-2 rounded-md"
//         disabled={!selectedProvince}
//       >
//         <option value="">Chọn quận/huyện</option>
//         {districts.map((district) => (
//           <option key={district.id} value={district.id}>
//             {district.name}
//           </option>
//         ))}
//       </select>

//       {/* Ward Select */}
//       <select
//         value={selectedWard}
//         onChange={(e) => setSelectedWard(e.target.value)}
//         className="border border-gray-300 px-3 py-2 rounded-md"
//         disabled={!selectedDistrict}
//       >
//         <option value="">Chọn xã/phường</option>
//         {wards.map((ward) => (
//           <option key={ward.id} value={ward.id}>
//             {ward.name}
//           </option>
//         ))}
//       </select>

//       {/* Detailed Address Input */}
//       <input
//         type="text"
//         value={detailAddress}
//         onChange={(e) => setDetailAddress(e.target.value)}
//         placeholder="Tên đường, số nhà"
//         className="border border-gray-300 px-3 py-2 rounded-md col-span-3"
//       />
//     </>
//   );
// };

// export default AddressSelector;
