import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Helmet>
        {/* Title and description for SEO */}
        <title>Không tìm thấy trang | Vuavot</title>
        <meta
          name="description"
          content="Trang bạn đang tìm không tồn tại trên Vuavot. Khám phá các sản phẩm cầu lông, tennis và phụ kiện chất lượng cao từ các thương hiệu hàng đầu."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Không tìm thấy trang | Vuavot" />
        <meta
          property="og:description"
          content="Rất tiếc! Trang bạn đang tìm không tồn tại trên Vuavot. Khám phá các sản phẩm cầu lông và phụ kiện chính hãng từ Yonex, Victor, và nhiều thương hiệu khác."
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta property="og:url" content="https://vuavot.com/404" />
        <meta property="og:type" content="website" />

        {/* Zalo */}
        <meta property="zalo:title" content="Không tìm thấy trang | Vuavot" />
        <meta
          property="zalo:description"
          content="Trang bạn đang tìm không tồn tại trên Vuavot. Khám phá sản phẩm cầu lông và phụ kiện chính hãng từ các thương hiệu hàng đầu như Yonex, Victor."
        />
        <meta property="zalo:image" content="%PUBLIC_URL%/logo.png" />
        <meta property="zalo:url" content="https://vuavot.com/404" />
      </Helmet>

      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        404 - Không tìm thấy trang
      </h1>
      <p className="text-lg text-gray-600 mb-6">
        Rất tiếc! Trang bạn đang tìm không tồn tại.
      </p>
      <p className="text-lg text-gray-600 mb-8">
        Dù bạn đang tìm sản phẩm vợt tennis, cầu lông hay các phụ kiện khác,
        chúng tôi đều sẵn sàng phục vụ bạn!
      </p>
      <div className="space-x-4">
        <Link
          to="/"
          className="px-6 py-2 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600 transition duration-200"
        >
          Quay về Trang chủ
        </Link>
        <Link
          to="/"
          className="px-6 py-2 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600 transition duration-200"
        >
          Xem các sản phẩm
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
