import axios from "axios";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import AddressSelector from "../components/Common/AddressSelector";
import Breadcrumb from "../components/Common/Breadcrumb";
import Container from "../components/Common/Container";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { CartItem, CheckoutPayload, DetailSalesOrder } from "../shared/types";
import {
  formatVietnamDong,
  translateCharacteristicName,
} from "../shared/utils";
import useCartStore from "../store/cartStore";

const CheckoutPage = () => {
  const totalQuantity = useCartStore((state) => state.totalQuantity);
  const items = useCartStore((state) => state.items);
  const totalAmount = useCartStore((state) => state.totalAmount);

  const fullNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const noteRef = useRef<HTMLTextAreaElement>(null);
  const addressLineRef = useRef<HTMLInputElement>(null);

  const [address, setAddress] = useState<any>({
    province: "",
    district: "",
    ward: "",
    detail: "",
  });

  const addressCustomer = `${address.detail}, ${address.ward}, ${address.district}, ${address.province}`;

  const handleCheckout = async (event: React.FormEvent) => {
    event.preventDefault();

    const payload: CheckoutPayload = {
      saleOrgCode: "1003",
      salesEmployeeCode: "10000000",
      documentDate: new Date().toISOString().split("T")[0],
      requestDate: new Date().toISOString().split("T")[0],
      note1: "",
      paymentMethod: "PM1",
      distribution: "14",
      orderType: "ZOR0",
      customerName: "4eec0b3c-6dd8-49db-9e59-66ef04528f88",
      profileId: "4eec0b3c-6dd8-49db-9e59-66ef04528f88",
      phone: phoneRef.current?.value || "",
      email: emailRef.current?.value || "",
      addressCustomer: addressCustomer,
      address: addressCustomer,
      deliveryNote: noteRef.current?.value || "",
      quantity: totalQuantity,
      storageLocation: "CN2",
      storageLocationName: "Nguyên vật liệu 3 (QLVT)",
      detailSalesOrders: items.map(
        (item: CartItem): DetailSalesOrder => ({
          id: item.id,
          itemId: item.id,
          itemCode: item.productCode || "",
          erpProductCode: item.ERPProductCode || "",
          itemName: item.name,
          quantity: item.quantity,
          amount: item.price,
          currency: "VND",
          totalAmount: item.totalPrice,
          storageLocation: "CN2",
          storageLocationName: "Nguyên vật liệu 3 (QLVT)",
          active: true,
          unitPrice: item.price,
        })
      ),
    };

    try {
      const response = await axios.post(
        "https://mes-new-dev-trading.isdcorp.vn/api/v1/preorder/create",
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        alert("Đơn hàng đã được tạo thành công!");
      } else {
        throw new Error("Không thể tạo đơn hàng.");
      }
    } catch (error) {
      console.error("Lỗi:", error);
      alert("Không thể tạo đơn hàng. Vui lòng thử lại sau.");
    }
  };

  const breadcrumbItems = [
    { label: "Trang chủ", link: "/" },
    { label: "Thanh toán" },
  ];

  return (
    <>
      <Header />
      <Container className="mb-10 px-4 lg:px-0">
        <Breadcrumb items={breadcrumbItems} />
        <Helmet>
          <title>Thanh toán | Vuavot</title>
          <meta
            name="description"
            content="Hoàn tất đơn hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín cung cấp sản phẩm từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Nhanh chóng và dễ dàng, mua sắm ngay hôm nay!"
          />
          <meta property="og:title" content="Thanh toán | Vuavot" />
          <meta
            property="og:description"
            content="Hoàn tất đơn hàng của bạn tại Vua Vợt. Cửa hàng chuyên cung cấp các sản phẩm cầu lông chất lượng từ các thương hiệu hàng đầu. Đảm bảo trải nghiệm mua sắm nhanh chóng và tiện lợi."
          />
          <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
          <meta property="og:url" content="https://vuavot.com/thanh-toan" />
          <meta property="og:type" content="website" />
          <meta property="zalo:title" content="Thanh toán | Vuavot" />
          <meta
            property="zalo:description"
            content="Hoàn tất quá trình mua sắm tại Vua Vợt - nơi cung cấp sản phẩm cầu lông chính hãng từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Đảm bảo nhanh chóng và dễ dàng."
          />
          <meta property="zalo:image" content="%PUBLIC_URL%/logo.png" />
          <meta property="zalo:url" content="https://vuavot.com/thanh-toan" />
        </Helmet>
        <div className="flex flex-col-reverse lg:flex-row gap-6 mb-6">
          {/* Left Section - Shipping Info */}
          <div className="lg:w-2/3 w-full bg-white px-4 lg:px-6 shadow-md pb-6">
            <h2 className="text-2xl font-semibold mb-6">THÔNG TIN GIAO HÀNG</h2>
            <form onSubmit={handleCheckout} className="">
              <h2 className="text-gray-600 mb-2 font-medium">
                Thông tin chung
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <input
                  ref={fullNameRef}
                  type="text"
                  placeholder="Họ và tên"
                  className="border border-gray-300 px-3 py-2 rounded-md"
                />
                <input
                  ref={emailRef}
                  type="email"
                  placeholder="Email"
                  className="border border-gray-300 px-3 py-2 rounded-md col-start-1 md:col-auto"
                />
                <input
                  ref={phoneRef}
                  type="text"
                  placeholder="Số điện thoại"
                  className="border border-gray-300 px-3 py-2 rounded-md"
                />
              </div>

              <h2 className="text-gray-600 mb-2 font-medium">
                Địa chỉ nhận hàng
              </h2>
              {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10"> */}
              <AddressSelector onAddressChange={setAddress} />
              {/* </div> */}

              <div className="grid grid-cols-1 gap-4 mb-6">
                <textarea
                  ref={noteRef}
                  placeholder="Ghi chú"
                  rows={3}
                  className="border border-gray-300 px-3 py-2 rounded-md"
                />
              </div>

              <div className="flex justify-between gap-5 mt-6">
                <Link
                  to="/gio-hang"
                  className="flex items-center gap-1 hover:text-primary transition duration-300"
                >
                  <IoIosArrowRoundBack size={25} />
                  <span> QUAY LẠI GIỎ HÀNG</span>
                </Link>
                <button
                  type="submit"
                  className="bg-primary text-white px-6 py-2 rounded-md font-medium hover:bg-primary-dark transition duration-300"
                >
                  ĐẶT HÀNG
                </button>
              </div>
            </form>
          </div>

          {/* Right Section - Order Summary */}
          <div className="lg:w-1/3 w-full bg-gray-100 p-4 lg:p-6 shadow-md">
            <h2 className="text-lg font-semibold mb-4">
              ĐƠN HÀNG ({totalQuantity} sản phẩm)
            </h2>

            <ul className="space-y-4 mb-4">
              {items.map((item: CartItem) => (
                <li key={item.id} className="flex items-center space-x-4">
                  <img
                    src={item.img}
                    alt={item.name}
                    className="w-16 h-16 md:w-20 md:h-20 object-cover"
                  />
                  <div className="flex-1">
                    <p className="font-medium">{item.name}</p>
                    <p className="text-sm text-gray-600">{item.brand}</p>
                    <div className="flex gap-3">
                      {Object.entries(item.characteristic).map(
                        ([key, value]) => (
                          <div
                            key={key}
                            className="flex gap-1 text-sm text-gray-500"
                          >
                            <p>{translateCharacteristicName(key)}:</p>
                            <p>{value}</p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-sm">{formatVietnamDong(item.price)}</p>
                    <p className="text-xs text-gray-600">x {item.quantity}</p>
                    <p className="font-semibold">
                      {formatVietnamDong(item.price * item.quantity)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>

            <div className="flex items-center gap-3 mb-4">
              <input
                type="text"
                placeholder="Mã giảm giá"
                className="border px-3 py-2 rounded-md flex-grow"
              />
              <button className="ml-2 px-5 py-2 bg-red-500 text-white rounded-md hover:brightness-110 transition duration-300">
                SỬ DỤNG
              </button>
            </div>

            <div className="border-t pt-4">
              <div className="flex justify-between font-semibold text-lg">
                <span>TỔNG CỘNG:</span>
                <span>{formatVietnamDong(totalAmount)}</span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default CheckoutPage;

// import axios from "axios";
// import { useRef, useState } from "react";
// import { Helmet } from "react-helmet-async";
// import { IoIosArrowRoundBack } from "react-icons/io";
// import { Link } from "react-router-dom";
// import AddressSelector from "../components/Common/AddressSelector";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import { CartItem, CheckoutPayload, DetailSalesOrder } from "../shared/types";
// import {
//   formatVietnamDong,
//   translateCharacteristicName,
// } from "../shared/utils";
// import useCartStore from "../store/cartStore";

// const CheckoutPage = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const items = useCartStore((state) => state.items);
//   const totalAmount = useCartStore((state) => state.totalAmount);

//   const fullNameRef = useRef<HTMLInputElement>(null);
//   const emailRef = useRef<HTMLInputElement>(null);
//   const phoneRef = useRef<HTMLInputElement>(null);
//   const noteRef = useRef<HTMLTextAreaElement>(null);
//   const addressLineRef = useRef<HTMLInputElement>(null);

//   const [address, setAddress] = useState<any>({
//     province: "",
//     district: "",
//     ward: "",
//     detail: "",
//   });

//   const addressCustomer = `${address.detail}, ${address.ward}, ${address.district}, ${address.province}`;

//   const handleCheckout = async (event: React.FormEvent) => {
//     event.preventDefault();
//     // fullNameRef.current?.value
//     // Tạo payload từ dữ liệu người dùng nhập

//     // @ts-ignore
//     const payload: CheckoutPayload = {
//       saleOrgCode: "1003",
//       salesEmployeeCode: "10000000",
//       documentDate: new Date().toISOString().split("T")[0],
//       requestDate: new Date().toISOString().split("T")[0],
//       note1: "",
//       paymentMethod: "PM1",
//       distribution: "14",
//       orderType: "ZOR0",
//       customerName: "4eec0b3c-6dd8-49db-9e59-66ef04528f88",
//       profileId: "4eec0b3c-6dd8-49db-9e59-66ef04528f88",
//       phone: phoneRef.current?.value || "",
//       email: phoneRef.current?.value || "",
//       addressCustomer: addressCustomer,
//       address: addressCustomer,
//       deliveryNote: noteRef.current?.value || "",
//       quantity: totalQuantity,
//       storageLocation: "CN2",
//       storageLocationName: "Nguyên vật liệu 3 (QLVT)",
//       detailSalesOrders: items.map(
//         (item: CartItem): DetailSalesOrder => ({
//           id: item.id,
//           itemId: item.id,
//           itemCode: item.productCode || "",
//           erpProductCode: item.ERPProductCode || "",
//           itemName: item.name,
//           quantity: item.quantity,
//           amount: item.price,
//           currency: "VND",
//           totalAmount: item.totalPrice,
//           storageLocation: "CN2",
//           storageLocationName: "Nguyên vật liệu 3 (QLVT)",
//           active: true,
//           unitPrice: item.price,
//         })
//       ),
//     };

//     try {
//       // Gửi request bằng Axios
//       const response = await axios.post(
//         "https://mes-new-dev-trading.isdcorp.vn/api/v1/preorder/create",
//         payload,
//         {
//           headers: { "Content-Type": "application/json" },
//         }
//       );
//       if (response.status === 200) {
//         alert("Đơn hàng đã được tạo thành công!");
//       } else {
//         throw new Error("Không thể tạo đơn hàng.");
//       }
//     } catch (error) {
//       console.error("Lỗi:", error);
//       alert("Không thể tạo đơn hàng. Vui lòng thử lại sau.");
//     }
//   };

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: "Thanh toán" },
//   ];

//   return (
//     <>
//       <Header />
//       <Container className="mb-10">
//         <Breadcrumb items={breadcrumbItems} />
//         <Helmet>
//           {/* Title and description for SEO */}
//           <title>Thanh toán | Vuavot</title>
//           <meta
//             name="description"
//             content="Hoàn tất đơn hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín cung cấp sản phẩm từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Nhanh chóng và dễ dàng, mua sắm ngay hôm nay!"
//           />

//           {/* Open Graph / Facebook */}
//           <meta property="og:title" content="Thanh toán | Vuavot" />
//           <meta
//             property="og:description"
//             content="Hoàn tất đơn hàng của bạn tại Vua Vợt. Cửa hàng chuyên cung cấp các sản phẩm cầu lông chất lượng từ các thương hiệu hàng đầu. Đảm bảo trải nghiệm mua sắm nhanh chóng và tiện lợi."
//           />
//           <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
//           <meta property="og:url" content="https://vuavot.com/thanh-toan" />
//           <meta property="og:type" content="website" />

//           {/* Zalo */}
//           <meta property="zalo:title" content="Thanh toán | Vuavot" />
//           <meta
//             property="zalo:description"
//             content="Hoàn tất quá trình mua sắm tại Vua Vợt - nơi cung cấp sản phẩm cầu lông chính hãng từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Đảm bảo nhanh chóng và dễ dàng."
//           />
//           <meta property="zalo:image" content="%PUBLIC_URL%/logo.png" />
//           <meta property="zalo:url" content="https://vuavot.com/thanh-toan" />
//         </Helmet>
//         <div className="flex flex-col lg:flex-row gap-6 mb-6">
//           {/* Left Section - Shipping Info */}
//           <div className="lg:w-2/3 w-full bg-white px-6 shadow-md pb-6">
//             <h2 className="text-2xl font-semibold mb-6">THÔNG TIN GIAO HÀNG</h2>

//             <form onSubmit={handleCheckout} className="">
//               <h2 className="text-gray-600 mb-2 font-medium">
//                 Thông tin chung
//               </h2>
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
//                 <input
//                   ref={fullNameRef}
//                   type="text"
//                   placeholder="Họ và tên"
//                   className="border border-gray-300 px-3 py-2 rounded-md"
//                 />
//                 <input
//                   ref={emailRef}
//                   type="email"
//                   placeholder="Email"
//                   className="border border-gray-300 px-3 py-2 rounded-md col-start-1"
//                 />
//                 <input
//                   ref={phoneRef}
//                   type="text"
//                   placeholder="Số điện thoại"
//                   className="border border-gray-300 px-3 py-2 rounded-md"
//                 />
//               </div>

//               <h2 className="text-gray-600 mb-2 font-medium">
//                 Địa chỉ nhận hàng
//               </h2>

//               <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
//                 <AddressSelector onAddressChange={setAddress} />
//               </div>

//               <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                 <textarea
//                   ref={noteRef}
//                   placeholder="Ghi chú"
//                   rows={3}
//                   className="border border-gray-300 px-3 py-2 rounded-md col-span-2"
//                 />
//               </div>

//               {/* <p className="mt-4 font-medium">Phương thức vận chuyển</p>
//               <div className="space-y-2">
//                 <label className="flex items-center space-x-2">
//                   <input type="radio" name="delivery" className="form-radio" />
//                   <span>Giao hàng tận nơi</span>
//                 </label>
//                 <div className="grid grid-cols-3 gap-2">
//                   <select className="border px-3 py-2 rounded-md">
//                     <option>Chọn tỉnh/thành</option>
//                   </select>
//                   <select className="border px-3 py-2 rounded-md">
//                     <option>Chọn quận/huyện</option>
//                   </select>
//                   <select className="border px-3 py-2 rounded-md">
//                     <option>Chọn phường/xã</option>
//                   </select>
//                 </div>
//                 <label className="flex items-center space-x-2">
//                   <input type="radio" name="delivery" className="form-radio" />
//                   <span>Nhận hàng tại cửa hàng</span>
//                 </label>
//               </div> */}

//               <div className="flex justify-end gap-5 mt-6">
//                 <Link
//                   to="/gio-hang"
//                   className="flex items-center gap-1 hover:text-primary transition duration-300"
//                 >
//                   <IoIosArrowRoundBack size={25} />
//                   <span> QUAY LẠI GIỎ HÀNG</span>
//                 </Link>
//                 <button
//                   type="submit"
//                   className="bg-primary text-white px-6 py-2 rounded-md font-medium hover:bg-primary-dark transition duration-300"
//                 >
//                   ĐẶT HÀNG
//                 </button>
//               </div>
//             </form>
//           </div>

//           {/* Right Section - Order Summary */}
//           <div className="lg:w-1/3 w-full bg-gray-100 p-6 shadow-md">
//             <h2 className="text-lg font-semibold mb-4">
//               ĐƠN HÀNG ({totalQuantity} sản phẩm)
//             </h2>

//             <ul className="space-y-4 mb-4">
//               {items.map((item: CartItem) => (
//                 <li key={item.id} className="flex items-center space-x-4">
//                   <img
//                     src={item.img}
//                     alt={item.name}
//                     className="w-20 h-w-20 object-cover"
//                   />
//                   <div className="flex-1">
//                     <p className="font-medium">{item.name}</p>
//                     <p className="text-sm text-gray-600">{item.brand}</p>
//                     <div className="flex gap-3">
//                       {Object.entries(item.characteristic).map(
//                         ([key, value]) => (
//                           <div
//                             key={key}
//                             className="flex gap-1 text-sm text-gray-500"
//                           >
//                             <p>{translateCharacteristicName(key)}:</p>
//                             <p>{value}</p>
//                           </div>
//                         )
//                       )}
//                     </div>
//                   </div>
//                   <div className="text-right">
//                     <p className="text-sm">{formatVietnamDong(item.price)}</p>
//                     <p className="text-xs text-gray-600">x {item.quantity}</p>
//                     <p className="font-semibold">
//                       {formatVietnamDong(item.price * item.quantity)}
//                     </p>
//                   </div>
//                 </li>
//               ))}
//             </ul>

//             <div className="flex items-center gap-3 mb-4">
//               <input
//                 type="text"
//                 placeholder="Mã giảm giá"
//                 className="border px-3 py-2 rounded-md flex-grow"
//               />
//               <button className="ml-2 px-5 py-2 bg-red-500 text-white rounded-md hover:brightness-110 transition duration-300">
//                 SỬ DỤNG
//               </button>
//             </div>

//             <div className="border-t pt-4">
//               <div className="flex justify-between font-semibold text-lg">
//                 <span>TỔNG CỘNG:</span>
//                 <span>{formatVietnamDong(totalAmount)}</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Container>
//       <Footer />
//     </>
//   );
// };

// export default CheckoutPage;

// import { useRef } from "react";
// import CartDrawerItem from "../components/Common/CartDrawerItem";
// import useCartStore from "../store/cartStore";
// import { formatVietnamDong } from "../shared/utils";
// import { Link } from "react-router-dom";
// import logo from "../assets/logo.png";
// import Container from "../components/Common/Container";
// import axios from "axios";
// import { CartItem, CheckoutPayload, DetailSalesOrder } from "../shared/types";
// import { Helmet } from "react-helmet-async";

// const CheckoutPage = () => {
//   const totalQuantity = useCartStore((state) => state.totalQuantity);
//   const items = useCartStore((state) => state.items);
//   const totalAmount = useCartStore((state) => state.totalAmount);

//   const fullNameRef = useRef<HTMLInputElement>(null);
//   const phoneRef = useRef<HTMLInputElement>(null);
//   const addressLineRef = useRef<HTMLInputElement>(null);
//   const noteRef = useRef<HTMLTextAreaElement>(null);

//   const handleCheckout = async (event: React.FormEvent) => {
//     event.preventDefault();
//     // fullNameRef.current?.value
//     // Tạo payload từ dữ liệu người dùng nhập
//     const payload: CheckoutPayload = {
//       saleOrgCode: "1003",
//       salesEmployeeCode: "10000000",
//       documentDate: new Date().toISOString().split("T")[0],
//       requestDate: new Date().toISOString().split("T")[0],
//       note1: noteRef.current?.value || "",
//       paymentMethod: "PM1",
//       distribution: "14",
//       orderType: "ZOR0",
//       customerName: "4eec0b3c-6dd8-49db-9e59-66ef04528f88",
//       profileId: "4eec0b3c-6dd8-49db-9e59-66ef04528f88",
//       phone: phoneRef.current?.value || "",
//       email: "nhut.px@isdcorp.vn",
//       addressCustomer: addressLineRef.current?.value || "",
//       address: addressLineRef.current?.value || "",
//       deliveryNote: noteRef.current?.value || "",
//       quantity: totalQuantity,
//       storageLocation: "CN2",
//       storageLocationName: "Nguyên vật liệu 3 (QLVT)",
//       detailSalesOrders: items.map(
//         (item: CartItem): DetailSalesOrder => ({
//           id: item.id,
//           itemId: item.id,
//           itemCode: item.productCode || "",
//           erpProductCode: item.ERPProductCode || "",
//           itemName: item.name,
//           quantity: item.quantity,
//           amount: item.price,
//           currency: "VND",
//           totalAmount: item.totalPrice,
//           storageLocation: "CN2",
//           storageLocationName: "Nguyên vật liệu 3 (QLVT)",
//           active: true,
//           unitPrice: item.price,
//         })
//       ),
//     };

//     console.warn(payload);

//     try {
//       // Gửi request bằng Axios
//       const response = await axios.post(
//         "https://mes-new-dev-trading.isdcorp.vn/api/v1/preorder/create",
//         payload,
//         {
//           headers: { "Content-Type": "application/json" },
//         }
//       );
//       if (response.status === 200) {
//         alert("Đơn hàng đã được tạo thành công!");
//       } else {
//         throw new Error("Không thể tạo đơn hàng.");
//       }
//     } catch (error) {
//       console.error("Lỗi:", error);
//       alert("Không thể tạo đơn hàng. Vui lòng thử lại sau.");
//     }
//   };

//   return (
//     <Container>
//       <Helmet>
//         {/* Title and description for SEO */}
//         <title>Thanh toán | Vuavot</title>
//         <meta
//           name="description"
//           content="Hoàn tất đơn hàng của bạn tại Vua Vợt - Cửa hàng cầu lông uy tín cung cấp sản phẩm từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Nhanh chóng và dễ dàng, mua sắm ngay hôm nay!"
//         />

//         {/* Open Graph / Facebook */}
//         <meta property="og:title" content="Thanh toán | Vuavot" />
//         <meta
//           property="og:description"
//           content="Hoàn tất đơn hàng của bạn tại Vua Vợt. Cửa hàng chuyên cung cấp các sản phẩm cầu lông chất lượng từ các thương hiệu hàng đầu. Đảm bảo trải nghiệm mua sắm nhanh chóng và tiện lợi."
//         />
//         <meta property="og:image" content="%PUBLIC_URL%/logo.png" />
//         <meta property="og:url" content="https://vuavot.com/thanh-toan" />
//         <meta property="og:type" content="website" />

//         {/* Zalo */}
//         <meta property="zalo:title" content="Thanh toán | Vuavot" />
//         <meta
//           property="zalo:description"
//           content="Hoàn tất quá trình mua sắm tại Vua Vợt - nơi cung cấp sản phẩm cầu lông chính hãng từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Đảm bảo nhanh chóng và dễ dàng."
//         />
//         <meta property="zalo:image" content="%PUBLIC_URL%/logo.png" />
//         <meta property="zalo:url" content="https://vuavot.com/thanh-toan" />
//       </Helmet>

//       <Link to="/" className="flex gap-1 items-center justify-center py-3">
//         <img src={logo} alt="logo" className="w-14" />
//         <h1 className="font-bold text-2xl -rotate-2">
//           <span className="text-primary"> Vua </span>Vợt
//         </h1>
//       </Link>

//       <div className="flex flex-col lg:flex-row lg:gap-6 mb-6 lg:mb-0">
//         <div className="lg:w-2/3 w-full bg-lightWhite3 px-32 py-6 shadow-sm">
//           <p className="font-semibold text-xl uppercase mb-4">
//             Thông tin nhận hàng
//           </p>

//           <form onSubmit={handleCheckout} className="flex flex-col gap-3">
//             <div className="flex flex-col gap-1">
//               <label className="">Họ và tên</label>
//               <input
//                 ref={fullNameRef}
//                 type="text"
//                 className="px-3 py-2 border-2 rounded-md"
//               />
//             </div>

//             <div className="flex flex-col gap-1">
//               <label className="">Số điện thoại</label>
//               <input
//                 ref={phoneRef}
//                 type="text"
//                 className="px-3 py-2 border-2 rounded-md"
//               />
//             </div>

//             <div className="flex flex-col gap-1">
//               <label className="">Địa chỉ</label>
//               <input
//                 ref={addressLineRef}
//                 type="text"
//                 className="px-3 py-2 border-2 rounded-md"
//               />
//             </div>

//             <div className="flex flex-col gap-1">
//               <label className="">Ghi chú</label>
//               <textarea
//                 ref={noteRef}
//                 className="px-3 py-2 border-2 rounded-md "
//               />
//             </div>

//             <button className=" mt-7 px-20 py-3 bg-primary hover-brigher-color rounded-lg text-white font-semibold uppercase">
//               Mua ngay
//             </button>
//           </form>
//         </div>
//         <aside className="lg:w-1/3 w-full px-4 py-6">
//           <p className="font-semibold text-xl uppercase mb-4">
//             Đơn hàng ({totalQuantity} sản phẩm)
//           </p>

//           <div className="">
//             <ul className="flex flex-col gap-6 ">
//               {items.map((product) => (
//                 <CartDrawerItem key={product.id} product={product} />
//               ))}
//             </ul>
//           </div>
//           <div className="flex justify-between mt-5">
//             <p className="text-lg">Tổng cộng:</p>
//             <p className="text-xl font-medium">
//               {formatVietnamDong(totalAmount)}
//             </p>
//           </div>
//           <div className="italic mt-3 flex flex-col gap-1">
//             <p>
//               - Giá trên chưa bao gồm phí vận chuyển. Phí vận chuyển sẽ được
//               nhân viên báo khi xác nhận đơn hàng.
//             </p>
//             <p>
//               - Thời gian xử lý đơn hàng: Từ 8h00- 17h thứ 2 đến thứ 7. Các đơn
//               hàng sau thời gian này sẽ được xử lý vào ngày làm việc tiếp theo.
//             </p>
//           </div>
//         </aside>
//       </div>
//     </Container>
//   );
// };

// export default CheckoutPage;
