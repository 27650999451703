import React, { useEffect, useState, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { Filter } from "../../../shared/types";

interface SortByProps {
  updateFilter: (newFilterValues: Partial<Filter>) => void;
}

export const sortOptions = [
  { label: "Phổ biến nhất", orderBy: null, orderByDescending: "popular" },
  {
    label: "Giảm giá nhiều nhất",
    orderBy: null,
    orderByDescending: "discount",
  },
  { label: "Giá thấp nhất", orderBy: "price", orderByDescending: null },
  { label: "Giá cao nhất", orderBy: null, orderByDescending: "price" },
];

const FilterSortBy: React.FC<SortByProps> = ({ updateFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const orderBy = searchParams.get("orderBy");
    const orderByDescending = searchParams.get("orderByDescending");

    const foundIndex = sortOptions.findIndex(
      (option) =>
        option.orderBy === orderBy &&
        option.orderByDescending === orderByDescending
    );

    setSelectedOption(foundIndex !== -1 ? foundIndex : null);
  }, [searchParams]);

  const handleSortChange = (
    orderBy: string | null,
    orderByDescending: string | null,
    index: number
  ) => {
    if (selectedOption === index) {
      setSelectedOption(null);
      updateFilter({ orderBy: null, orderByDescending: null });
    } else {
      setSelectedOption(index);
      updateFilter({ orderBy, orderByDescending, pageIndex: 1 });

      const params = new URLSearchParams(searchParams);
      if (orderBy) params.set("orderBy", orderBy);
      else params.delete("orderBy");

      if (orderByDescending) params.set("orderByDescending", orderByDescending);
      else params.delete("orderByDescending");

      setSearchParams(params);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none w-[250px]"
      >
        <span>Sắp xếp theo</span>
        <MdKeyboardArrowDown
          size={20}
          className={`transition-transform duration-200 ${
            isOpen ? "-rotate-90" : "rotate-0"
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white border rounded-md shadow-lg p-4 w-[250px]">
          <ul className="space-y-2">
            {sortOptions.map((option, index) => (
              <li key={index} className="flex items-center">
                <button
                  onClick={() =>
                    handleSortChange(
                      option.orderBy,
                      option.orderByDescending,
                      index
                    )
                  }
                  className="flex items-center gap-2 w-full text-left"
                >
                  <span
                    className={`w-5 h-5 flex items-center justify-center rounded-full border-2 ${
                      selectedOption === index
                        ? "bg-white border-primary"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedOption === index && (
                      <span className="w-3 h-3 bg-primary rounded-full"></span>
                    )}
                  </span>
                  <span
                    className={`${
                      selectedOption === index && "text-primary font-medium"
                    }`}
                  >
                    {option.label}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterSortBy;
