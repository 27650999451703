import React from "react";
import { Filter } from "../../shared/types";
import { priceRanges } from "./FilterType/FilterPrice";
import { sortOptions } from "./FilterType/FilterSortBy";
import { IoTrashBinOutline } from "react-icons/io5";
import {
  ACCESSORY_CATEGORY_MAPPING,
  BRAND_MAPPING,
} from "../../shared/constant";

interface ActiveFiltersProps {
  filters: {
    minPrice: number | null;
    maxPrice: number | null;
    BrandCodes: string | null;
    orderBy: string | null;
    orderByDescending: string | null;
    accessoryDetailCode: string | null;
  };
  updateFilter: (newFilterValues: Partial<Filter>) => void;
  clearAllFilters: () => void;
}

const ActiveFilters: React.FC<ActiveFiltersProps> = ({
  filters,
  updateFilter,
  clearAllFilters,
}) => {
  // Find the label for the active price range
  const activePriceRange = priceRanges.find(
    (range) =>
      range.minPrice === filters.minPrice && range.maxPrice === filters.maxPrice
  );

  // Find the label for the active sort option
  const activeSortOption = sortOptions.find(
    (option) =>
      option.orderBy === filters.orderBy &&
      option.orderByDescending === filters.orderByDescending
  );

  // Split BrandCodes into an array if it exists
  const brandCodesArray = filters.BrandCodes
    ? filters.BrandCodes.split(",")
    : [];

  const activeAccessory = filters.accessoryDetailCode
    ? ACCESSORY_CATEGORY_MAPPING[filters.accessoryDetailCode]
    : null;

  return (
    <div className="mb-8">
      {(activePriceRange ||
        brandCodesArray.length > 0 ||
        activeSortOption ||
        activeAccessory) && (
        <p className="text-lg font-medium">ĐANG LỌC THEO:</p>
      )}
      <div className="flex flex-wrap gap-2 mt-2">
        {/* Display Price Filter */}
        {activeAccessory && (
          <div className="bg-primary text-white px-4 py-2 rounded-full flex items-center">
            {activeAccessory}
            <button
              className="ml-2 text-white"
              onClick={() => updateFilter({ accessoryDetailCode: null })}
            >
              &times;
            </button>
          </div>
        )}

        {/* Display Brand Filters separately */}
        {brandCodesArray.map((brandCode) => (
          <div
            key={brandCode}
            className="bg-primary text-white px-4 py-2 rounded-full flex items-center"
          >
            {BRAND_MAPPING[brandCode] || brandCode} {/* Hiển thị tên đầy đủ */}
            <button
              className="ml-2 text-white"
              onClick={() => {
                // Remove the selected brand code from the array
                const updatedBrandCodes = brandCodesArray
                  .filter((code) => code !== brandCode)
                  .join(",");
                updateFilter({ BrandCodes: updatedBrandCodes || null });
              }}
            >
              &times;
            </button>
          </div>
        ))}

        {/* Display Price Filter */}
        {activePriceRange && (
          <div className="bg-primary text-white px-4 py-2 rounded-full flex items-center">
            {activePriceRange.label}
            <button
              className="ml-2 text-white"
              onClick={() => updateFilter({ minPrice: null, maxPrice: null })}
            >
              &times;
            </button>
          </div>
        )}

        {/* Display Sort Option */}
        {activeSortOption && (
          <div className="bg-primary text-white px-4 py-2 rounded-full flex items-center">
            {activeSortOption.label}
            <button
              className="ml-2 text-white"
              onClick={() =>
                updateFilter({ orderBy: null, orderByDescending: null })
              }
            >
              &times;
            </button>
          </div>
        )}

        {/* Clear All Filters button */}
        {(activePriceRange ||
          brandCodesArray.length > 0 ||
          activeSortOption ||
          activeAccessory) && (
          <button
            className="border-2 border-primary px-4 py-2 rounded-full flex gap-2 items-center"
            onClick={clearAllFilters}
          >
            <IoTrashBinOutline size={20} className="text-primary" />
            Xóa hết
          </button>
        )}
      </div>
    </div>
  );
};

export default ActiveFilters;

// // ActiveFilters.tsx
// import React from "react";
// import { Filter } from "../../shared/types";
// import { priceRanges } from "./FilterType/FilterPrice";
// import { sortOptions } from "./FilterType/FilterSortBy";

// interface ActiveFiltersProps {
//   filters: {
//     minPrice: number | null;
//     maxPrice: number | null;
//     BrandCodes: string | null;
//     orderBy: string | null;
//     orderByDescending: string | null;
//   };
//   updateFilter: (newFilterValues: Partial<Filter>) => void;
//   clearAllFilters: () => void;
// }

// const ActiveFilters: React.FC<ActiveFiltersProps> = ({
//   filters,
//   updateFilter,
//   clearAllFilters,
// }) => {
//   // Find the label for the active price range
//   const activePriceRange = priceRanges.find(
//     (range) =>
//       range.minPrice === filters.minPrice && range.maxPrice === filters.maxPrice
//   );

//   // Find the label for the active sort option
//   const activeSortOption = sortOptions.find(
//     (option) =>
//       option.orderBy === filters.orderBy &&
//       option.orderByDescending === filters.orderByDescending
//   );

//   return (
//     <div>
//       <p className="text-lg font-medium">ĐANG LỌC THEO:</p>
//       <div className="flex flex-wrap gap-2 mt-2">
//         {/* Display Price Filter */}
//         {activePriceRange && (
//           <div className="bg-primary text-white px-4 py-2 rounded-full flex items-center">
//             {activePriceRange.label}
//             <button
//               className="ml-2 text-white"
//               onClick={() => updateFilter({ minPrice: null, maxPrice: null })}
//             >
//               &times;
//             </button>
//           </div>
//         )}

//         {/* Display Brand Filter */}
//         {filters.BrandCodes && (
//           <div className="bg-primary text-white px-4 py-2 rounded-full flex items-center">
//             {filters.BrandCodes} {/* Replace with brand name if necessary */}
//             <button
//               className="ml-2 text-white"
//               onClick={() => updateFilter({ BrandCodes: null })}
//             >
//               &times;
//             </button>
//           </div>
//         )}

//         {/* Display Sort Option */}
//         {activeSortOption && (
//           <div className="bg-primary text-white px-4 py-2 rounded-full flex items-center">
//             {activeSortOption.label}
//             <button
//               className="ml-2 text-white"
//               onClick={() =>
//                 updateFilter({ orderBy: null, orderByDescending: null })
//               }
//             >
//               &times;
//             </button>
//           </div>
//         )}

//         {/* Clear All Filters button */}
//         {(activePriceRange || filters.BrandCodes || activeSortOption) && (
//           <button
//             className="bg-primary text-white px-4 py-2 rounded-full"
//             onClick={clearAllFilters}
//           >
//             Xóa hết
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ActiveFilters;
