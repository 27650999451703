const SubscriptionBanner = () => {
  return (
    <section className="bg-primary py-6 md:py-10 px-4 flex justify-center items-center">
      <div className="flex flex-col md:flex-row items-center max-w-6xl w-full space-y-4 md:space-y-0 md:space-x-4">
        {/* Left Text */}
        <div className="flex-1 text-white text-center md:text-left">
          <h3 className="text-lg md:text-xl font-bold">ĐĂNG KÝ NHẬN TIN</h3>
          <p className="text-sm md:text-base">
            ĐỂ NHẬN ƯU ĐÃI 10% CHO KHÁCH HÀNG MỚI
          </p>
        </div>

        {/* Subscription Form */}
        <form className="flex items-center bg-white rounded-full overflow-hidden w-full max-w-xs md:max-w-sm mx-auto md:mx-0 py-1 px-2">
          <input
            type="email"
            placeholder="Email"
            className="flex-1 py-2 px-4 text-gray-700 focus:outline-none text-sm md:text-base"
          />
          <button
            type="submit"
            className="bg-black text-white px-4 py-2 font-semibold text-sm md:text-base rounded-full whitespace-nowrap"
          >
            ĐĂNG KÝ
          </button>
        </form>
      </div>
    </section>
  );
};

export default SubscriptionBanner;
