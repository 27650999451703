import React from "react";
import racquet from "../../assets/racquet.png";
import clothes from "../../assets/clothes.png";
import shoes from "../../assets/shoes.png";
import items from "../../assets/items.png";
import { Link } from "react-router-dom";

const categories = [
  {
    src: racquet,
    label: "Vợt cầu lông",
    slug: "vot-cau-long",
    style: "row-span-2",
  },
  { src: clothes, label: "Quần áo cầu lông", slug: "quan-cau-long" },
  {
    src: items,
    label: "Phụ kiện cầu lông",
    slug: "phu-kien-cau-long",
    style: "row-span-2",
  },
  { src: shoes, label: "Giày cầu lông", slug: "giay-cau-long" },
];

const ShopByCategory: React.FC = () => {
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3  my-8">
      {categories.map((category, index) => (
        <Link
          to={`/san-pham/${category.slug}`}
          key={index}
          className={`relative ${category.style} bg-gray-100 overflow-hidden rounded-lg shadow-lg group`}
        >
          <img
            src={category.src}
            alt={category.label}
            className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
          />
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-white opacity-80 px-2 py-1 sm:px-3 sm:py-1 text-sm sm:text-base md:text-lg rounded text-slate-700 font-semibold shadow-md uppercase group-hover:scale-110 transition duration-300 whitespace-nowrap">
            {category.label}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ShopByCategory;

// import React from "react";
// import racquet from "../../assets/racquet.png";
// import clothes from "../../assets/clothes.png";
// import shoes from "../../assets/shoes.png";
// import items from "../../assets/items.png";
// import { Link } from "react-router-dom";
// import { getCategorySlugFromName } from "../../shared/utils";

// const categories = [
//   {
//     src: racquet,
//     label: "Vợt cầu lông",
//     slug: "vot-cau-long",
//     style: "row-span-2",
//   },
//   { src: clothes, label: "Quần áo cầu lông", slug: "quan-cau-long" },
//   {
//     src: items,
//     label: "Phụ kiện cầu lông",
//     slug: "phu-kien-cau-long",
//     style: "row-span-2",
//   },
//   { src: shoes, label: "Giày cầu lông", slug: "giay-cau-long" },
// ];

// const ShopByCategory: React.FC = () => {
//   return (
//     <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 my-8">
//       {categories.map((category, index) => (
//         <Link
//           to={`/san-pham/${category.slug}`}
//           key={index}
//           className={`relative ${category.style} bg-gray-100  overflow-hidden rounded-lg shadow-lg group`}
//         >
//           <img
//             src={category.src}
//             alt={category.label}
//             className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
//           />
//           <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-white opacity-80 px-3 py-1 text-lg rounded text-slate-700 font-semibold shadow-md uppercase group-hover:scale-110 transition duration-300">
//             {category.label}
//           </div>
//         </Link>
//       ))}
//     </div>
//   );
// };

// export default ShopByCategory;

// import React from "react";
// import { useQuery } from "@tanstack/react-query";
// import { fetchCategories } from "../../services/home";
// import { Link } from "react-router-dom";
// import {
//   getCategorySlugFromName,
//   removeDichVuCategory,
// } from "../../shared/utils";
// import Container from "../Common/Container";
// import { CATEGORIES } from "../../shared/constant";

// const ShopByCategory: React.FC = () => {
//   const {
//     isPending: isLoadingCategory,
//     error: errorCategory,
//     data: dataCategory,
//   } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//     staleTime: 1000 * 60 * 5,
//   });

//   if (isLoadingCategory) {
//     return <div>Loading categories...</div>;
//   }

//   if (errorCategory) {
//     return <div>Error fetching categories</div>;
//   }

//   return (
//     <section className="bg-lightWhite3">
//       <Container>
//         <p className="text-primary font-semibold uppercase tracking-widest text-center">
//           Vuavot
//         </p>
//         <h3 className="font-semibold text-[30px] mb-5 leading-none text-center">
//           Các loại sản phẩm
//         </h3>
//         <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
//           {dataCategory?.data.filter(removeDichVuCategory).map((category) => (
//             <Link
//               to={`/san-pham/${getCategorySlugFromName(category.categoryName)}`}
//               key={category.categoryId}
//               className="relative group overflow-hidden rounded-lg shadow-lg"
//             >
//               {/* Background Image */}
//               <img
//                 src={
//                   CATEGORIES.find(
//                     (c) => c.CategoryCode.toString() === category.categoryCode
//                   )?.CategoryImg ||
//                   "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//                 }
//                 alt={category.categoryName}
//                 className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
//               />

//               {/* Dark Overlay */}
//               <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 group-hover:bg-opacity-20"></div>

//               {/* Category Name */}
//               <div className="absolute inset-0 flex items-center justify-center text-white font-semibold text-xl uppercase transition-transform duration-300 transform group-hover:scale-110">
//                 {category.categoryName}
//               </div>
//             </Link>
//           ))}
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default ShopByCategory;

// import React from "react";
// import { useQuery } from "@tanstack/react-query";
// import { fetchCategories } from "../../services/home";
// import { Link } from "react-router-dom";
// import {
//   getCategorySlugFromName,
//   removeDichVuCategory,
// } from "../../shared/utils";
// import Container from "../Common/Container";
// import { Category } from "../../shared/types";
// import { CATEGORIES } from "../../shared/constant";

// const ShopByCategory: React.FC = () => {
//   const {
//     isPending: isLoadingCategory,
//     error: errorCategory,
//     data: dataCategory,
//   } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//     staleTime: 1000 * 60 * 5,
//   });

//   if (isLoadingCategory) {
//     return <div>Loading categories...</div>;
//   }

//   if (errorCategory) {
//     return <div>Error fetching categories</div>;
//   }

//   return (
//     <section className="bg-lightWhite3">
//       <Container>
//         {/* <h2 className="text-2xl font-bold text-center my-6">
//           Các loại sản phẩm
//         </h2> */}
//         <p className="text-primary font-semibold uppercase tracking-widest text-center">
//           Vuavot
//         </p>
//         <h3 className="font-semibold text-[30px]  mb-5 leading-none text-center">
//           Các loại sản phẩm
//         </h3>
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
//           {dataCategory?.data.filter(removeDichVuCategory).map((category) => (
//             <Link
//               to={`/san-pham/${getCategorySlugFromName(category.categoryName)}`}
//               key={category.categoryId}
//               className="relative group overflow-hidden rounded-lg shadow-lg"
//             >
//               {/* Background Image */}
//               {/* category.imageUrl || */}
//               <img
//                 src={
//                   CATEGORIES.find(
//                     (c) => c.CategoryCode.toString() === category.categoryCode
//                   )?.CategoryImg ||
//                   "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
//                 }
//                 alt={category.categoryName}
//                 className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
//               />

//               {/* Dark Overlay */}
//               <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 group-hover:bg-opacity-20"></div>

//               {/* Category Name */}
//               <div className="absolute inset-0 flex items-center justify-center text-white font-semibold text-xl uppercase transition-transform duration-300 transform group-hover:scale-110 ">
//                 {category.categoryName}
//               </div>
//             </Link>
//           ))}
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default ShopByCategory;
