import React, { useState, useEffect } from "react";

interface CountdownTimerProps {
  initialCountdown: number; // Initial time in seconds
  onReset?: () => void; // Optional callback when the timer resets
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialCountdown,
  onReset,
}) => {
  const [countdown, setCountdown] = useState(initialCountdown);

  // Update the countdown every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 0) {
          if (onReset) onReset();
          return initialCountdown;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialCountdown, onReset]);

  // Helper function to format countdown time into days, hours, minutes, and seconds
  const formatTime = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;

    return { days, hours, minutes, secs };
  };

  const { days, hours, minutes, secs } = formatTime(countdown);

  return (
    <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 text-center leading-none">
      {/* <div className="bg-black text-white px-2 py-1 sm:px-3 sm:py-2 rounded min-w-[50px]">
        <span className="block text-base sm:text-xl font-bold">
          {days.toString().padStart(2, "0")}
        </span>
        <span className="text-[10px] sm:text-xs">Ngày</span>
      </div> */}
      <div className="bg-black text-white px-2 py-1 sm:px-3 sm:py-2 rounded min-w-[50px]">
        <span className="block text-base sm:text-xl font-bold">
          {hours.toString().padStart(2, "0")}
        </span>
        <span className="text-[10px] sm:text-xs">Giờ</span>
      </div>
      <div className="bg-black text-white px-2 py-1 sm:px-3 sm:py-2 rounded min-w-[50px]">
        <span className="block text-base sm:text-xl font-bold">
          {minutes.toString().padStart(2, "0")}
        </span>
        <span className="text-[10px] sm:text-xs">Phút</span>
      </div>
      <div className="bg-black text-white px-2 py-1 sm:px-3 sm:py-2 rounded min-w-[50px]">
        <span className="block text-base sm:text-xl font-bold">
          {secs.toString().padStart(2, "0")}
        </span>
        <span className="text-[10px] sm:text-xs">Giây</span>
      </div>
    </div>
  );
};

export default CountdownTimer;

// // CountdownTimer.tsx
// import React, { useState, useEffect } from "react";

// interface CountdownTimerProps {
//   initialCountdown: number; // Initial time in seconds
//   onReset?: () => void; // Optional callback when the timer resets
// }

// const CountdownTimer: React.FC<CountdownTimerProps> = ({
//   initialCountdown,
//   onReset,
// }) => {
//   const [countdown, setCountdown] = useState(initialCountdown);

//   // Update the countdown every second
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCountdown((prevCountdown) => {
//         if (prevCountdown <= 0) {
//           if (onReset) onReset();
//           return initialCountdown;
//         }
//         return prevCountdown - 1;
//       });
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [initialCountdown, onReset]);

//   // Helper function to format countdown time into days, hours, minutes, and seconds
//   const formatTime = (seconds: number) => {
//     const days = Math.floor(seconds / (24 * 60 * 60));
//     const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
//     const minutes = Math.floor((seconds % (60 * 60)) / 60);
//     const secs = seconds % 60;

//     return { days, hours, minutes, secs };
//   };

//   const { days, hours, minutes, secs } = formatTime(countdown);

//   return (
//     <div className="flex space-x-4 text-center leading-none">
//       <div className="bg-black text-white px-4 py-2 rounded">
//         <span className="block text-xl font-bold">
//           {days.toString().padStart(2, "0")}
//         </span>
//         <span className="text-xs">Ngày</span>
//       </div>
//       <div className="bg-black text-white px-4 py-2 rounded">
//         <span className="block text-xl font-bold">
//           {hours.toString().padStart(2, "0")}
//         </span>
//         <span className="text-xs">Giờ</span>
//       </div>
//       <div className="bg-black text-white px-4 py-2 rounded">
//         <span className="block text-xl font-bold">
//           {minutes.toString().padStart(2, "0")}
//         </span>
//         <span className="text-xs">Phút</span>
//       </div>
//       <div className="bg-black text-white px-4 py-2 rounded">
//         <span className="block text-xl font-bold">
//           {secs.toString().padStart(2, "0")}
//         </span>
//         <span className="text-xs">Giây</span>
//       </div>
//     </div>
//   );
// };

// export default CountdownTimer;
