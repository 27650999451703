import React from "react";
import blogImg1 from "../../assets/blogImg1.png";
import blogImg2 from "../../assets/blogImg2.png";
import blogImg3 from "../../assets/blogImg3.png";
import { Link } from "react-router-dom";

const NewsEventSection = () => {
  const blogPosts = [
    {
      image: blogImg1,
      title: "Top 5 cây vợt cầu lông Redson cao cấp tốt nhất",
      description:
        "Redson là một thương hiệu nổi tiếng trong lĩnh vực cầu lông, được biết đến với những sản phẩm chất lượng cao, phù hợp...",
      link: "Xem chi tiết",
    },
    {
      image: blogImg2,
      title: "Top 5 cây vợt cầu lông Redson cao cấp tốt nhất",
      description:
        "Redson là một thương hiệu nổi tiếng trong lĩnh vực cầu lông, được biết đến với những sản phẩm chất lượng cao, phù hợp...",
      link: "Xem chi tiết",
    },
    {
      image: blogImg3,
      title: "Top 5 cây vợt cầu lông Redson cao cấp tốt nhất",
      description:
        "Redson là một thương hiệu nổi tiếng trong lĩnh vực cầu lông, được biết đến với những sản phẩm chất lượng cao, phù hợp...",
      link: "Xem chi tiết",
    },
  ];

  return (
    <section className="py-8">
      <h2 className="text-2xl font-extrabold mb-4">TIN TỨC - SỰ KIỆN</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Main Featured Blog */}
        <Link to="/" className="relative group md:row-span-2">
          <img
            src={blogPosts[0].image}
            alt={blogPosts[0].title}
            className="w-full object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 p-4 flex flex-col justify-end rounded-lg">
            <h3 className="text-lg font-semibold text-white">
              {blogPosts[0].title}
            </h3>
            <p className="text-white mb-2">{blogPosts[0].description}</p>
            {/* <a href="/" className="text-primary font-semibold">
              {blogPosts[0].link} &rarr;
            </a> */}
          </div>
        </Link>

        {/* Additional Blogs */}
        {/* <div className="flex flex-col justify-between space-y-4"> */}
        {blogPosts.slice(1).map((post, index) => (
          <Link to="/" key={index} className="flex items-start space-x-4">
            <img
              src={post.image}
              alt={post.title}
              className="w-1/3 h-full object-cover rounded-lg"
            />
            <div>
              <h3 className="text-lg font-semibold">{post.title}</h3>
              <p className=" text-gray-600">{post.description}</p>
              {/* <a href="/" className="text-primary font-semibold">
                {post.link} &rarr;
              </a> */}
            </div>
          </Link>
        ))}
        {/* </div> */}
      </div>
    </section>
  );
};

export default NewsEventSection;
