import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { fetchAllProducts } from "../../services/home";
import { formatVietnamDong, getCategorySlugFromName } from "../../shared/utils";
import { Product } from "../../shared/types";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  // Setup filters for the API request
  const [filters, setFilters] = useState({
    SearchQuery: "",
    pageIndex: 1,
  });

  useEffect(() => {
    const debouncedSetFilters = debounce(() => {
      if (query.trim() && !submitted) {
        setFilters({
          SearchQuery: query,
          pageIndex: 1,
        });
        setShowSuggestions(true);
      }
    }, 300);

    debouncedSetFilters();

    return () => debouncedSetFilters.cancel();
  }, [query, submitted]);

  // Fetch search suggestions based on filters
  const { data: dataAllProduct } = useQuery({
    queryKey: ["searchProducts", filters],
    queryFn: () => fetchAllProducts("all", filters),
    enabled: !!filters.SearchQuery,
  });

  const handleInputChange = (e: any) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    setSubmitted(false);
    if (!searchQuery.trim()) {
      setShowSuggestions(false);
    }
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    if (query.trim()) {
      setShowSuggestions(false);
      setSubmitted(true);
      navigate(`/tim-kiem?q=${encodeURIComponent(query)}`);
    }
  };

  const handleProductClick = (product: Product) => {
    navigate(
      `/san-pham/${getCategorySlugFromName(product.categoryName)}/${
        product.productId
      }`
    );
  };

  return (
    <div className="md:block relative">
      <form onSubmit={handleSearchSubmit} className="flex">
        <div
          className={`relative flex-1 border border-gray-300 rounded-l-md ${
            showSuggestions && "border-b-0 rounded-b-none"
          }`}
        >
          <input
            type="text"
            placeholder="Tìm kiếm sản phẩm ở đây"
            className="py-3 px-4 w-full rounded-md"
            value={query}
            onChange={handleInputChange}
            onFocus={() => query.trim() && setShowSuggestions(true)}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
          />

          {/* Suggestions Dropdown - Overlaying the Input */}
          {showSuggestions &&
            dataAllProduct &&
            dataAllProduct?.data?.length > 0 && (
              <div className="absolute top-[90%] -left-[1px] -right-[1px] bg-white border border-gray-300 border-t-0 rounded-b-md shadow-lg mt-1 z-20 ">
                {dataAllProduct.data.slice(0, 4).map((product) => (
                  <div
                    key={product.productId}
                    className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                    onMouseDown={() => handleProductClick(product)}
                  >
                    <img
                      src={
                        product.imageUrl ||
                        "https://www.bandg.com/assets/img/default-product-img.png"
                      }
                      alt={product.productName}
                      className="w-20 h-20 rounded-md mr-3"
                    />
                    <div>
                      <p className="font-medium ">{product.productName}</p>
                      <p className="italic text-gray-500 text-sm mb-[3px]">
                        {product.brand}
                      </p>
                      <div className="flex gap-2">
                        <p className="text-sm text-primary">
                          {formatVietnamDong(product.price)}
                        </p>
                        <p className="text-sm line-through text-gray-400">
                          {formatVietnamDong(product.manufacturerPrice)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {(dataAllProduct?.paging?.totalCount || 0) > 5 && (
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={() => {
                        navigate(`/tim-kiem?q=${encodeURIComponent(query)}`);
                      }}
                      className=" hover:underline"
                    >
                      Xem toàn bộ {dataAllProduct?.paging?.totalCount} kết quả
                    </button>
                  </div>
                )} */}
              </div>
            )}
        </div>

        <button type="submit" className="bg-black rounded-r-md px-5 py-3">
          <FiSearch size={28} className="text-white" />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
