import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { fetchBrands, fetchCategories } from "../../services/home";
import { CATEGORIES } from "../../shared/constant";
import { Category } from "../../shared/types";
import {
  getCategorySlugFromName,
  removeDichVuCategory,
} from "../../shared/utils";
import SearchBar from "../Home/SearchBar";

const HeroWithOverlay = () => {
  const { error: errorCategory, data: dataCategory } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });
  const {
    isPending: isLoadingBrand,
    error: errorBrand,
    data: dataBrand,
  } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
    staleTime: 1000 * 60 * 5,
  });

  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);
  const expandedMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuHeight, setMenuHeight] = useState<number | null>(null);

  // Set the height of the expanded menu
  useEffect(() => {
    if (isExpanded && expandedMenuRef.current) {
      setMenuHeight(expandedMenuRef.current.offsetHeight);
    }
  }, [isExpanded]);

  // Function to handle navigation with brand filter
  const handleChildCategoryClick = (
    parentCategory: Category,
    childCategory: Category
  ) => {
    const parentCategorySlug = getCategorySlugFromName(
      parentCategory.categoryName
    );

    if (parentCategory.categoryName === "Phụ Kiện Cầu Lông") {
      navigate(
        `/san-pham/${parentCategorySlug}?accessoryDetailCode=${childCategory.categoryCode}`
      );
    } else {
      const brandName = childCategory.categoryName.split(" ").pop();
      const brand = dataBrand?.data.find(
        (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
      );

      navigate(
        `/san-pham/${parentCategorySlug}?brandCodes=${brand?.brandCode}`
      );
    }

    setIsExpanded(false);
    setHoveredCategory(null);
  };

  return (
    <div className="hidden md:flex gap-8 mt-4 relative shadow-sm ">
      {/* Left Navigation with Categories */}
      <div
        className="w-1/4 relative z-50"
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => {
          setIsExpanded(false);
          setHoveredCategory(null);
        }}
      >
        <div
          className={`bg-black text-white flex justify-between items-center px-6 py-[14px] cursor-pointer ${
            isExpanded ? "rounded-t-lg" : "rounded-lg"
          }`}
        >
          <div className="flex gap-3">
            <RxHamburgerMenu size={24} />
            <p className="font-medium">DANH MỤC SẢN PHẨM</p>
          </div>
          {isExpanded ? (
            <MdOutlineKeyboardArrowRight size={24} />
          ) : (
            <MdOutlineKeyboardArrowDown size={24} />
          )}
        </div>

        {/* Expanded Category Menu */}
        {isExpanded && (
          <div
            ref={expandedMenuRef}
            className="absolute top-full left-0 w-full bg-white shadow-lg border border-gray-200"
          >
            {dataCategory?.data.filter(removeDichVuCategory).map((category) => (
              <button
                // to={`/san-pham/${getCategorySlugFromName(
                //   category.categoryName
                // )}?orderByDescending=popular`}
                onClick={() => {
                  setIsExpanded(false);
                  setHoveredCategory(null);

                  navigate(
                    `/san-pham/${getCategorySlugFromName(
                      category.categoryName
                    )}?orderByDescending=popular`
                  );
                }}
                key={category.categoryId}
                className={`w-full flex justify-between items-center py-3 pl-6 pr-3 border-b-[1px] border-gray-200 ${
                  hoveredCategory?.categoryId === category.categoryId &&
                  "font-semibold"
                } transition duration-300`}
                onMouseEnter={() => setHoveredCategory(category)}
              >
                <p className="hover:text-primary transition duration-300">
                  {category.categoryName}
                </p>
                <MdOutlineKeyboardArrowRight
                  size={24}
                  className={`${
                    hoveredCategory?.categoryId === category.categoryId
                      ? "text-gray-700"
                      : "text-gray-400"
                  } transition duration-300`}
                />
              </button>
            ))}
          </div>
        )}

        {/* Hovered Category Submenu Overlay */}
        {hoveredCategory && (
          <div
            className="absolute top-[64px] left-full w-[35vw] bg-white shadow-lg border border-gray-200 z-50 p-4 flex"
            style={{ minHeight: menuHeight || "auto" }} // Set height dynamically based on expanded menu
            onMouseLeave={() => setHoveredCategory(null)}
          >
            {/* Single Column for Category Names */}
            <div className="w-1/2">
              <button
                // to={`/san-pham/${getCategorySlugFromName(
                //   hoveredCategory.categoryName
                // )}`}
                onClick={() => {
                  setIsExpanded(false);
                  setHoveredCategory(null);

                  navigate(
                    `/san-pham/${getCategorySlugFromName(
                      hoveredCategory.categoryName
                    )}?orderByDescending=popular`
                  );
                }}
              >
                <h2 className="text-lg text-primary hover:text-primary-dark transition duration-300 font-semibold mb-4 uppercase">
                  {hoveredCategory.categoryName}
                </h2>
              </button>
              <div className="space-y-3">
                {hoveredCategory.childCategories.map((childCategory) => (
                  <div key={childCategory.categoryId} className="space-y-2">
                    <h3
                      className="text-gray-800 hover:text-primary transition duration-300 cursor-pointer"
                      onClick={() =>
                        handleChildCategoryClick(hoveredCategory, childCategory)
                      }
                    >
                      {childCategory.categoryName}
                    </h3>
                    {childCategory.childCategories?.length > 0 && (
                      <ul className="pl-4 border-l-2 border-gray-200">
                        {childCategory.childCategories.map((subCategory) => (
                          <li
                            key={subCategory.categoryId}
                            className="text-gray-600 hover:text-primary transition duration-300 cursor-pointer"
                          >
                            {subCategory.categoryName}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Right Column for Image */}
            <div className="w-1/2 flex justify-center items-start">
              <img
                src={
                  CATEGORIES.find(
                    (c) =>
                      c.CategoryCode.toString() === hoveredCategory.categoryCode
                  )?.CategoryImg
                }
                alt={hoveredCategory.categoryName}
                className="max-w-full max-h-64 object-contain"
              />
            </div>
          </div>
        )}
      </div>

      {/* Search Bar */}
      <div className="w-3/4">
        <SearchBar />
      </div>
    </div>
  );
};

export default HeroWithOverlay;

// import React, { useState } from "react";
// import { useQuery } from "@tanstack/react-query";
// import { RxHamburgerMenu } from "react-icons/rx";
// import {
//   MdOutlineKeyboardArrowRight,
//   MdOutlineKeyboardArrowDown,
// } from "react-icons/md";
// import { fetchBrands, fetchCategories } from "../../services/home";
// import {
//   getCategorySlugFromName,
//   removeDichVuCategory,
// } from "../../shared/utils";
// import SearchBar from "../Home/SearchBar";
// import { Category } from "../../shared/types";
// import { Link, useNavigate } from "react-router-dom";
// import { CATEGORIES } from "../../shared/constant";

// const HeroWithOverlay = () => {
//   const { error: errorCategory, data: dataCategory } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//   });
//   const {
//     isPending: isLoadingBrand,
//     error: errorBrand,
//     data: dataBrand,
//   } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//     staleTime: 1000 * 60 * 5,
//   });

//   const navigate = useNavigate();
//   const [isExpanded, setIsExpanded] = useState(false);
//   const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);

//   const handleChildCategoryClick = (
//     parentCategoryName: string,
//     childCategoryName: string
//   ) => {
//     if (parentCategoryName === "Phụ Kiện Cầu Lông") {
//       navigate(`/tim-kiem?q=${encodeURIComponent(childCategoryName)}`);
//       return;
//     }

//     const brandName = childCategoryName.split(" ").pop();
//     const brand = dataBrand?.data.find(
//       (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
//     );
//     const parentCategorySlug = getCategorySlugFromName(parentCategoryName);

//     if (brand) {
//       navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
//     } else {
//       console.error("Brand not found:", brandName);
//     }
//   };

//   return (
//     <div className="flex gap-8 mt-5 relative">
//       {/* Left Navigation with Categories */}
//       <div
//         className="w-1/4 relative z-50"
//         onMouseEnter={() => setIsExpanded(true)}
//         onMouseLeave={() => {
//           setIsExpanded(false);
//           setHoveredCategory(null);
//         }}
//       >
//         <div className="bg-black text-white flex justify-between items-center px-6 py-5 rounded-t-lg cursor-pointer">
//           <div className="flex gap-3">
//             <RxHamburgerMenu size={24} />
//             <p className="font-medium">DANH MỤC SẢN PHẨM</p>
//           </div>
//           {isExpanded ? (
//             <MdOutlineKeyboardArrowDown size={24} />
//           ) : (
//             <MdOutlineKeyboardArrowRight size={24} />
//           )}
//         </div>

//         {/* Expanded Category Menu */}
//         {isExpanded && (
//           <div className="absolute top-full left-0 w-full bg-white shadow-lg border border-gray-200">
//             {dataCategory?.data.filter(removeDichVuCategory).map((category) => (
//               <Link
//                 to={`/san-pham/${getCategorySlugFromName(
//                   category.categoryName
//                 )}`}
//                 key={category.categoryId}
//                 className={`flex justify-between items-center py-3 pl-6 pr-3 border-b-[1px] border-gray-200 ${
//                   hoveredCategory?.categoryId === category.categoryId &&
//                   "font-semibold"
//                 } transition duration-300`}
//                 onMouseEnter={() => setHoveredCategory(category)}
//               >
//                 <p className="hover:text-primary transition duration-300">
//                   {category.categoryName}
//                 </p>
//                 <MdOutlineKeyboardArrowRight size={24} />
//               </Link>
//             ))}
//           </div>
//         )}

//         {/* Hovered Category Submenu Overlay */}
//         {hoveredCategory && (
//           <div
//             className="absolute top-[64px] left-full w-[35vw] min-h-[calc(100%-64px)] bg-white shadow-lg border border-gray-200 z-50 p-4 flex"
//             onMouseLeave={() => setHoveredCategory(null)}
//           >
//             {/* Single Column for Category Names */}
//             <div className="w-1/2">
//               <h2 className="text-lg text-primary font-semibold mb-4 uppercase">
//                 {hoveredCategory.categoryName}
//               </h2>
//               <div className="space-y-3">
//                 {hoveredCategory.childCategories.map((childCategory) => (
//                   <div key={childCategory.categoryId} className="space-y-2">
//                     <h3
//                       className="text-gray-800 hover:text-primary transition duration-300 cursor-pointer"
//                       onClick={() =>
//                         handleChildCategoryClick(
//                           hoveredCategory.categoryName,
//                           childCategory.categoryName
//                         )
//                       }
//                     >
//                       {childCategory.categoryName}
//                     </h3>
//                     {childCategory.childCategories?.length > 0 && (
//                       <ul className="pl-4 border-l-2 border-gray-200">
//                         {childCategory.childCategories.map((subCategory) => (
//                           <li
//                             key={subCategory.categoryId}
//                             className="text-gray-600 hover:text-primary transition duration-300 cursor-pointer"
//                           >
//                             {subCategory.categoryName}
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </div>
//                 ))}
//               </div>
//             </div>

//             {/* Right Column for Image */}
//             <div className="w-1/2 flex justify-center items-start">
//               <img
//                 src={
//                   CATEGORIES.find(
//                     (c) =>
//                       c.CategoryCode.toString() === hoveredCategory.categoryCode
//                   )?.CategoryImg
//                 }
//                 alt={hoveredCategory.categoryName}
//                 className="max-w-full max-h-64 object-contain"
//               />
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Search Bar */}
//       <div className="w-3/4">
//         <SearchBar />
//       </div>
//     </div>
//   );
// };

// export default HeroWithOverlay;

// import React, { useState } from "react";
// import { useQuery } from "@tanstack/react-query";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { fetchBrands, fetchCategories } from "../../services/home";
// import {
//   getCategorySlugFromName,
//   removeDichVuCategory,
// } from "../../shared/utils";
// import SearchBar from "../Home/SearchBar";
// import { Category } from "../../shared/types";
// import { Link, useNavigate } from "react-router-dom";
// import { CATEGORIES } from "../../shared/constant";

// const HeroWithHoverExpandableNavigation = () => {
//   const { error: errorCategory, data: dataCategory } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//   });
//   const { error: errorBrand, data: dataBrand } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//     staleTime: 1000 * 60 * 5,
//   });

//   const navigate = useNavigate();
//   const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);
//   const [isNavExpanded, setIsNavExpanded] = useState(false);

//   // Function to handle navigation with brand filter
//   const handleChildCategoryClick = (
//     parentCategoryName: string,
//     childCategoryName: string
//   ) => {
//     if (parentCategoryName === "Phụ Kiện Cầu Lông") {
//       navigate(`/tim-kiem?q=${encodeURIComponent(childCategoryName)}`);
//       return;
//     }

//     const brandName = childCategoryName.split(" ").pop();
//     const brand = dataBrand?.data.find(
//       (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
//     );
//     const parentCategorySlug = getCategorySlugFromName(parentCategoryName);

//     if (brand) {
//       navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
//     } else {
//       console.error("Brand not found:", brandName);
//     }
//   };

//   return (
//     <div className="flex gap-8 mt-5 relative">
//       {/* Left Navigation with Categories */}
//       <div
//         className="w-1/4 relative z-30"
//         onMouseEnter={() => setIsNavExpanded(true)}
//         onMouseLeave={() => {
//           setIsNavExpanded(false);
//           setHoveredCategory(null);
//         }}
//       >
//         <div className="bg-black text-white flex items-center gap-3 px-6 py-5 rounded-t-lg">
//           <RxHamburgerMenu size={24} />
//           <p className="font-medium">DANH SÁCH SẢN PHẨM</p>
//           <MdOutlineKeyboardArrowRight size={24} />
//         </div>

//         {/* Expanded Navigation Categories */}
//         {isNavExpanded && (
//           <div className="absolute top-[64px] left-0 w-full bg-white shadow-lg border border-gray-200 z-50">
//             <div>
//               {dataCategory?.data
//                 .filter(removeDichVuCategory)
//                 .map((category) => (
//                   <Link
//                     to={`/san-pham/${getCategorySlugFromName(
//                       category.categoryName
//                     )}`}
//                     key={category.categoryId}
//                     className={`flex justify-between items-center py-[16px] pl-[22px] pr-3 border-b-[1px] border-gray-200 ${
//                       hoveredCategory?.categoryId === category.categoryId &&
//                       "font-semibold"
//                     } transition duration-300`}
//                     onMouseEnter={() => setHoveredCategory(category)}
//                     onMouseLeave={() => setHoveredCategory(null)}
//                   >
//                     <p className="hover:text-primary transition duration-300">
//                       {category.categoryName}
//                     </p>
//                     <MdOutlineKeyboardArrowRight size={24} />
//                   </Link>
//                 ))}
//             </div>

//             {/* Single Column with Image on Hover */}
//             {hoveredCategory && (
//               <div
//                 className="absolute top-[64px] left-full w-[35vw] min-h-[calc(100%-77px)] bg-white shadow-lg border border-gray-200 z-50 p-4 flex"
//                 onMouseEnter={() => setHoveredCategory(hoveredCategory)}
//                 onMouseLeave={() => setHoveredCategory(null)}
//               >
//                 {/* Left Column for Category Names */}
//                 <div className="w-1/2">
//                   <h2 className="text-lg text-primary font-semibold mb-4 uppercase ">
//                     {hoveredCategory.categoryName}
//                   </h2>
//                   <div className="space-y-3">
//                     {hoveredCategory.childCategories.map((childCategory) => (
//                       <div key={childCategory.categoryId} className="space-y-2">
//                         <h3
//                           className="text-gray-800 hover:text-primary transition duration-300 cursor-pointer whitespace-nowrap"
//                           onClick={() =>
//                             handleChildCategoryClick(
//                               hoveredCategory.categoryName,
//                               childCategory.categoryName
//                             )
//                           }
//                         >
//                           {childCategory.categoryName}
//                         </h3>
//                         {childCategory.childCategories?.length > 0 && (
//                           <ul className="pl-4 border-l-2 border-gray-200">
//                             {childCategory.childCategories.map(
//                               (subCategory) => (
//                                 <li
//                                   key={subCategory.categoryId}
//                                   className="text-gray-600 hover:text-primary transition duration-300 cursor-pointer"
//                                 >
//                                   {subCategory.categoryName}
//                                 </li>
//                               )
//                             )}
//                           </ul>
//                         )}
//                       </div>
//                     ))}
//                   </div>
//                 </div>

//                 {/* Right Column for Image */}
//                 <div className="w-1/2 flex justify-center items-start">
//                   <img
//                     src={
//                       CATEGORIES.find(
//                         (c) =>
//                           c.CategoryCode.toString() ===
//                           hoveredCategory.categoryCode
//                       )?.CategoryImg
//                     }
//                     alt={hoveredCategory.categoryName}
//                     className="max-w-full max-h-64 object-contain"
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//         )}
//       </div>

//       {/* Search Bar */}
//       <div className="w-3/4">
//         <SearchBar />
//       </div>
//     </div>
//   );
// };

// export default HeroWithHoverExpandableNavigation;

// import React, { useState } from "react";
// import { useQuery } from "@tanstack/react-query";
// import { RxHamburgerMenu } from "react-icons/rx";
// import {
//   MdOutlineKeyboardArrowRight,
//   MdKeyboardArrowDown,
// } from "react-icons/md";
// import { fetchBrands, fetchCategories } from "../../services/home";
// import {
//   getCategorySlugFromName,
//   removeDichVuCategory,
// } from "../../shared/utils";
// import SearchBar from "../Home/SearchBar";
// import { Category } from "../../shared/types";
// import { Link, useNavigate } from "react-router-dom";

// const HeroWithExpandableNavigation = () => {
//   const { error: errorCategory, data: dataCategory } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//   });
//   const { error: errorBrand, data: dataBrand } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//     staleTime: 1000 * 60 * 5,
//   });

//   const navigate = useNavigate();
//   const [isNavExpanded, setIsNavExpanded] = useState(false);
//   const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);

//   // Function to handle navigation with brand filter
//   const handleChildCategoryClick = (
//     parentCategoryName: string,
//     childCategoryName: string
//   ) => {
//     if (parentCategoryName === "Phụ Kiện Cầu Lông") {
//       navigate(`/tim-kiem?q=${encodeURIComponent(childCategoryName)}`);
//       return;
//     }

//     const brandName = childCategoryName.split(" ").pop();
//     const brand = dataBrand?.data.find(
//       (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
//     );
//     const parentCategorySlug = getCategorySlugFromName(parentCategoryName);

//     if (brand) {
//       navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
//     } else {
//       console.error("Brand not found:", brandName);
//     }
//   };

//   return (
//     <div className="flex gap-8 mt-5 relative">
//       {/* Left Navigation with Categories */}
//       <div className="w-1/4 relative z-30">
//         <div
//           className="bg-black text-white flex items-center gap-3 px-6 py-5 rounded-t-lg cursor-pointer"
//           onClick={() => setIsNavExpanded(!isNavExpanded)}
//         >
//           <RxHamburgerMenu size={24} />
//           <p className="font-medium">DANH SÁCH SẢN PHẨM</p>
//           {isNavExpanded ? (
//             <MdKeyboardArrowDown size={24} />
//           ) : (
//             <MdOutlineKeyboardArrowRight size={24} />
//           )}
//         </div>

//         {/* Expanded Navigation Categories */}
//         {isNavExpanded && (
//           <div className="absolute top-[64px] left-0 w-full bg-white shadow-lg border border-gray-200 z-50">
//             <div>
//               {dataCategory?.data
//                 .filter(removeDichVuCategory)
//                 .map((category) => (
//                   <div
//                     key={category.categoryId}
//                     className={`flex justify-between items-center py-[16px] pl-[22px] pr-3 border-b-[1px] border-gray-200 ${
//                       hoveredCategory?.categoryId === category.categoryId &&
//                       "font-semibold"
//                     } transition duration-300`}
//                     onMouseEnter={() => setHoveredCategory(category)}
//                     onMouseLeave={() => setHoveredCategory(null)}
//                   >
//                     <p className="hover:text-primary transition duration-300">
//                       {category.categoryName}
//                     </p>
//                     <MdOutlineKeyboardArrowRight size={24} />
//                   </div>
//                 ))}
//             </div>

//             {/* Right Panel with Child Categories */}
//             {hoveredCategory && (
//               <div
//                 className="absolute top-0 left-full w-[71vw] h-full bg-white shadow-lg border border-gray-200 p-6 z-50"
//                 onMouseEnter={() => setHoveredCategory(hoveredCategory)}
//                 onMouseLeave={() => setHoveredCategory(null)}
//               >
//                 <div className="grid grid-cols-3 gap-8 px-4 py-4">
//                   {hoveredCategory.childCategories.map((childCategory) => (
//                     <button
//                       onClick={() =>
//                         handleChildCategoryClick(
//                           hoveredCategory.categoryName,
//                           childCategory.categoryName
//                         )
//                       }
//                       key={childCategory.categoryId}
//                     >
//                       <h3 className="font-bold mb-2 hover:text-primary transition duration-300 text-left">
//                         {childCategory.categoryName}
//                       </h3>
//                       {childCategory.childCategories?.length > 0 && (
//                         <ul className="space-y-1">
//                           {childCategory.childCategories.map((subCategory) => (
//                             <li
//                               key={subCategory.categoryId}
//                               className="text-gray-600"
//                             >
//                               {subCategory.categoryName}
//                             </li>
//                           ))}
//                         </ul>
//                       )}
//                     </button>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         )}
//       </div>

//       {/* Search Bar */}
//       <div className="w-3/4">
//         <SearchBar />
//       </div>
//     </div>
//   );
// };

// export default HeroWithExpandableNavigation;
