import axios, { AxiosRequestConfig, AxiosError } from "axios";

// Helper functions to get/set tokens
const getAccessToken = () => localStorage.getItem("accessToken");
const getRefreshToken = () => localStorage.getItem("refreshToken");
const setAccessToken = (token: string) =>
  localStorage.setItem("accessToken", token);
const setRefreshToken = (token: string) =>
  localStorage.setItem("refreshToken", token);
const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Token refreshing logic
let isRefreshing = false;
let failedQueue: any[] = [];

// Process queue function
const processQueue = (
  error: AxiosError | null,
  token: string | null = null
) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Request interceptor to attach the access token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    // Check if the error is due to an expired access token
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = getRefreshToken();

        if (refreshToken) {
          try {
            const response = await axios.post("/auth/refresh", {
              token: refreshToken,
            });

            // Update tokens
            setAccessToken(response.data.accessToken);
            setRefreshToken(response.data.refreshToken);

            processQueue(null, response.data.accessToken);

            // Retry the original request with the new access token
            return axiosInstance(originalRequest);
          } catch (refreshError: any) {
            processQueue(refreshError, null);
            removeTokens(); // Clean up tokens if refreshing fails
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          // No refresh token available, log the user out
          removeTokens();
          return Promise.reject(error);
        }
      }

      // Queue the requests while the refresh is happening
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          if (originalRequest.headers)
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
          return axiosInstance(originalRequest);
        })
        .catch((queueError) => {
          return Promise.reject(queueError);
        });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
