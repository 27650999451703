import React from "react";
import discount from "../../assets/discount.png";
import maydan from "../../assets/maydan.png";
import oneShoe from "../../assets/oneShoe.png";

const DiscountBanner: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-6 my-8 lg:my-12 px-4 md:px-6 lg:px-0">
      {/* Left Banner Section */}
      <div className="flex-1 bg-[#EDEDED] p-6 md:p-8 flex items-center justify-between space-x-4 md:space-x-6">
        <div>
          <h3 className="text-primary font-extrabold text-xl md:text-2xl lg:text-3xl">
            VOUCHER
          </h3>
          <p className="text-lg md:text-xl lg:text-2xl font-semibold mt-2">
            GIẢM 50K CHO <br className="hidden md:block" /> LẦN ĐAN TIẾP THEO
          </p>
        </div>
        <div className="relative">
          <img src={maydan} alt="Bag" className="w-32 md:w-40 lg:w-44 h-auto" />
          <img
            src={discount}
            alt="Discount 50%"
            className="absolute top-1 -left-3 md:top-2 md:-left-4 lg:top-4 lg:-left-6 w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
          />
        </div>
      </div>

      {/* Right Banner Section */}
      <div className="flex-1 bg-[#A52B2D] p-6 md:p-8 flex items-center justify-between text-white space-x-4 md:space-x-6">
        <div>
          <h3 className="font-extrabold text-xl md:text-2xl lg:text-3xl">
            SĂN SALE
          </h3>
          <p className="text-lg md:text-xl lg:text-2xl font-semibold mt-2">
            &nbsp; &nbsp; NHẬN QUÀ KHỦNG
          </p>
        </div>
        <div className="relative">
          <img
            src={oneShoe}
            alt="Shoe"
            className="w-32 md:w-40 lg:w-60 h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default DiscountBanner;
