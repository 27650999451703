import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import useViewport from "../../hooks/useViewport";
import { fetchMainProducts } from "../../services/home";
import { MainProduct } from "../../shared/types";
import ProductSlider from "../Common/ProductSlider";

const AccessorySlider: React.FC = () => {
  const { isMobile, isTablet } = useViewport();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  const {
    isPending: isLoadingMainProduct,
    error: errorMainProduct,
    data: dataMainProduct,
  } = useQuery({
    queryKey: ["mainProducts"],
    queryFn: fetchMainProducts,
  });

  if (isLoadingMainProduct) return <div>Loading...</div>;
  if (errorMainProduct) return <div>Error fetching main products</div>;

  const accessoryCategory = dataMainProduct?.data.find(
    (mainProduct: MainProduct) =>
      mainProduct.categoryName === "Phụ Kiện Cầu Lông"
  );

  const uniqueCategories = Array.from(
    new Set(
      accessoryCategory?.products.map((product) => product.categoryDetailName)
    )
  ).filter((name) => name);

  const filteredProducts =
    selectedCategory === "All"
      ? accessoryCategory?.products
      : accessoryCategory?.products.filter(
          (product) => product.categoryDetailName === selectedCategory
        );

  return (
    <div className="py-4 md:py-6 lg:py-8">
      <div className="px-4 md:px-5">
        <div className="flex flex-col md:flex-row md:justify-between items-center mb-3 md:mb-5">
          {/* Title */}
          <h2 className="text-lg md:text-2xl lg:text-3xl font-extrabold mb-4 md:mb-0">
            PHỤ KIỆN
          </h2>

          {/* Category Filter Buttons */}
          <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
            <button
              onClick={() => setSelectedCategory("All")}
              className={`px-3 py-1 md:px-4 md:py-2 rounded-md ${
                selectedCategory === "All"
                  ? "bg-primary text-white"
                  : "border border-gray-300 text-gray-600"
              } text-sm md:text-base`}
            >
              Tất cả
            </button>

            {uniqueCategories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-3 py-1 md:px-4 md:py-2 rounded-md ${
                  selectedCategory === category
                    ? "bg-primary text-white"
                    : "border border-gray-300 text-gray-600"
                } text-sm md:text-base`}
              >
                {category}
              </button>
            ))}

            {/* Right Arrow Button */}
            <button
              onClick={() =>
                navigate(
                  "/san-pham/phu-kien-cau-long?orderByDescending=popular"
                )
              }
              className="px-3 py-1 md:px-4 md:py-2 rounded-md border border-gray-300 text-gray-600 flex items-center text-sm md:text-base"
            >
              <AiOutlineArrowRight className="text-lg" />
            </button>
          </div>
        </div>

        <ProductSlider products={filteredProducts} />
        {/* Product Slider */}
      </div>
    </div>
  );
};

export default AccessorySlider;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import ProductCard from "../Common/ProductCard";
// import useViewport from "../../hooks/useViewport";
// import { useQuery } from "@tanstack/react-query";
// import { fetchMainProducts } from "../../services/home";
// import { AiOutlineArrowRight } from "react-icons/ai";

// interface Product {
//   productId: string;
//   productName: string;
//   brand: string;
//   productCode: string;
//   erpProductCode: string;
//   price?: number;
//   imageUrl?: string;
//   isPopular: boolean;
//   manufacturerPrice?: number;
//   categoryName: string;
//   categoryDetailName: string;
// }

// interface MainProduct {
//   categoryName: string;
//   products: Product[];
// }

// const AccessorySlider: React.FC = () => {
//   const { isMobile, isTablet, isDesktop } = useViewport();
//   const [selectedCategory, setSelectedCategory] = useState("All");
//   const navigate = useNavigate();

//   const {
//     isPending: isLoadingMainProduct,
//     error: errorMainProduct,
//     data: dataMainProduct,
//   } = useQuery({
//     queryKey: ["mainProducts"],
//     queryFn: fetchMainProducts,
//   });

//   if (isLoadingMainProduct) {
//     return <div>Loading...</div>;
//   }

//   if (errorMainProduct) {
//     return <div>Error fetching main products</div>;
//   }

//   // Find "Phụ Kiện Cầu Lông" category and extract products
//   const accessoryCategory = dataMainProduct?.data.find(
//     (mainProduct: MainProduct) =>
//       mainProduct.categoryName === "Phụ Kiện Cầu Lông"
//   );

//   // Extract unique categoryDetailNames
//   const uniqueCategories = Array.from(
//     new Set(
//       accessoryCategory?.products.map((product) => product.categoryDetailName)
//     )
//   ).filter((name) => name);

//   // Filter products by selected categoryDetailName
//   const filteredProducts =
//     selectedCategory === "All"
//       ? accessoryCategory?.products
//       : accessoryCategory?.products.filter(
//           (product) => product.categoryDetailName === selectedCategory
//         );

//   // Adjust Swiper configuration based on viewport size
//   const slidesPerView = isMobile ? 1.5 : isTablet ? 2.5 : 4;
//   const spaceBetween = isMobile ? 16 : isTablet ? 20 : 30;

//   return (
//     <div className="py-8 px-5">
//       <div className="flex justify-between items-center mb-6">
//         {/* Title */}
//         <h2 className="text-2xl font-extrabold">PHỤ KIỆN</h2>

//         {/* Category Filter Buttons */}
//         <div className="flex flex-wrap space-x-2">
//           {/* All Option */}
//           <button
//             onClick={() => setSelectedCategory("All")}
//             className={`px-4 py-2 rounded ${
//               selectedCategory === "All"
//                 ? "bg-primary text-white"
//                 : "border border-gray-300 text-gray-600"
//             }`}
//           >
//             Tất cả
//           </button>

//           {/* Unique Category Detail Names */}
//           {uniqueCategories.map((category) => (
//             <button
//               key={category}
//               onClick={() => setSelectedCategory(category)}
//               className={`px-4 py-2 rounded ${
//                 selectedCategory === category
//                   ? "bg-primary text-white"
//                   : "border border-gray-300 text-gray-600"
//               }`}
//             >
//               {category}
//             </button>
//           ))}

//           {/* Right Arrow Button */}
//           <button
//             onClick={() => navigate("/san-pham/phu-kien-cau-long")}
//             className="px-4 py-2 rounded border border-gray-300 text-gray-600 flex items-center"
//           >
//             <AiOutlineArrowRight className="text-lg" />
//           </button>
//         </div>
//       </div>

//       {/* Product Slider */}
//       <Swiper
//         modules={[Navigation]}
//         spaceBetween={spaceBetween}
//         slidesPerView={slidesPerView}
//         navigation
//         loop
//         className="px-7"
//       >
//         {filteredProducts?.map((product, index) => (
//           <SwiperSlide key={product.productId} className="select-none">
//             <ProductCard
//               id={product.productId}
//               category={product.categoryName}
//               name={product.productName}
//               brand={product.brand}
//               originalPrice={`${product.manufacturerPrice?.toLocaleString()}₫`}
//               salePrice={`${product.price?.toLocaleString()}₫`}
//               colors={["#FFA500", "#FF0000", "#000000"]}
//               badge={product.isPopular ? "HOT" : undefined}
//               imageUrl={product.imageUrl || "/path/to/default/image.png"}
//             />
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

// export default AccessorySlider;
