import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/Common/ScrollToTop";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import CollectionPage from "./pages/CollectionPage";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import ProductPage from "./pages/ProductPage";
import SearchResultPage from "./pages/SearchResultPage";
import ProductPage2 from "./pages/ProductPage2";
import ChatWidget from "./components/Common/ChatWidget";

function App() {
  // Redirect http thành https
  // useEffect(() => {
  //   if (window.location.protocol === "http:") {
  //     window.location.href = window.location.href.replace("http:", "https:");
  //   }
  // }, []);

  return (
    <>
      <ScrollToTop />
      <ChatWidget />
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/san-pham/:categorySlug" element={<CollectionPage />} />
        <Route
          path="/san-pham/:categorySlug/:productId"
          element={<ProductPage2 />}
        />
        <Route path="/gio-hang" element={<CartPage />} />
        <Route path="/tim-kiem" element={<SearchResultPage />} />
        <Route path="/thanh-toan" element={<CheckoutPage />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
