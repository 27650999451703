import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { fetchBrands, fetchCategories } from "../../services/home";
import { CATEGORIES } from "../../shared/constant";
import { Category } from "../../shared/types";
import {
  getCategorySlugFromName,
  removeDichVuCategory,
} from "../../shared/utils";
import SearchBar from "../Home/SearchBar";
import Slider from "../Home/Slider";
import Skeleton from "../Common/Skeleton";

const Hero = () => {
  const { error: errorCategory, data: dataCategory } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });
  const {
    isPending: isLoadingBrand,
    error: errorBrand,
    data: dataBrand,
  } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
    staleTime: 1000 * 60 * 5,
  });

  const navigate = useNavigate();
  const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);

  // Function to handle navigation with brand filter
  const handleChildCategoryClick = (
    parentCategory: Category,
    childCategory: Category
  ) => {
    const parentCategorySlug = getCategorySlugFromName(
      parentCategory.categoryName
    );

    if (parentCategory.categoryName === "Phụ Kiện Cầu Lông") {
      navigate(
        `/san-pham/${parentCategorySlug}?accessoryDetailCode=${childCategory.categoryCode}`
      );
      return;
    }

    const brandName = childCategory.categoryName.split(" ").pop();
    const brand = dataBrand?.data.find(
      (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
    );

    if (brand) {
      navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
    } else {
      console.error("Brand not found:", brandName);
    }
  };

  return (
    <>
      <div className="hidden md:flex gap-8 mt-5 relative">
        {/* Left Navigation with Categories */}
        <div className="w-1/4 relative">
          <div className="bg-black text-white flex items-center gap-3 px-6 py-[14px] rounded-t-lg">
            <RxHamburgerMenu size={24} />
            <p className="font-medium">DANH MỤC SẢN PHẨM</p>
          </div>
          <div>
            {!dataCategory ? (
              <div className="space-y-4">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center px-4 py-3 border-b border-gray-200"
                  >
                    <Skeleton width="70%" height="20px" />
                    <Skeleton width="24px" height="24px" />
                  </div>
                ))}
              </div>
            ) : (
              dataCategory?.data
                .filter(removeDichVuCategory)
                .map((category) => (
                  <Link
                    to={`/san-pham/${getCategorySlugFromName(
                      category.categoryName
                    )}?orderByDescending=popular`}
                    key={category.categoryId}
                    className={`flex justify-between items-center md:py-[9px] lg:py-[12px] xl:py-[15px] pl-[22px] pr-3 border-b-[1px] border-gray-200 ${
                      hoveredCategory?.categoryId === category.categoryId &&
                      "font-semibold"
                    } transition duration-300`}
                    onMouseEnter={() => setHoveredCategory(category)}
                    onMouseLeave={() => setHoveredCategory(null)}
                  >
                    <p className="hover:text-primary transition duration-300">
                      {category.categoryName}
                    </p>
                    <MdOutlineKeyboardArrowRight
                      size={24}
                      className={`${
                        hoveredCategory?.categoryId === category.categoryId
                          ? "text-gray-700"
                          : "text-gray-400"
                      } transition duration-300`}
                    />
                  </Link>
                ))
            )}
          </div>

          {/* Single Column with Image on Hover */}
          {hoveredCategory && (
            <div
              className="absolute top-[64px] left-full w-[35vw] min-h-[calc(100%-77px)] bg-white shadow-lg border border-gray-200 z-50 p-4 flex"
              onMouseEnter={() => setHoveredCategory(hoveredCategory)}
              onMouseLeave={() => setHoveredCategory(null)}
            >
              {/* Left Column for Category Names */}
              <div className="w-1/2">
                <Link
                  to={`/san-pham/${getCategorySlugFromName(
                    hoveredCategory.categoryName
                  )}`}
                >
                  <h2 className="text-lg text-primary hover:text-primary-dark transition duration-300 font-semibold mb-4 uppercase ">
                    {hoveredCategory.categoryName}
                  </h2>
                </Link>
                <div className="space-y-3">
                  {hoveredCategory.childCategories.map((childCategory) => (
                    <div key={childCategory.categoryId} className="space-y-2">
                      <h3
                        className="text-gray-800 hover:text-primary transition duration-300 cursor-pointer whitespace-nowrap"
                        onClick={() =>
                          handleChildCategoryClick(
                            hoveredCategory,
                            childCategory
                          )
                        }
                      >
                        {childCategory.categoryName}
                      </h3>
                      {childCategory.childCategories?.length > 0 && (
                        <ul className="pl-4 border-l-2 border-gray-200">
                          {childCategory.childCategories.map((subCategory) => (
                            <li
                              key={subCategory.categoryId}
                              className="text-gray-600 hover:text-primary transition duration-300 cursor-pointer"
                            >
                              {subCategory.categoryName}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Right Column for Image */}
              <div className="w-1/2 flex justify-center items-start">
                <img
                  src={
                    CATEGORIES.find(
                      (c) =>
                        c.CategoryCode.toString() ===
                        hoveredCategory.categoryCode
                    )?.CategoryImg
                  }
                  alt={hoveredCategory.categoryName}
                  className="max-w-full max-h-64 object-contain"
                />
              </div>
            </div>
          )}
        </div>

        {/* Search Bar */}
        <div className="w-3/4">
          <SearchBar />

          <Slider />
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        <Slider />
      </div>
    </>
  );
};

export default Hero;

// import React, { useState } from "react";
// import { useQuery } from "@tanstack/react-query";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { fetchBrands, fetchCategories } from "../../services/home";
// import {
//   getCategorySlugFromName,
//   removeDichVuCategory,
// } from "../../shared/utils";
// import SearchBar from "../Home/SearchBar";
// import Slider from "../Home/Slider";
// import { Category } from "../../shared/types";
// import { Link, useNavigate } from "react-router-dom";

// const Hero = () => {
//   const { error: errorCategory, data: dataCategory } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//   });
//   const {
//     isPending: isLoadingBrand,
//     error: errorBrand,
//     data: dataBrand,
//   } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//     staleTime: 1000 * 60 * 5,
//   });

//   const navigate = useNavigate();

//   const [hoveredCategory, setHoveredCategory] = useState<Category | null>(null);

//   // Function to handle navigation with brand filter
//   const handleChildCategoryClick = (
//     parentCategoryName: string,
//     childCategoryName: string
//   ) => {
//     if (parentCategoryName === "Phụ Kiện Cầu Lông") {
//       navigate(`/tim-kiem?q=${encodeURIComponent(childCategoryName)}`);
//       // setIsCategoryOpen(false);
//       return;
//     }

//     const brandName = childCategoryName.split(" ").pop();
//     const brand = dataBrand?.data.find(
//       (b) => b.brandName.toLowerCase() === brandName?.toLowerCase()
//     );
//     const parentCategorySlug = getCategorySlugFromName(parentCategoryName);

//     if (brand) {
//       navigate(`/san-pham/${parentCategorySlug}?brandCodes=${brand.brandCode}`);
//       // setIsCategoryOpen(false);
//     } else {
//       console.error("Brand not found:", brandName);
//     }
//   };

//   return (
//     <>
//       <div className="hidden md:flex gap-8 mt-5 relative ">
//         {/* Left Navigation with Categories */}
//         <div className="w-1/4 relative">
//           <div className="bg-black text-white flex items-center gap-3 px-6 py-5 rounded-t-lg ">
//             <RxHamburgerMenu size={24} />
//             <p className="font-medium">DANH SÁCH SẢN PHẨM</p>
//           </div>
//           <div>
//             {dataCategory?.data.filter(removeDichVuCategory).map((category) => (
//               <Link
//                 to={`/san-pham/${getCategorySlugFromName(
//                   category.categoryName
//                 )}`}
//                 key={category.categoryId}
//                 className={`flex justify-between items-center py-[16px] pl-[22px] pr-3 border-b-[1px] border-gray-200 ${
//                   hoveredCategory?.categoryId === category.categoryId &&
//                   "font-semibold"
//                 } transition duration-300`}
//                 onMouseEnter={() => setHoveredCategory(category)}
//                 onMouseLeave={() => setHoveredCategory(null)}
//               >
//                 <p className="hover:text-primary transition duration-300">
//                   {category.categoryName}
//                 </p>
//                 <MdOutlineKeyboardArrowRight size={24} />
//               </Link>
//             ))}
//           </div>

//           {/* Full Height and Width Right Panel with Child Categories */}
//           {hoveredCategory && (
//             <div
//               className="absolute top-[64px] left-full w-[71vw] h-[calc(100%-64px)] bg-white shadow-lg border border-gray-200  z-50"
//               onMouseEnter={() => setHoveredCategory(hoveredCategory)}
//               onMouseLeave={() => setHoveredCategory(null)}
//             >
//               <h2 className="text-lg text-primary-light uppercase font-semibold">
//                 {hoveredCategory.categoryName}
//               </h2>
//               <div className="px-4 py-4">
//                 {hoveredCategory.childCategories.map((childCategory) => (
//                   <button
//                     onClick={() =>
//                       handleChildCategoryClick(
//                         hoveredCategory.categoryName,
//                         childCategory.categoryName
//                       )
//                     }
//                     key={childCategory.categoryId}
//                   >
//                     <h3 className="font-bold mb-2 hover:text-primary transition duration-300 text-left">
//                       {childCategory.categoryName}
//                     </h3>
//                     {childCategory.childCategories?.length > 0 && (
//                       <ul className="space-y-1">
//                         {childCategory.childCategories.map((subCategory) => (
//                           <li
//                             key={subCategory.categoryId}
//                             className="text-gray-600"
//                           >
//                             {subCategory.categoryName}
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Search Bar and Slider */}
//         <div className="w-3/4">
//           <SearchBar />
//           <Slider />
//         </div>
//       </div>

//       <div className="md:hidden">
//         <Slider />
//       </div>
//     </>
//   );
// };

// export default Hero;

// import { useQuery } from "@tanstack/react-query";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { fetchCategories } from "../../services/home";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { removeDichVuCategory } from "../../shared/utils";
// import SearchBar from "../Home/SearchBar";
// import ImageSlideshow from "./ImageSlideshow";
// import Slider from "../Home/Slider";

// const Hero = () => {
//   const { error: errorCategory, data: dataCategory } = useQuery({
//     queryKey: ["categories"],
//     queryFn: fetchCategories,
//   });

//   return (
//     <div className="flex gap-8 mt-5">
//       <div className="w-1/4 rounded-lg overflow-hidden">
//         <div className="bg-black text-white flex items-center gap-3 px-6 py-5">
//           <RxHamburgerMenu size={24} />
//           <p className="font-medium">DANH SÁCH SẢN PHẨM</p>
//         </div>
//         <div className="">
//           {dataCategory?.data.filter(removeDichVuCategory).map((category) => (
//             <div className="flex justify-between items-center py-[19px] pl-[22px] pr-3 border-b-[1px] border-gray-200border-b-[1px] border-gray-200">
//               <p>{category.categoryName}</p>
//               <MdOutlineKeyboardArrowRight size={24} />
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="w-3/4">
//         <SearchBar />
//         <Slider />
//       </div>
//     </div>
//   );
// };

// export default Hero;
