import axiosInstance from "../shared/axiosInstance";
import { APISingleResponse, ProductDetail } from "../shared/types";

export const fetchProductDetail = async (
  productId?: string
): Promise<APISingleResponse<ProductDetail>> => {
  try {
    if (!productId) throw new Error("Product ID is null");

    const response = await axiosInstance.get<APISingleResponse<ProductDetail>>(
      `/api/v1/products/${productId}`
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(
        response.data.message || "Failed to fetch product detail"
      );
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw new Error("An error occurred while fetching product detail");
  }
};
