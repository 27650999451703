import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CartItem } from "../shared/types";
const _ = require("lodash");
interface CartState {
  items: CartItem[];
  totalQuantity: number;
  totalAmount: number;
  changed: boolean;
  addItemToCart: (item: CartItem) => void;
  removeItemFromCart: (item: CartItem) => void;
  resetCart: () => void;
  replaceCart: (
    items: CartItem[],
    totalQuantity: number,
    totalAmount: number
  ) => void;
  removeEntireItem: (item: CartItem) => void;
}

const checkIf2ItemAreTheSame = (firstItem: CartItem, lastItem: CartItem) =>
  firstItem.id === lastItem.id &&
  _.isEqual(firstItem.characteristic, lastItem.characteristic);

const useCartStore = create<CartState>()(
  persist(
    (set) => ({
      items: [],
      totalQuantity: 0,
      totalAmount: 0,
      changed: false,

      addItemToCart: (newItem) =>
        set((state) => {
          const existingItem = state.items.find((item) =>
            checkIf2ItemAreTheSame(item, newItem)
          );
          let updatedItems;

          if (!existingItem) {
            updatedItems = [...state.items, newItem];
          } else {
            updatedItems = state.items.map((item) =>
              checkIf2ItemAreTheSame(item, newItem)
                ? {
                    ...item,
                    quantity: item.quantity + newItem.quantity,
                    totalPrice: item.totalPrice + newItem.totalPrice,
                  }
                : item
            );
          }

          return {
            items: updatedItems,
            totalQuantity: state.totalQuantity + newItem.quantity,
            totalAmount: state.totalAmount + newItem.totalPrice,
            changed: true,
          };
        }),

      removeItemFromCart: (cartItem) =>
        set((state) => {
          const existingItem = state.items.find((item) =>
            checkIf2ItemAreTheSame(item, cartItem)
          );

          if (!existingItem) return state;

          let updatedItems;
          if (existingItem.quantity === 1) {
            updatedItems = state.items.filter(
              (item) => !checkIf2ItemAreTheSame(item, cartItem)
            );
          } else {
            updatedItems = state.items.map((item) =>
              checkIf2ItemAreTheSame(item, cartItem)
                ? {
                    ...item,
                    quantity: item.quantity - 1,
                    totalPrice: item.totalPrice - item.price,
                  }
                : item
            );
          }

          return {
            items: updatedItems,
            totalQuantity: state.totalQuantity - 1,
            totalAmount: state.totalAmount - existingItem.price,
            changed: true,
          };
        }),

      resetCart: () =>
        set({
          items: [],
          totalQuantity: 0,
          totalAmount: 0,
          changed: true,
        }),

      replaceCart: (items, totalQuantity, totalAmount) =>
        set({
          items,
          totalQuantity,
          totalAmount,
          changed: false,
        }),

      removeEntireItem: (cartItem) =>
        set((state) => {
          const existingItem = state.items.find((item) =>
            checkIf2ItemAreTheSame(item, cartItem)
          );
          if (!existingItem) return state;

          const updatedItems = state.items.filter(
            (item) => !checkIf2ItemAreTheSame(item, cartItem)
          );

          return {
            items: updatedItems,
            totalQuantity: state.totalQuantity - existingItem.quantity,
            totalAmount: state.totalAmount - existingItem.totalPrice,
            changed: true,
          };
        }),
    }),
    {
      name: "cart",
    }
  )
);

export default useCartStore;
