import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import collectionPage from "../assets/collectionPage.png";
import ActiveFilters from "../components/Collection/ActiveFilters";
import FilterAccessory from "../components/Collection/FilterType/FilterAccessory";
import FilterBrands from "../components/Collection/FilterType/FilterBrands";
import FilterPrice from "../components/Collection/FilterType/FilterPrice";
import FilterSortBy from "../components/Collection/FilterType/FilterSortBy";
import ProductCollection from "../components/Collection/ProductCollection";
import Breadcrumb from "../components/Common/Breadcrumb";
import Container from "../components/Common/Container";
import Pagination from "../components/Common/Pagination";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
import { fetchAllProducts } from "../services/home";
import { Filter } from "../shared/types";
import {
  getCategoryCodeFromSlug,
  getCategoryNameFromSlug,
  sortedProductsHasImageComeFirst,
} from "../shared/utils";

const CollectionPage = () => {
  const { categorySlug } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryCode = getCategoryCodeFromSlug(categorySlug);

  const [filters, setFilters] = useState<Filter>({
    minPrice: searchParams.get("minPrice")
      ? Number(searchParams.get("minPrice"))
      : null,
    maxPrice: searchParams.get("maxPrice")
      ? Number(searchParams.get("maxPrice"))
      : null,
    BrandCodes: searchParams.get("brandCodes") || null,
    pageIndex: searchParams.get("pageIndex")
      ? Number(searchParams.get("pageIndex"))
      : 1,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 12,
    orderBy: searchParams.get("orderBy") || null,
    orderByDescending: searchParams.get("orderByDescending"),
    accessoryDetailCode: searchParams.get("accessoryDetailCode"),
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      minPrice: searchParams.get("minPrice")
        ? Number(searchParams.get("minPrice"))
        : null,
      maxPrice: searchParams.get("maxPrice")
        ? Number(searchParams.get("maxPrice"))
        : null,
      BrandCodes: searchParams.get("brandCodes") || null,
      pageIndex: searchParams.get("pageIndex")
        ? Number(searchParams.get("pageIndex"))
        : 1,
      orderBy: searchParams.get("orderBy") || null,
      orderByDescending: searchParams.get("orderByDescending") || null,
      accessoryDetailCode: searchParams.get("accessoryDetailCode"),
    }));
  }, [searchParams]);

  const debouncedSetFilters = useCallback(
    debounce((updatedFilters) => {
      setFilters(updatedFilters);

      const params = new URLSearchParams();
      if (updatedFilters.minPrice)
        params.set("minPrice", updatedFilters.minPrice.toString());
      if (updatedFilters.maxPrice)
        params.set("maxPrice", updatedFilters.maxPrice.toString());
      if (updatedFilters.BrandCodes)
        params.set("brandCodes", updatedFilters.BrandCodes);
      if (updatedFilters.pageIndex !== 1)
        params.set("pageIndex", updatedFilters.pageIndex.toString());
      if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);
      if (updatedFilters.orderByDescending)
        params.set("orderByDescending", updatedFilters.orderByDescending);
      if (updatedFilters.accessoryDetailCode)
        params.set("accessoryDetailCode", updatedFilters.accessoryDetailCode);

      // Điều hướng với categorySlug giữ nguyên
      navigate(`/san-pham/${categorySlug}?${params.toString()}`);
    }, 300),
    [categorySlug, navigate]
  );

  const updateFilter = (newFilterValues: Partial<Filter>) => {
    const mergedFilters = { ...filters, ...newFilterValues };
    debouncedSetFilters(mergedFilters);
  };

  const clearAllFilters = () => {
    updateFilter({
      minPrice: null,
      maxPrice: null,
      BrandCodes: null,
      orderBy: null,
      orderByDescending: null,
      accessoryDetailCode: null,
      pageIndex: 1,
    });
  };

  const breadcrumbItems = [
    { label: "Trang chủ", link: "/" },
    { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
  ];

  const { error: errorAllProduct, data: dataAllProduct } = useQuery({
    queryKey: [categoryCode, filters, "allProducts"],
    queryFn: () => fetchAllProducts(categoryCode, filters),
  });

  if (errorAllProduct) {
    return <div>Error fetching all products</div>;
  }

  const categoryName = getCategoryNameFromSlug(categorySlug);

  return (
    <div>
      <Helmet>
        {/* Title and description for SEO */}
        <title>{`Danh sách sản phẩm ${categoryName} - Vua Vợt`}</title>
        <meta
          name="description"
          content={`Khám phá các sản phẩm ${
            categoryName || "cầu lông"
          } từ những thương hiệu hàng đầu tại Vua Vợt. Lựa chọn đa dạng về vợt cầu lông, giày, quần áo và phụ kiện tại 83 Thống Nhất, Vạn Thạnh, Nha Trang, Khánh Hòa.`}
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content={`Danh sách sản phẩm ${categoryName} - Vua Vợt`}
        />
        <meta
          property="og:description"
          content={`Danh mục ${
            categoryName || "sản phẩm cầu lông"
          } chính hãng từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Ghé thăm Vua Vợt để tìm sản phẩm phù hợp với bạn.`}
        />
        <meta property="og:image" content={collectionPage} />
        <meta
          property="og:url"
          content={`https://vuavot.com/${categorySlug}`}
        />
        <meta property="og:type" content="website" />

        {/* Zalo */}
        <meta
          property="zalo:title"
          content={`Danh sách sản phẩm ${categoryName} - Vua Vợt`}
        />
        <meta
          property="zalo:description"
          content={`Vua Vợt - Cửa hàng chuyên cung cấp các sản phẩm ${
            categoryName || "cầu lông"
          } chất lượng cao từ các thương hiệu nổi tiếng như Yonex, Victor, Lining. Địa chỉ: 83 Thống Nhất, Vạn Thạnh, Nha Trang.`}
        />
        <meta property="zalo:image" content={collectionPage} />
        <meta
          property="zalo:url"
          content={`https://vuavot.com/${categorySlug}`}
        />
      </Helmet>

      <Header />
      <Container className="mb-10 px-4 lg:px-0">
        <HeroWithOverlay />
        <Breadcrumb items={breadcrumbItems} />
        <h1
          style={{ fontWeight: "900" }}
          className="text-2xl md:text-3xl uppercase text-center md:text-left"
        >
          {categoryName}
        </h1>
        {/* Filters Section */}
        <div className="flex flex-col md:flex-row flex-wrap gap-4 items-center mt-5 md:mt-7 mb-4 md:mb-6 border-b pb-4">
          {categorySlug === "phu-kien-cau-long" && (
            <FilterAccessory updateFilter={updateFilter} />
          )}
          <FilterBrands updateFilter={updateFilter} />
          <FilterPrice updateFilter={updateFilter} />
          <FilterSortBy updateFilter={updateFilter} />
        </div>
        {/* Active Filters */}
        <ActiveFilters
          filters={filters}
          updateFilter={updateFilter}
          clearAllFilters={clearAllFilters}
        />
        {/* Product Collection */}
        {dataAllProduct?.data.length === 0 && (
          <p className="text-center">Không có sản phẩm</p>
        )}
        <ProductCollection
          products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
        />
        {/* Pagination */}
        {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
          <Pagination
            currentPage={filters.pageIndex}
            totalPages={dataAllProduct.paging.totalPage}
            onPageChange={(pageIndex) => updateFilter({ pageIndex })}
          />
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default CollectionPage;

// import React, { useCallback, useEffect, useState } from "react";
// import { Helmet } from "react-helmet-async";
// import { useParams, useSearchParams } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";
// import debounce from "lodash.debounce";
// import Header from "../components/Layout/Header";
// import Footer from "../components/Layout/Footer";
// import Container from "../components/Common/Container";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import FilterSortBy from "../components/Collection/FilterType/FilterSortBy";
// import Pagination from "../components/Common/Pagination";
// import ProductCollection from "../components/Collection/ProductCollection";
// import ActiveFilters from "../components/Collection/ActiveFilters";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import HeroWithOverlay from "../components/Layout/HeroWithOverlay";
// import collectionPage from "../assets/collectionPage.png";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//     orderByDescending: searchParams.get("orderByDescending"),
//   });

//   useEffect(() => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       minPrice: searchParams.get("minPrice")
//         ? Number(searchParams.get("minPrice"))
//         : null,
//       maxPrice: searchParams.get("maxPrice")
//         ? Number(searchParams.get("maxPrice"))
//         : null,
//       BrandCodes: searchParams.get("brandCodes") || null,
//       pageIndex: searchParams.get("pageIndex")
//         ? Number(searchParams.get("pageIndex"))
//         : 1,
//       orderBy: searchParams.get("orderBy") || null,
//       orderByDescending: searchParams.get("orderByDescending") || null,
//     }));
//   }, [searchParams]);

//   const debouncedSetFilters = useCallback(
//     debounce((updatedFilters) => {
//       setFilters(updatedFilters);

//       const params = new URLSearchParams();
//       if (updatedFilters.minPrice)
//         params.set("minPrice", updatedFilters.minPrice.toString());
//       if (updatedFilters.maxPrice)
//         params.set("maxPrice", updatedFilters.maxPrice.toString());
//       if (updatedFilters.BrandCodes)
//         params.set("brandCodes", updatedFilters.BrandCodes);
//       if (updatedFilters.pageIndex !== 1)
//         params.set("pageIndex", updatedFilters.pageIndex.toString());
//       if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);
//       if (updatedFilters.orderByDescending)
//         params.set("orderByDescending", updatedFilters.orderByDescending);

//       setSearchParams(params);
//     }, 300),
//     []
//   );

//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     const mergedFilters = { ...filters, ...newFilterValues };
//     debouncedSetFilters(mergedFilters);
//   };

//   const clearAllFilters = () => {
//     updateFilter({
//       minPrice: null,
//       maxPrice: null,
//       BrandCodes: null,
//       orderBy: null,
//       orderByDescending: null,
//       pageIndex: 1,
//     });
//   };

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   const categoryName = getCategoryNameFromSlug(categorySlug);

//   return (
//     <div>
//       <Helmet>
//         {/* Title and description for SEO */}
//         <title>{`Danh sách sản phẩm ${categoryName} - Vua Vợt`}</title>
//         <meta
//           name="description"
//           content={`Khám phá các sản phẩm ${
//             categoryName || "cầu lông"
//           } từ những thương hiệu hàng đầu tại Vua Vợt. Lựa chọn đa dạng về vợt cầu lông, giày, quần áo và phụ kiện tại 83 Thống Nhất, Vạn Thạnh, Nha Trang, Khánh Hòa.`}
//         />

//         {/* Open Graph / Facebook */}
//         <meta
//           property="og:title"
//           content={`Danh sách sản phẩm ${categoryName} - Vua Vợt`}
//         />
//         <meta
//           property="og:description"
//           content={`Danh mục ${
//             categoryName || "sản phẩm cầu lông"
//           } chính hãng từ Yonex, Victor, Lining và nhiều thương hiệu nổi tiếng. Ghé thăm Vua Vợt để tìm sản phẩm phù hợp với bạn.`}
//         />
//         <meta property="og:image" content={collectionPage} />
//         <meta
//           property="og:url"
//           content={`https://vuavot.com/${categorySlug}`}
//         />
//         <meta property="og:type" content="website" />

//         {/* Zalo */}
//         <meta
//           property="zalo:title"
//           content={`Danh sách sản phẩm ${categoryName} - Vua Vợt`}
//         />
//         <meta
//           property="zalo:description"
//           content={`Vua Vợt - Cửa hàng chuyên cung cấp các sản phẩm ${
//             categoryName || "cầu lông"
//           } chất lượng cao từ các thương hiệu nổi tiếng như Yonex, Victor, Lining. Địa chỉ: 83 Thống Nhất, Vạn Thạnh, Nha Trang.`}
//         />
//         <meta property="zalo:image" content={collectionPage} />
//         <meta
//           property="zalo:url"
//           content={`https://vuavot.com/${categorySlug}`}
//         />
//       </Helmet>

//       <Header />
//       <Container className="mb-10 px-4 lg:px-0">
//         <HeroWithOverlay />
//         <Breadcrumb items={breadcrumbItems} />
//         <h1
//           style={{ fontWeight: "900" }}
//           className="text-2xl md:text-3xl uppercase text-center md:text-left"
//         >
//           {categoryName + ` ${filters.BrandCodes || ""}`}
//         </h1>
//         {/* Filters Section */}
//         <div className="flex flex-col md:flex-row flex-wrap gap-4 items-center mt-5 md:mt-7 mb-4 md:mb-6 border-b pb-4">
//           <FilterBrands updateFilter={updateFilter} />
//           <FilterPrice updateFilter={updateFilter} />
//           <FilterSortBy updateFilter={updateFilter} />
//         </div>
//         {/* Active Filters */}
//         <ActiveFilters
//           filters={filters}
//           updateFilter={updateFilter}
//           clearAllFilters={clearAllFilters}
//         />
//         {/* Product Collection */}
//         {dataAllProduct?.data.length === 0 && (
//           <p className="text-center">Không có sản phẩm</p>
//         )}
//         <ProductCollection
//           products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//         />
//         {/* Pagination */}
//         {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//           <Pagination
//             currentPage={filters.pageIndex}
//             totalPages={dataAllProduct.paging.totalPage}
//             onPageChange={(pageIndex) => updateFilter({ pageIndex })}
//           />
//         )}
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import React, { useCallback, useEffect, useState } from "react";
// import { Helmet } from "react-helmet-async";
// import { useParams, useSearchParams } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";
// import debounce from "lodash.debounce";
// import Header from "../components/Layout/Header";
// import Footer from "../components/Layout/Footer";
// import Container from "../components/Common/Container";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import FilterSortBy from "../components/Collection/FilterType/FilterSortBy";
// import Pagination from "../components/Common/Pagination";
// import ProductCollection from "../components/Collection/ProductCollection";
// import ActiveFilters from "../components/Collection/ActiveFilters";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import HeroWithOverlay from "../components/Layout/HeroWithOverlay";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//     orderByDescending: searchParams.get("orderByDescending"),
//   });

//   useEffect(() => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       minPrice: searchParams.get("minPrice")
//         ? Number(searchParams.get("minPrice"))
//         : null,
//       maxPrice: searchParams.get("maxPrice")
//         ? Number(searchParams.get("maxPrice"))
//         : null,
//       BrandCodes: searchParams.get("brandCodes") || null,
//       pageIndex: searchParams.get("pageIndex")
//         ? Number(searchParams.get("pageIndex"))
//         : 1,
//       orderBy: searchParams.get("orderBy") || null,
//       orderByDescending: searchParams.get("orderByDescending") || null,
//     }));
//   }, [searchParams]);

//   const debouncedSetFilters = useCallback(
//     debounce((updatedFilters) => {
//       setFilters(updatedFilters);

//       const params = new URLSearchParams();
//       if (updatedFilters.minPrice)
//         params.set("minPrice", updatedFilters.minPrice.toString());
//       if (updatedFilters.maxPrice)
//         params.set("maxPrice", updatedFilters.maxPrice.toString());
//       if (updatedFilters.BrandCodes)
//         params.set("brandCodes", updatedFilters.BrandCodes);
//       if (updatedFilters.pageIndex !== 1)
//         params.set("pageIndex", updatedFilters.pageIndex.toString());
//       if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);
//       if (updatedFilters.orderByDescending)
//         params.set("orderByDescending", updatedFilters.orderByDescending);

//       setSearchParams(params);
//     }, 300),
//     []
//   );

//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     const mergedFilters = { ...filters, ...newFilterValues };
//     debouncedSetFilters(mergedFilters);
//   };

//   const clearAllFilters = () => {
//     updateFilter({
//       minPrice: null,
//       maxPrice: null,
//       BrandCodes: null,
//       orderBy: null,
//       orderByDescending: null,
//       pageIndex: 1,
//     });
//   };

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Helmet>
//         <title>Danh sách {getCategoryNameFromSlug(categorySlug)}</title>
//       </Helmet>
//       <Header />
//       <Container className="mb-10 px-4 lg:px-0">
//         {" "}
//         {/* Add padding for mobile */}
//         <HeroWithOverlay />
//         <Breadcrumb items={breadcrumbItems} />
//         <h1
//           style={{ fontWeight: "900" }}
//           className="text-2xl md:text-3xl uppercase text-center md:text-left"
//         >
//           {getCategoryNameFromSlug(categorySlug) +
//             ` ${filters.BrandCodes || ""}`}
//         </h1>
//         {/* Filters Section */}
//         <div className="flex flex-col md:flex-row flex-wrap gap-4 items-center mt-5 md:mt-7 mb-4 md:mb-6 border-b pb-4">
//           <FilterBrands updateFilter={updateFilter} />
//           <FilterPrice updateFilter={updateFilter} />
//           <FilterSortBy updateFilter={updateFilter} />
//         </div>
//         {/* Active Filters */}
//         <ActiveFilters
//           filters={filters}
//           updateFilter={updateFilter}
//           clearAllFilters={clearAllFilters}
//         />
//         {/* Product Collection */}
//         {dataAllProduct?.data.length === 0 && (
//           <p className="text-center">Không có sản phẩm</p>
//         )}
//         <ProductCollection
//           products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//         />
//         {/* Pagination */}
//         {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//           <Pagination
//             currentPage={filters.pageIndex}
//             totalPages={dataAllProduct.paging.totalPage}
//             onPageChange={(pageIndex) => updateFilter({ pageIndex })}
//           />
//         )}
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { useQuery } from "@tanstack/react-query";
// import debounce from "lodash.debounce";
// import { useCallback, useEffect, useState } from "react";
// import { Helmet } from "react-helmet-async";
// import { useParams, useSearchParams } from "react-router-dom";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Pagination from "../components/Common/Pagination";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import HeroWithExpandableNavigation from "../components/Layout/HeroWithExpandableNavigation";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import FilterSortBy from "../components/Collection/FilterType/FilterSortBy";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//     orderByDescending: searchParams.get("orderByDescending") || null,
//   });

//   useEffect(() => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       minPrice: searchParams.get("minPrice")
//         ? Number(searchParams.get("minPrice"))
//         : null,
//       maxPrice: searchParams.get("maxPrice")
//         ? Number(searchParams.get("maxPrice"))
//         : null,
//       BrandCodes: searchParams.get("brandCodes") || null,
//       pageIndex: searchParams.get("pageIndex")
//         ? Number(searchParams.get("pageIndex"))
//         : 1,
//       orderBy: searchParams.get("orderBy") || null,
//       orderByDescending: searchParams.get("orderByDescending") || null,
//     }));
//   }, [searchParams]);

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   const debouncedSetFilters = useCallback(
//     debounce((updatedFilters) => {
//       setFilters(updatedFilters);

//       const params = new URLSearchParams();
//       if (updatedFilters.minPrice)
//         params.set("minPrice", updatedFilters.minPrice.toString());
//       if (updatedFilters.maxPrice)
//         params.set("maxPrice", updatedFilters.maxPrice.toString());
//       if (updatedFilters.BrandCodes)
//         params.set("brandCodes", updatedFilters.BrandCodes);
//       if (updatedFilters.pageIndex !== 1)
//         params.set("pageIndex", updatedFilters.pageIndex.toString());
//       if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);
//       if (updatedFilters.orderByDescending)
//         params.set("orderByDescending", updatedFilters.orderByDescending);

//       setSearchParams(params); // Update the URL
//     }, 300),
//     []
//   );

//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     const mergedFilters = { ...filters, ...newFilterValues };
//     debouncedSetFilters(mergedFilters);
//   };

//   const handlePageChange = (pageIndex: number) => {
//     updateFilter({ pageIndex });
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Helmet>
//         <title>Danh sách {getCategoryNameFromSlug(categorySlug)}</title>
//       </Helmet>
//       <Header />
//       <Container className="mb-10">
//         <HeroWithExpandableNavigation />
//         <Breadcrumb items={breadcrumbItems} />
//         <h1 style={{ fontWeight: "900" }} className="text-3xl uppercase">
//           {getCategoryNameFromSlug(categorySlug) +
//             ` ${filters.BrandCodes || ""}`}
//         </h1>
//         {/* Filter Row */}
//         <div className="flex flex-wrap gap-4 items-center mt-7 mb-6 border-b pb-4">
//           <FilterBrands updateFilter={updateFilter} />
//           <FilterPrice updateFilter={updateFilter} />
//           <FilterSortBy updateFilter={updateFilter} />
//           {/* Add other filter components here, like FilterColor or FilterProduct */}
//         </div>

//         <div>
//           <p className="text-lg font-medium">ĐANG LỌC THEO: </p>
//           <div>...</div>
//         </div>

//         {/* Product Collection */}
//         {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//         <ProductCollection
//           products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//         />

//         {/* Pagination */}
//         {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//           <Pagination
//             currentPage={filters.pageIndex}
//             totalPages={dataAllProduct.paging.totalPage}
//             onPageChange={handlePageChange}
//           />
//         )}
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { useQuery } from "@tanstack/react-query";
// import debounce from "lodash.debounce";
// import { useCallback, useEffect, useState } from "react";
// import { Helmet } from "react-helmet-async";
// import { useParams, useSearchParams } from "react-router-dom";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Pagination from "../components/Common/Pagination";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header";
// import HeroWithExpandableNavigation from "../components/Layout/HeroWithExpandableNavigation";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//   });

//   // Sync filters with searchParams on initial load
//   useEffect(() => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       minPrice: searchParams.get("minPrice")
//         ? Number(searchParams.get("minPrice"))
//         : null,
//       maxPrice: searchParams.get("maxPrice")
//         ? Number(searchParams.get("maxPrice"))
//         : null,
//       BrandCodes: searchParams.get("brandCodes") || null,
//       pageIndex: searchParams.get("pageIndex")
//         ? Number(searchParams.get("pageIndex"))
//         : 1,
//       orderBy: searchParams.get("orderBy") || null,
//     }));
//   }, [searchParams]);

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   const debouncedSetFilters = useCallback(
//     debounce((updatedFilters) => {
//       setFilters(updatedFilters);

//       const params = new URLSearchParams();
//       if (updatedFilters.minPrice)
//         params.set("minPrice", updatedFilters.minPrice.toString());
//       if (updatedFilters.maxPrice)
//         params.set("maxPrice", updatedFilters.maxPrice.toString());
//       if (updatedFilters.BrandCodes)
//         params.set("brandCodes", updatedFilters.BrandCodes);
//       if (updatedFilters.pageIndex !== 1)
//         params.set("pageIndex", updatedFilters.pageIndex.toString());
//       if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

//       setSearchParams(params); // Update the URL
//     }, 300),
//     []
//   );

//   // Update filter state while merging new filter values with existing ones
//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     const mergedFilters = { ...filters, ...newFilterValues };
//     debouncedSetFilters(mergedFilters);
//   };

//   const handlePageChange = (pageIndex: number) => {
//     updateFilter({ pageIndex });
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Helmet>
//         <title>Danh sách {getCategoryNameFromSlug(categorySlug)}</title>
//       </Helmet>
//       <Header />
//       <Container>
//         <HeroWithExpandableNavigation />
//         <Breadcrumb items={breadcrumbItems} />
//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             <FilterPrice updateFilter={updateFilter} />
//             <FilterBrands updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             <div className="h-16 border-4 border-double shadow-sm border-blue-200 text-blue-500  font-medium text-xl uppercase text-center mb-5 flex items-center justify-center  -skew-x-12">
//               <span className="skew-x-12">
//                 {getCategoryNameFromSlug(categorySlug) +
//                   ` ${filters.BrandCodes || ""}`}
//               </span>
//             </div>
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//             {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//               <Pagination
//                 currentPage={filters.pageIndex}
//                 totalPages={dataAllProduct.paging.totalPage}
//                 onPageChange={handlePageChange}
//               />
//             )}
//           </div>
//         </div>
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { useQuery } from "@tanstack/react-query";
// import { useState, useCallback } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import debounce from "lodash.debounce";
// import FilterCategory from "../components/Collection/FilterType/FilterCategory";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Header from "../components/Layout/Header";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import Footer from "../components/Layout/Footer";
// import Pagination from "../components/Common/Pagination";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//   });

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   const debouncedSetFilters = useCallback(
//     debounce((updatedFilters) => {
//       setFilters(updatedFilters);

//       const params = new URLSearchParams();
//       if (updatedFilters.minPrice)
//         params.set("minPrice", updatedFilters.minPrice.toString());
//       if (updatedFilters.maxPrice)
//         params.set("maxPrice", updatedFilters.maxPrice.toString());
//       if (updatedFilters.BrandCodes)
//         params.set("brandCodes", updatedFilters.BrandCodes);
//       if (updatedFilters.pageIndex !== 1)
//         params.set("pageIndex", updatedFilters.pageIndex.toString());
//       if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

//       setSearchParams(params); // Update the URL
//     }, 300),
//     []
//   );

//   // Update filter state while merging new filter values with existing ones
//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     const mergedFilters = { ...filters, ...newFilterValues };
//     debouncedSetFilters(mergedFilters);
//   };

//   const handlePageChange = (pageIndex: number) => {
//     updateFilter({ pageIndex });
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Header />
//       <Container>
//         <Breadcrumb items={breadcrumbItems} />
//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             <FilterPrice updateFilter={updateFilter} />
//             <FilterBrands updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//             {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//               <Pagination
//                 currentPage={filters.pageIndex}
//                 totalPages={dataAllProduct.paging.totalPage}
//                 onPageChange={handlePageChange}
//               />
//             )}
//           </div>
//         </div>
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { useQuery } from "@tanstack/react-query";
// import { useState, useCallback } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import debounce from "lodash.debounce";
// import FilterCategory from "../components/Collection/FilterType/FilterCategory";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Header from "../components/Layout/Header";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import Footer from "../components/Layout/Footer";
// import Pagination from "../components/Common/Pagination";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//   });

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   const debouncedSetFilters = useCallback(
//     debounce((newFilterValues) => {
//       setFilters((prevFilters) => ({ ...prevFilters, ...newFilterValues }));

//       const params = new URLSearchParams();
//       if (newFilterValues.minPrice)
//         params.set("minPrice", newFilterValues.minPrice.toString());
//       if (newFilterValues.maxPrice)
//         params.set("maxPrice", newFilterValues.maxPrice.toString());
//       if (newFilterValues.BrandCodes)
//         params.set("brandCodes", newFilterValues.BrandCodes);
//       if (newFilterValues.pageIndex && newFilterValues.pageIndex !== 1)
//         params.set("pageIndex", newFilterValues.pageIndex.toString());
//       if (newFilterValues.orderBy)
//         params.set("orderBy", newFilterValues.orderBy);

//       setSearchParams(params); // Update the URL
//     }, 300),
//     []
//   );

//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     debouncedSetFilters(newFilterValues);
//   };

//   const handlePageChange = (pageIndex: number) => {
//     updateFilter({ pageIndex });
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Header />
//       <Container>
//         <Breadcrumb items={breadcrumbItems} />
//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             <FilterPrice updateFilter={updateFilter} />
//             <FilterBrands updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//             {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//               <Pagination
//                 currentPage={filters.pageIndex}
//                 totalPages={dataAllProduct.paging.totalPage}
//                 onPageChange={handlePageChange}
//               />
//             )}
//           </div>
//         </div>
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { useQuery } from "@tanstack/react-query";
// import { useState } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import FilterCategory from "../components/Collection/FilterType/FilterCategory";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Header from "../components/Layout/Header";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import Footer from "../components/Layout/Footer";
// import Pagination from "../components/Common/Pagination";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null,
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//   });

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//   });

//   // Update filter state from child components and URL
//   const updateFilter = (newFilterValues: Partial<Filter>) => {
//     const updatedFilters = {
//       ...filters,
//       ...newFilterValues,
//     };
//     setFilters(updatedFilters);

//     const params = new URLSearchParams();
//     if (updatedFilters.minPrice)
//       params.set("minPrice", updatedFilters.minPrice.toString());
//     if (updatedFilters.maxPrice)
//       params.set("maxPrice", updatedFilters.maxPrice.toString());
//     if (updatedFilters.BrandCodes)
//       params.set("brandCodes", updatedFilters.BrandCodes);
//     if (updatedFilters.pageIndex !== 1)
//       params.set("pageIndex", updatedFilters.pageIndex.toString());
//     if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

//     setSearchParams(params); // Update the URL
//   };

//   const handlePageChange = (pageIndex: number) => {
//     updateFilter({ pageIndex });
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Header />
//       <Container>
//         <Breadcrumb items={breadcrumbItems} />
//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             {/* <FilterCategory setFilters={setFilters} /> */}
//             <FilterPrice updateFilter={updateFilter} />
//             <FilterBrands updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//             {dataAllProduct?.paging && dataAllProduct.paging.totalPage > 1 && (
//               <Pagination
//                 currentPage={filters.pageIndex}
//                 totalPages={dataAllProduct.paging.totalPage}
//                 onPageChange={handlePageChange}
//               />
//             )}
//           </div>
//         </div>
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { keepPreviousData, useQuery } from "@tanstack/react-query";
// import { useState } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import FilterCategory from "../components/Collection/FilterType/FilterCategory";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Header from "../components/Layout/Header";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";
// import FilterBrands from "../components/Collection/FilterType/FilterBrands";
// import Footer from "../components/Layout/Footer";

// const CollectionPage = () => {
//   const { categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({
//     minPrice: searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null,
//     maxPrice: searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null,
//     BrandCodes: searchParams.get("brandCodes") || null, // Array of selected brand codes
//     pageIndex: searchParams.get("pageIndex")
//       ? Number(searchParams.get("pageIndex"))
//       : 1,
//     pageSize: searchParams.get("pageSize")
//       ? Number(searchParams.get("pageSize"))
//       : 12,
//     orderBy: searchParams.get("orderBy") || null,
//   });

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//     // placeholderData: keepPreviousData,
//   });

//   // Updating the filter state from child components
//   const updateFilter = (newFilterValues: Partial<typeof filters>) => {
//     const updatedFilters = {
//       ...filters,
//       ...newFilterValues,
//     };
//     setFilters(updatedFilters);

//     const params = new URLSearchParams();
//     if (updatedFilters.minPrice)
//       params.set("minPrice", updatedFilters.minPrice.toString());
//     if (updatedFilters.maxPrice)
//       params.set("maxPrice", updatedFilters.maxPrice.toString());
//     if (updatedFilters.BrandCodes) {
//       params.set("brandCodes", updatedFilters.BrandCodes);
//       // updatedFilters.brands.forEach((brand) => params.append("brands", brand));
//     }
//     if (updatedFilters.pageIndex !== 1)
//       params.set("pageIndex", updatedFilters.pageIndex.toString());
//     if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

//     setSearchParams(params); // Update the URL
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Header />
//       {/* <CategoryNavigation /> */}
//       <Container>
//         <Breadcrumb items={breadcrumbItems} />

//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             <FilterCategory setFilters={setFilters} />
//             <FilterPrice updateFilter={updateFilter} />
//             <FilterBrands updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//           </div>
//         </div>
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default CollectionPage;

// import { keepPreviousData, useQuery } from "@tanstack/react-query";
// import { useEffect, useState } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import FilterCategory from "../components/Collection/FilterType/FilterCategory";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Header from "../components/Layout/Header";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";

// const CollectionPage = () => {
//   const { "category-slug": categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({} as Filter);

//   useEffect(() => {
//     const changeFilter = (key: string, value: string | number) => {
//       setFilters((prevConfig) => ({
//         ...prevConfig,
//         [key]: value,
//       }));
//     };

//     const sortType = searchParams.get("sort_by") || "popularity.desc";
//     changeFilter("sort_by", sortType);

//     const genreType = searchParams.getAll("genre") || [];
//     changeFilter("with_genres", genreType.toString());

//     const minRuntime = Number(searchParams.get("minRuntime")) || 0;
//     const maxRuntime = Number(searchParams.get("maxRuntime")) || 200;
//     changeFilter("with_runtime.gte", minRuntime);
//     changeFilter("with_runtime.lte", maxRuntime);

//     const releaseFrom = searchParams.get("from") || "2002-11-04";
//     const releaseTo = searchParams.get("to") || "2022-07-28";
//     changeFilter("primary_release_date.gte", releaseFrom);
//     changeFilter("primary_release_date.lte", releaseTo);
//     changeFilter("air_date.gte", releaseFrom);
//     changeFilter("air_date.lte", releaseTo);

//     // eslint-disable-next-line
//   }, [window.location.search]);

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//     placeholderData: keepPreviousData,
//   });

//   // Updating the filter state from child components
//   const updateFilter = (newFilterValues: Partial<typeof filters>) => {
//     const updatedFilters = {
//       ...filters,
//       ...newFilterValues,
//     };
//     setFilters(updatedFilters);

//     const params = new URLSearchParams();
//     if (updatedFilters.minPrice !== null)
//       params.set("minPrice", updatedFilters.minPrice.toString());
//     if (updatedFilters.maxPrice !== null)
//       params.set("maxPrice", updatedFilters.maxPrice.toString());
//     if (updatedFilters.brands.length)
//       updatedFilters.brands.forEach((brand) => params.append("brands", brand));
//     if (updatedFilters.pageIndex !== 1)
//       params.set("pageIndex", updatedFilters.pageIndex.toString());
//     if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

//     setSearchParams(params); // Update the URL
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Header />
//       {/* <CategoryNavigation /> */}
//       <Container>
//         <Breadcrumb items={breadcrumbItems} />

//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             <FilterCategory />
//             <FilterPrice updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default CollectionPage;
