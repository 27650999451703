import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { fetchFlashSale } from "../../services/home";
import ProductSlider from "../Common/ProductSlider";
import CountdownTimer from "./CountdownTimer";

const FlashSaleSlider: React.FC = () => {
  // Initial countdown time in seconds (e.g., 5 days, 6 hours, 30 minutes, and 10 seconds)
  const initialCountdown = 5 * 24 * 60 * 60 + 6 * 60 * 60 + 30 * 60 + 10;

  // Fetch the main products data
  const {
    isPending: isLoadingFlashSale,
    error: errorFlashSale,
    data: dataFlashSale,
  } = useQuery({
    queryKey: ["flash-sale"],
    queryFn: fetchFlashSale,
  });

  // Handle loading and error states
  if (isLoadingFlashSale) {
    return <div>Loading...</div>;
  }

  if (errorFlashSale) {
    return <div>Error fetching main flash sale</div>;
  }

  return (
    <div className="bg-gray-100 py-8">
      <div className="px-8">
        {/* Header with Title and Countdown Timer */}
        <div className="flex justify-between items-center mb-5">
          <h2 className="text-lg sm:text-xl md:text-2xl font-semibold">
            FLASH SALE
          </h2>

          {/* Countdown Timer */}
          <CountdownTimer initialCountdown={initialCountdown} />
        </div>

        <ProductSlider products={dataFlashSale?.data} />

        {/* View All Button */}
        <div className="flex justify-center mt-4 md:mt-6 lg:mt-8">
          <Link
            to="/san-pham/vot-cau-long?orderByDescending=discount"
            className="px-4 py-2 text-sm md:text-base lg:text-lg border border-gray-500 rounded-md hover:bg-gray-200 transition duration-200"
          >
            Xem tất cả
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FlashSaleSlider;
