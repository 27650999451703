import React from "react";
import {
  FaCcVisa,
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import {
  AiOutlineClockCircle,
  AiOutlinePhone,
  AiOutlineMail,
} from "react-icons/ai";
import logoFooter from "../../assets/logoFooter.png";
import paymentMethods from "../../assets/paymentMethods.png";
import { MdLocationPin } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <footer className="bg-[#251F1F] text-gray-300 py-10 pb-6">
      <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Logo and Store Info */}
        <div>
          <img src={logoFooter} alt="Vua Vợt Logo" className="w-32 mb-4" />
          <p className="mb-2 text-justify">
            VUAVOT.COM - Cửa hàng cầu lông chuyên cung cấp các thương hiệu
            Yonex, Victor, Lining, Felet... giày cầu lông, quần áo cầu lông và
            phụ kiện.
          </p>
          <p className="flex gap-1 items-start">
            <IoLocationOutline size={25} />
            <span>
              <span className="font-medium">Địa chỉ:</span> 83 Thống Nhất, Vạn
              Thắng, Nha Trang, Khánh Hòa
            </span>
          </p>
          <p className="flex items-center">
            <AiOutlinePhone className="mr-2" size={20} />{" "}
            <span className="font-medium">{"Số điện thoại: "}&nbsp;</span>
            {"0888 08 0822"}
          </p>

          {/* <p className="flex items-center">
            <AiOutlineMail className="mr-2" /> info@gmail.com
          </p> */}
          <div className="flex space-x-4 mt-4">
            <FaTiktok className="text-white text-xl" />
            <FaInstagram className="text-white text-xl" />
            <FaYoutube className="text-white text-xl" />
            <FaFacebook className="text-white text-xl" />
          </div>
        </div>

        {/* Policy Links */}
        <div>
          <h3 className="font-bold text-white mb-4">Chính sách</h3>
          <ul className="space-y-2">
            <li>Chính sách xử lý khiếu nại</li>
            <li>Chính sách bảo hành</li>
            <li>Điều khoản dịch vụ</li>
            <li>Chính sách giao hàng và đổi trả</li>
            <li>Liên hệ</li>
            <li>Hướng dẫn kiểm tra bảo hành</li>
            <li>Chính sách thanh toán</li>
            <li>Chính sách bảo mật thông tin</li>
          </ul>
        </div>

        {/* Customer Service Links */}
        <div>
          <h3 className="font-bold text-white mb-4">Hỏi đáp dịch vụ</h3>
          <ul className="space-y-2">
            <li>Dịch vụ căng lưới</li>
            <li>Tra cứu đơn hàng</li>
            <li>Hỏi đáp - FAQs</li>
            <li>Logo - Bộ nhận diện thương hiệu</li>
            <li>Hướng dẫn mua hàng online</li>
            <li>Tuyển dụng cơ hội việc làm</li>
            <li>Tư vấn mua hàng online - CSKH</li>
          </ul>
        </div>

        {/* Hours and Payments */}
        <div>
          <h3 className="font-bold text-white mb-4">Thời gian mở cửa</h3>
          <p className="flex items-center mb-4">
            <AiOutlineClockCircle className="mr-2" /> 7:30 - 20:30
          </p>
          <h3 className="font-bold text-white mb-4">Thanh toán:</h3>
          <img src={paymentMethods} alt="Payment Methods" className="w-40" />
        </div>
      </div>

      <div className="text-center text-gray-500 mt-8">
        © Bản quyền thuộc về Vua Vợt
      </div>
    </footer>
  );
};

export default Footer;
